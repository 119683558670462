import { createAsyncThunk } from "@reduxjs/toolkit";
import { profile } from "store/profile/service";

export const profileData = createAsyncThunk(
  "profileData/fetch",
  async(body, { rejectWithValue }) => {
    try {
      const response = await profile.profile_data(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

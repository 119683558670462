import { createSlice } from "@reduxjs/toolkit";
import { spareParts } from "store/sparePartsInfo/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  sparePartsInfoStatus: null,
  data: {},
  errors: "",
};

export const sparePartsInfoData = createSlice({
  name: "sparePartsInfo",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "sparePartsInfoStatus":
          state.sparePartsInfoStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [spareParts.pending]: (state) => {
      state.errors = null;
      state.sparePartsInfoStatus = REDUX_STATES.PENDING;
    },
    [spareParts.fulfilled]: (state, action) => {
      state.data = action.payload.sparePartInfo;
      state.sparePartsInfoStatus = REDUX_STATES.SUCCEEDED;
    },
    [spareParts.rejected]: (state, { payload }) => {
      state.sparePartsInfoStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = sparePartsInfoData.actions;

export default sparePartsInfoData.reducer;

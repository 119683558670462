import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { carFilterCountry } from "store/carFilterCountry/actions";

const initialState = {
  carFilterCountryStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const carFilter = createSlice({
  name: "carFilterCountry",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carFilterCountryStatus":
        state.carFilterCountryStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [carFilterCountry.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.countryStatus = REDUX_STATES.PENDING;
    },
    [carFilterCountry.fulfilled]: (state, action) => {
      state.data = action.payload?.cars;
      state.is_loading = false;
      state.carFilterCountryStatus = REDUX_STATES.SUCCEEDED;
    },
    [carFilterCountry.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carFilterCountryStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = carFilter.actions;

export default carFilter.reducer;

import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { Images } from "assets";
import { ROUTENAMES } from "routes/routeNames";
import { isAuthenticated } from "utils/helpers";
import { useSelector } from "react-redux";
import { selelectAuth } from "store/auth/selector";
import { useHeaderLists } from "utils/headerLists";
import { useTranslation } from "react-i18next";
import { IoMdTime } from "react-icons/io";
import i18n from "i18next";
import { FooterHref, FooterSectionText, PhoneHrefParent } from "components/footer/styled";
import { useCloseOnClickOutside } from "hooks/useCloseOnClickOutside";
import { HeaderSecondAdvertisement } from "./headerSecondAdveritesement";
import { GiEarthAsiaOceania } from "react-icons/gi";
import {
  AddressAndTime,
  AddressAndTimeZone,
  AddressWithStreet,
  CloseIcon,
  CloseIconParent,
  ContainerForHeaderTriangle,
  FirstHeaderLeftSide,
  FirstHeaderRightSide,
  FirstHeaderWrapper,
  HamburgerIcon,
  HeaderList,
  HeaderListsWrapper,
  HeaderMobileSearchBar,
  HeaderSearchBar,
  HeaderWrapper,
  LanguageDropDown,
  LanguageSelected,
  LeftArrow,
  LeftSideBlock,
  LeftSideText,
  Logo,
  LogoSmallSize,
  MainAddress,
  MobileHeaderList,
  MobileHeaderListsWrapper, NotSelectedLanguage,
  RightArrow,
  RightSideContent,
  RightSideText,
  SearchBox,
  SearchBoxWrapper,
  SearchIcon,
  SearchMediaIcon,
  SearchMobileIcon,
  SecondHeaderWrapper, SelectedLanguage,
  ThirdHeaderWrapper
} from "components/header/styled";
import { SearchCarsBlock } from "../welcome/SearchCarsBlock";

export const Header = ({ selectWebsiteData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { secondRef, secondIsOpen, setSecondIsOpen } = useCloseOnClickOutside();
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(true);
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [selectedLanguage, setSelectedLanguage] = useState("am");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const auth = useSelector(selelectAuth);
  const { t } = useTranslation();
  const headerLists = useHeaderLists();

  useEffect(() => {
    let element = document?.getElementById("search-container");

    if (!isMobileSearchOpen) {
      element.style.display = "flex";
    } else if (element && window.innerWidth < 768) {
      element.style.display = "none";
    }
  }, [isMobileSearchOpen]);

  const languages = [
    { code: "en", image: Images.usaFlag },
    { code: "am", image: Images.armeniaFlag },
    { code: "zh", image: Images.chinaFlag },
    { code: "ru", image: Images.russianFlag }
  ];

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLanguageSelect = (code) => {
    setSelectedLanguage(code);
    localStorage.setItem("lang", code);
    i18n.changeLanguage(code);
    window.dispatchEvent(new Event("storage"));
  };

  const handleNavigation = () => {
    if (search != null) {
      localStorage.setItem("searchWithArticulate", search);
      navigate(`${ROUTENAMES.cars}/${search}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (search != null) {
        localStorage.setItem("searchWithArticulate", search);
        navigate(`${ROUTENAMES.cars}/${search}`);
      }
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [navigate, language]);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isOpen]);

  return (
    <>
      {window.innerWidth < 768 ? <SearchCarsBlock /> : null}
      <HeaderWrapper>
        <FirstHeaderWrapper>
          <ContainerForHeaderTriangle>
            <FirstHeaderLeftSide>
              <LeftSideBlock>
                <LeftSideText isTextBig>
                  {t("need_help")}
                  <PhoneHrefParent>
                    <FooterHref
                      href={`tel:${selectWebsiteData?.phone_one ? selectWebsiteData?.phone_one : "+374-96-94-94-66"}`}>
                      <FooterSectionText
                        isPhone> {selectWebsiteData?.phone_one ? selectWebsiteData?.phone_one : "+374-96-94-94-66"} </FooterSectionText>
                    </FooterHref>
                    <FooterHref
                      href={`tel:${selectWebsiteData?.phone_two ? selectWebsiteData?.phone_two : "+374-96-94-94-66"}`}>
                      <FooterSectionText
                        isPhone> {selectWebsiteData?.phone_two ? selectWebsiteData?.phone_two : "+374-96-94-94-66"}</FooterSectionText>
                    </FooterHref>
                  </PhoneHrefParent>
                </LeftSideText>
              </LeftSideBlock>
            </FirstHeaderLeftSide>
            <RightArrow />
          </ContainerForHeaderTriangle>

          <ContainerForHeaderTriangle>
            <LeftArrow />
            <FirstHeaderRightSide>
              <RightSideContent>
                {localStorage.getItem("access_token") ? <LanguageDropDown>
                    <RightSideText onClick={() => navigate(ROUTENAMES.profile)}> {t("profile")} </RightSideText>
                    <SelectedLanguage>
                      <NotSelectedLanguage> </NotSelectedLanguage>

                    </SelectedLanguage>
                    {languages.map(language => (
                      <LanguageSelected
                        key={uuid()}
                        Image={language.image}
                        selected={language.code === selectedLanguage}
                        onClick={() => handleLanguageSelect(language.code)}
                      />
                    ))}
                  </LanguageDropDown> :
                  <RightSideContent>
                    <RightSideText onClick={() => navigate(ROUTENAMES.sign_in)}> {t("login")} </RightSideText>
                    <RightSideText onClick={() => navigate(ROUTENAMES.sign_up)}> {t("register")} </RightSideText>
                    <SelectedLanguage selectedLanguage={selectedLanguage} ref={secondRef}
                                      onClick={() => setSecondIsOpen(!secondIsOpen)}>
                      {secondIsOpen ? languages.map(language => (
                        <NotSelectedLanguage
                          key={uuid()}
                          Image={language.image}
                          selected={language.code === selectedLanguage}
                          selectedLanguage={language.code}
                          onClick={() => handleLanguageSelect(language.code)}
                        />
                      )) : null}
                    </SelectedLanguage>
                  </RightSideContent>
                }
              </RightSideContent>
            </FirstHeaderRightSide>
          </ContainerForHeaderTriangle>
        </FirstHeaderWrapper>

        <SecondHeaderWrapper>
          <AddressAndTime onClick={() => navigate(ROUTENAMES.our_addresses)} isAddress>
            <GiEarthAsiaOceania className='earth__icon'/>
            <AddressAndTimeZone>
              <MainAddress>{t("officeCountry")}</MainAddress>
              <AddressWithStreet>{selectWebsiteData?.address ? selectWebsiteData?.address : "Melkumov,88"}</AddressWithStreet>
            </AddressAndTimeZone>
          </AddressAndTime>
          <Logo src={Images.logo} alt='logo' onClick={() => navigate(ROUTENAMES.welcome)} />

          <AddressAndTime isAddress>
            <IoMdTime className="big-size-icon" />
            <AddressAndTimeZone>
              <MainAddress>
                Երկուշաբթիից շաբաթ 10։00 - 20։00
              </MainAddress>
              <MainAddress>
                Կիրակի 10:00 - 16:00
              </MainAddress>
            </AddressAndTimeZone>
          </AddressAndTime>
        </SecondHeaderWrapper>

        <ThirdHeaderWrapper isMobileSearchOpen={isMobileSearchOpen}>
          {isOpen ? (
            <MobileHeaderListsWrapper>
              {headerLists.map((element) => (
                <MobileHeaderList key={uuid()} onClick={() => navigate(element.path)}>{element.text}</MobileHeaderList>
              ))}
              {isAuthenticated ? null :
                <MobileHeaderList onClick={() => navigate(ROUTENAMES.sign_in)}> {t("login")} </MobileHeaderList>}
              {isAuthenticated ? null :
                <MobileHeaderList onClick={() => navigate(ROUTENAMES.sign_up)}> {t("register")} </MobileHeaderList>}
              {isAuthenticated || auth.accessToken ? <MobileHeaderList> {t("profile")} </MobileHeaderList> : null}
              <LanguageDropDown>
                {languages.map(language => (
                  <LanguageSelected
                    key={uuid()}
                    Image={language.image}
                    selected={language.code === selectedLanguage}
                    onClick={() => handleLanguageSelect(language.code)}
                  />
                ))}
              </LanguageDropDown>
            </MobileHeaderListsWrapper>
          ) : null}
          <HeaderListsWrapper>
            {headerLists.map((element) => (
              <HeaderList key={uuid()} onClick={() => navigate(element.path)}>{element.text}</HeaderList>
            ))}

            <SearchBox>
              <HeaderSearchBar type="text"
                               value={search}
                               onChange={e => setSearch(e.target.value)}
                               placeholder={t("search_with_code")}
                               onKeyDown={handleKeyDown}
              />
              <SearchIcon src={Images.searchIcon} alt="searchIcon" onClick={handleNavigation} />
            </SearchBox>

          </HeaderListsWrapper>
          {isMobileSearchOpen ? <LogoSmallSize src={Images.logo} onClick={() => navigate(ROUTENAMES.welcome)} alt='logo'/> : null}
          {isMobileSearchOpen ? <SearchMediaIcon src={Images.searchIcon} alt="searchIcon"
                                                 onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)} /> : null}
          {isMobileSearchOpen ? null :
            <SearchBoxWrapper>
              <SearchBox isHundred>
                <HeaderMobileSearchBar type="text" onChange={e => setSearch(e.target.value)}
                                       placeholder={t("search_with_code")} value={search} />
                <CloseIconParent onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)}>
                  <CloseIcon src={Images.closeIcon} alt='close'/>
                </CloseIconParent>
                <SearchMobileIcon src={Images.searchIcon} alt="searchIcon" onClick={handleNavigation}
                />
              </SearchBox>
            </SearchBoxWrapper>
          }
          {isMobileSearchOpen ? <HamburgerIcon src={Images.hamburgerIcon} alt='hamburger' onClick={toggleMenu} /> : null}
        </ThirdHeaderWrapper>
        <HeaderSecondAdvertisement />
      </HeaderWrapper>
    </>
  );
};

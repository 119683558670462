import "./sliderStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper";
import {
  SliderAdvertisementImage,
  SliderAdvertisementImageAmeria,
  SliderAdvertisementInecoImage
} from "../welcome/styled";
import { Images } from "../../assets";
import { HeaderAdvertisementContainer } from "./styled";
import React from "react";

export const HeaderSecondAdvertisement = () => {
  return (
      <HeaderAdvertisementContainer>
        <Swiper
          scrollbar={{
            hide: true,
          }}
          autoplay={true}
          modules={[Scrollbar, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <SliderAdvertisementImageAmeria backgroundImage={Images.advertisement} loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <SliderAdvertisementInecoImage backgroundImage={Images.ecoIneco} loading="lazy" />
          </SwiperSlide>
        </Swiper>
      </HeaderAdvertisementContainer>
  )
}
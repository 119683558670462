import { createSlice } from "@reduxjs/toolkit";
import { topCars } from "store/topAnnouncments/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  topCarStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const TopCarData = createSlice({
  name: "topCars",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "topCarStatus":
        state.topCarStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [topCars.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carStatus = REDUX_STATES.PENDING;
    },
    [topCars.fulfilled]: (state, action) => {
      state.data = action?.payload?.cars;
      state.is_loading = false;
      state.carStatus = REDUX_STATES.SUCCEEDED;
    },
    [topCars.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = TopCarData.actions;

export default TopCarData.reducer;

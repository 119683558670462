import { ModalMainBlock, ModalSuccessMessage, ModalWrapper } from "modals/styled";

export const SuccessModal = ({ modalSuccessMessage, setIsOpen }) => {

  setTimeout(() => {
    setIsOpen(false);
  }, 2000);

  return (
    <ModalWrapper>
      <ModalMainBlock>
        <ModalSuccessMessage>
          {modalSuccessMessage}
        </ModalSuccessMessage>
      </ModalMainBlock>
    </ModalWrapper>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { blogSlug } from "store/blogSinglePage/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  blogSlugStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const BlogSLugData = createSlice({
  name: "blog_slug",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "blogStatus":
        state.blogStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [blogSlug.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.blogSlugStatus = REDUX_STATES.PENDING;
    },
    [blogSlug.fulfilled]: (state, action) => {
      state.data = action.payload.blog;
      state.is_loading = false;
      state.blogSlugStatus = REDUX_STATES.SUCCEEDED;
    },
    [blogSlug.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.blogSlugStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = BlogSLugData.actions;

export default BlogSLugData.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { currencyExchangeService } from "store/currencyExchange/service";

export const currencyExchange = createAsyncThunk(
  "CurrencyExchange/fetch",
  async(_, { rejectWithValue }) => {
    try {
      const response = await currencyExchangeService.currencyExchange();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
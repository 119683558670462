import axios from "axios";
import { API } from "configs/api";

export const serviceSlugService = {
  service_slug: (data) => axios.get(`${API.service_slug}/${data.slug}?timestamp=${Date.now()}&lang=${data.language}`, {
    headers: {
      "Cache-Control": "no-store"
    }
  })
};

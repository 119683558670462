import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useCreditValidation = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    name_person: yup.string().required(t("this_field_is_required")),
    surName_person: yup.string().required(t("this_field_is_required")),
    phone_number: yup.string().required(t("this_field_is_required"))
  });
};

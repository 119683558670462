import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";

export const FaqWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-inline: 120px;
  box-sizing: border-box;
  font-size: 20px;
  white-space: pre-wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  
  @media only screen and (max-width: 768px) {
    padding-inline: 60px;
    font-size: 16px;
  }

  @media only screen and (max-width: 428px) {
    padding-inline: 30px;
  }
`;

export const FaqTitle = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${DefaultFont};
  color: ${COLORS.main_color};
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 30px;
  
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const FaqContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  background: ${COLORS.white};
  padding: 20px;
  box-sizing: border-box;
  ${DefaultFont};
`;

export const EveryFaq = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border: 1px solid ${COLORS.main_color};
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 10px;
`;

export const EveryFaqTitle = styled.h1`
  ${DefaultFont};
  text-align: center;
  font-size: 16px;
  color: ${COLORS.main_color};
`;

export const EveryFaqAnswer = styled.span`
  ${DefaultFont};
  font-size: 14px;
`;

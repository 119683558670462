import styled from "styled-components";
import { COLORS } from "utils/colors";

export const ScrollToTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  border-radius: 50px;
  z-index: 12;
  right: 20px;
  bottom: 110px;
  background: ${COLORS.main_color};
  cursor: pointer;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

import { createSlice } from "@reduxjs/toolkit";
import { sparePartsWithModelID } from "store/sparePartsProductWithModelId/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  sparePartsWithModelIdStatus: null,
  data: [],
  errors: "",
};

export const sparePartsWithModelIdData = createSlice({
  name: "sparePartWithModelId",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "sparePartsWithModelIdStatus":
          state.sparePartsWithModelIdStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [sparePartsWithModelID.pending]: (state) => {
      state.errors = null;
      state.sparePartsWithModelIdStatus = REDUX_STATES.PENDING;
    },
    [sparePartsWithModelID.fulfilled]: (state, action) => {
      state.data = action.payload.sparePartsCategory;
      state.sparePartsWithModelIdStatus = REDUX_STATES.SUCCEEDED;
    },
    [sparePartsWithModelID.rejected]: (state, { payload }) => {
      state.sparePartsWithModelIdStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = sparePartsWithModelIdData.actions;

export default sparePartsWithModelIdData.reducer;

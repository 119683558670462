import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoBackBlock, GoBackImage } from "components/goBack/styled";
import { Images } from "assets";
import { ROUTENAMES } from "routes/routeNames";

export const GoBack = () => {
  const [isHomePage, setIsHomePage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const back = () => {
      if(location.pathname.includes("/blog/")) {
        navigate(ROUTENAMES.blog);
      } else if(location.pathname.includes("/cars/")) {
        window.close();
      } else if(location.pathname.includes("/see-reviews") || location.pathname.includes("/write-reviews")) {
        window.close();
      } else {
        navigate(-1)
      }
  }

  useEffect(() => {
    if(location.pathname === "/") {
      setIsHomePage(true)
    } else {
      setIsHomePage(false);
    }
  }, [location])

  return (
    <GoBackBlock onClick={back} isHomePage={isHomePage}>
      <GoBackImage src={Images.goBackButton} alt='back'/>
    </GoBackBlock>
  )
}

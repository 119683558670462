import { selectBlogSlug, selectBlogStatus } from "store/blogSinglePage/selector";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { blogSlug } from "store/blogSinglePage/actions";
import { IMAGE_STARTING_URL } from "utils/constants";
import { DateFormatter } from "utils/helpers";
import { Container, Content, LazyImage, Meta, Title } from "containers/blogPage/styled";

export const BlogPage = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const { slug } = useParams();
  const blogSlugData = useSelector(selectBlogSlug);
  const blogSlugDataStatus = useSelector(selectBlogStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(blogSlug({ slug, language }));
  }, [dispatch, slug, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <>
      {blogSlugDataStatus === "loading"
        ? <Container>
          <Title isLoading> Loading... </Title>
        </Container>
        : <Container>
          <Title>{blogSlugData.name_blog}</Title>
          <Meta>{t("posted_on")} {blogSlugData?.created_at &&
            <DateFormatter date={blogSlugData.created_at} />}
          </Meta>
          <LazyImage
            effect="blur"
            src={`${IMAGE_STARTING_URL}/${blogSlugData.image}`}
            alt="Blog post image" />
          <Content dangerouslySetInnerHTML={{ __html: blogSlugData.blog_sample_description_am }} />
          <Content dangerouslySetInnerHTML={{ __html: blogSlugData.name_blog }} />
          <Content dangerouslySetInnerHTML={{ __html: blogSlugData.text_one }} />
        </Container>
      }
    </>
  );
};

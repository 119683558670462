import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { carModels } from "store/carModels/actions";
import { carNames } from "store/carNames/actions";
import { selectCarModels } from "store/carModels/selector";
import { selectCarNames } from "store/carNames/selector";
import { country } from "store/country/action";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTENAMES } from "routes/routeNames";
import { selectCountry } from "store/country/selector";
import { cars } from "store/cars/actions";
import { CarType } from "components/welcome/carType";
import { COLORS } from "utils/colors";
import { carPriceFromOptions, carYearFromOptions, carYearToOptions } from "utils/options";
import {
  CheckBox,
  CheckBoxContainer,
  EveryCheckBoxWithText,
  Label,
  RightSideWithFilteration,
  SearchBlock,
  SelectField,
  SendButton,
  StyledSearchBlockWrapper
} from "components/welcome/styled.js";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.white,
    color: state.isFocused || state.isSelected ? COLORS.white : COLORS.main_color
  }),
  control: (provided, state) => ({
    ...provided,
    color: COLORS.main_color,
    boxShadow: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.main_color,
    borderColor: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.main_color
  })
};

export const SearchCarsBlock = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const { t } = useTranslation();
  const [carName, setCarName] = useState({
    value: "",
    label: t("car_name")
  });
  const [carModel, setCarModel] = useState({
    value: "",
    label: t("car_model")
  });
  const [selectPriceFrom, setSelectPriceFrom] = useState({
    value: "",
    label: t("price_from")
  });
  const [selectPriceTo, setSelectPriceTo] = useState({
    value: "",
    label: t("price_to")
  });
  const [selectYearFrom, setSelectYearFrom] = useState({
    value: "",
    label: t("year_from")
  });
  const [selectYearTo, setSelectYearTo] = useState({
    value: "",
    label: t("year_to")
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CarModels = useSelector(selectCarModels);
  const CarDataNames = useSelector(selectCarNames);
  const countryData = useSelector(selectCountry);
  const { handleSubmit } = useForm();
  const [searchParams] = useSearchParams();
  const isFilterCountryAvailable = searchParams.get("id");

  const handleClick = (id) => {
    navigate(`${ROUTENAMES.cars}?filterCountry=true&id=${id}`);
  };

  const onSubmit = data => {
    const filteredData = {
      car_year_from: selectYearFrom.value,
      car_year_to: selectYearTo.value,
      car_price_from: selectPriceFrom.value,
      car_price_to: selectPriceTo.value,
      car_name_id: carName.id,
      car_model_id: carModel.id,
      is_filter: 1
    };

    if (filteredData.car_name_id !== ""
      || filteredData.car_year_from !== ""
      || filteredData.car_year_to !== ""
      || filteredData.car_price_from !== ""
      || filteredData.car_price_to !== ""
      || filteredData.car_model_id !== "") {

      dispatch(cars(filteredData));
      navigate(`${ROUTENAMES.cars}?filteredData=${JSON.stringify(filteredData)}`);
    }
  };

  const selectCarNameOptions = CarModels.map(option => ({
    value: option.car_name,
    label: option.car_name,
    id: option.id
  }));

  const selectCarModelOptions = CarDataNames.map(option => ({
    value: option.model_name,
    label: option.model_name,
    id: option.id
  }));

  useEffect(() => {
    dispatch(carModels());
  }, [dispatch]);

  useEffect(() => {
    dispatch(country(language));
  }, [language]);

  useEffect(() => {
    if (carName.id) {
      dispatch(carNames({ car_name_id: carName.id }));
    }
  }, [carName]);

  useEffect(() => {
    localStorage.removeItem("filteredData");
  }, []);

  useEffect(() => {
    localStorage.removeItem("filterCountry");
  }, []);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (carModel.id) {
      return;
    } else {
      setCarModel({
        value: "",
        label: t("car_model")
      });
    }

    if (carName.id) {
      return;
    } else {
      setCarName({
        value: "",
        label: t("car_name")
      });
    }

    setSelectYearFrom({
      value: "",
      label: t("year_from")
    });

    setSelectYearTo({
      value: "",
      label: t("year_to")
    });

    setSelectPriceTo({
      value: "",
      label: t("price_to")
    });

    setSelectPriceFrom({
      value: "",
      label: t("price_from")
    });
  }, [language]);

  return (
    <StyledSearchBlockWrapper id='search-container'>
      <CarType />
      <SearchBlock>
        <RightSideWithFilteration onSubmit={handleSubmit(onSubmit)}>
          <SelectField
            options={selectCarNameOptions}
            value={carName}
            onChange={(selectedCarNameOption) => setCarName(selectedCarNameOption)}
            styles={customStyles}
          />

          <SelectField
            options={selectCarModelOptions}
            value={carModel}
            onChange={(selectedCarModelOption) => setCarModel(selectedCarModelOption)}
            styles={customStyles}
          />

          <SelectField
            options={carYearFromOptions}
            value={selectYearFrom}
            onChange={(carYearFromOptions) => setSelectYearFrom(carYearFromOptions)}
            isSearchable={false}
            styles={customStyles}
          />

          <SelectField
            options={carYearToOptions}
            value={selectYearTo}
            onChange={(carYearToOptions) => setSelectYearFrom(carYearToOptions)}
            isSearchable={false}
            styles={customStyles}
          />

          <SelectField
            options={carPriceFromOptions}
            value={selectPriceFrom}
            onChange={(carPriceFromOptions) => setSelectPriceFrom(carPriceFromOptions)}
            isSearchable={false}
            styles={customStyles}
          />

          <SelectField
            options={carPriceFromOptions}
            value={selectPriceTo}
            onChange={(carPriceToOptions) => setSelectPriceTo(carPriceToOptions)}
            isSearchable={false}
            styles={customStyles}
          />
          <SendButton>{t("search_text")}</SendButton>
        </RightSideWithFilteration>
      </SearchBlock>
      <CheckBoxContainer>
        {countryData.map((element) => {
          return (
            <EveryCheckBoxWithText key={uuid()}>
              <CheckBox type="radio" id={element.name_country} name="drone" value={element.id}
                        onClick={() => handleClick(element.id)}
                        defaultChecked={+element.id === +isFilterCountryAvailable} />
              <Label htmlFor={element.name_country}>{element.name_country}</Label>
            </EveryCheckBoxWithText>
          );
        })}
      </CheckBoxContainer>
    </StyledSearchBlockWrapper>
  );
};

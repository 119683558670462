import { Carsheader } from "components/cars/carsheader.js";
import { CarsContent } from "components/cars/carsContent.js";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { HeaderAdvertisement } from "components/header/headerAdvertisement";
import { useParams } from "react-router-dom";

export const Cars = () => {
  const { search } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <>
      <Carsheader titleText={t("carList")} />
      <HeaderAdvertisement />
      <CarsContent search={search}/>
    </>
  );
};
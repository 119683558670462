import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMAGE_STARTING_URL } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "routes/routeNames";
import { Salon } from "store/salonLocalizationsIds/actions";
import { selectSalons } from "store/salonLocalizationsIds/selector";
import {
  AvailableWrapper,
  CarsWrapperAvailable,
  CheckboxForSalon, ChooseSalonText,
  Content,
  ContentTitle,
  EverySalonWrapper,
  FilterWithPlace,
  SalonText,
  WrapperContent
} from "containers/availableCars/styled";
import { MotorcyclesAction } from "../../store/motorcycles-active/actions";
import { currencyExchange } from "../../store/currencyExchange/action";
import { selectMotorCycleData } from "../../store/motorcycles-active/selector";
import { EveryMotorCycleBlock, ImageOfMoto, MotoName, MotoPrice } from "../motorcycles/styled";
import { selectCurrencyExchange } from "../../store/currencyExchange/selector";
import { NumberWithDots } from "../../HOC/numberWithDots";
import axios from "axios";
import { InvisibleH1 } from "../../components/welcome/styled";

export const MotorcyclesActive = () => {
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(selectCurrencyExchange);
  const selectActiveCarsData = useSelector(selectMotorCycleData);
  const [selectNumberCars, setSelectNumberCars] = useState([]);
  const navigate = useNavigate();
  const selectActiveSalons = useSelector(selectSalons);

  const filteredCars = number > 0 ? selectNumberCars : selectActiveCarsData;

  useEffect(() => {
    dispatch(MotorcyclesAction());
    dispatch(currencyExchange());
    dispatch(Salon());
  }, [dispatch]);

  const fetchSalonData = (number) => {
    axios.get(`https://www.admin.ecomotors.am/api/motorcycle-only-location?salon_localization_id=${number}`)
      .then((resp) => setSelectNumberCars(resp.data.cars))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(number >= 1) {
      fetchSalonData(number)
    }
  }, [dispatch, number])

  return (
    <AvailableWrapper>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>

      <Content>
        <ContentTitle>{t("active-motorcycles")}</ContentTitle>

        <CarsWrapperAvailable>
          <FilterWithPlace>
            <ChooseSalonText>{t('select_auto_salon')}</ChooseSalonText>
            <EverySalonWrapper key={'id-1'}>
              <CheckboxForSalon
                type='checkbox'
                onChange={() => setNumber(0)}
                checked={number === 0}
              />
              <SalonText>{t('all_cars')}</SalonText>
            </EverySalonWrapper>
            {selectActiveSalons?.map((element, index) =>
              element.id > 8 || element.id === 6 || element.id === 5 ? null : (
                <EverySalonWrapper key={index}>
                  <CheckboxForSalon
                    type='checkbox'
                    onChange={() => setNumber(element.id)}
                    checked={number === element.id}
                  />
                  <SalonText>{element.salon_localization_text}</SalonText>
                </EverySalonWrapper>
              )
            )}
          </FilterWithPlace>
          <WrapperContent>
            {filteredCars?.map((moto) => (
              <EveryMotorCycleBlock onClick={() => navigate(`${ROUTENAMES.motorcycles_single_page}/${moto.slug}`)} key={moto.id}>
                {moto.product_images.map((image) => (
                  image.main_image === 1 && (
                    <ImageOfMoto
                      key={image.id}
                      alt='image'
                      src={`${IMAGE_STARTING_URL}/${image.image}`}
                    />
                  )
                ))}
                <MotoName>{moto?.product_name?.motorcycle_name} {moto?.product_model?.motorcycle_model}</MotoName>
                <MotoPrice>{t('price')} {NumberWithDots(moto?.motorcycle_price * currency.usd)} ֏</MotoPrice>
                <MotoPrice>{t('car_product_year')} {moto?.product_year?.car_year}</MotoPrice>
              </EveryMotorCycleBlock>
            ))}
          </WrapperContent>
        </CarsWrapperAvailable>
      </Content>
    </AvailableWrapper>
  );
};

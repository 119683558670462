import { createAsyncThunk } from "@reduxjs/toolkit";
import { carSlugService } from "store/carSinglePage/service";

export const carSlug = createAsyncThunk(
  "car/carSlug",
  async(data, { rejectWithValue }) => {
    try {
      const response = await carSlugService.car_slug(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

export const carWithArticulate = createAsyncThunk(
  "cars/fetch",
  async(data, { rejectWithValue }) => {
    try {
      const response = await carSlugService.car_with_articulate(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);


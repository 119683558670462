import { createAsyncThunk } from "@reduxjs/toolkit";
import { carModelService } from "store/carModels/service";

export const carModels = createAsyncThunk(
  "carModels/fetch",
  async(_, { rejectWithValue }) => {
    try {
      const response = await carModelService.carModels();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { MotorcyclesAction } from "./actions";

const initialState = {
  motorCyclesStatus: null,
  data: [],
  errors: "",
};

export const motorcycleData = createSlice({
  name: "motorCycles/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "motorCyclesStatus":
          state.motorCyclesStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [MotorcyclesAction.pending]: (state) => {
      state.errors = null;
      state.motorCyclesStatus = REDUX_STATES.PENDING;
    },
    [MotorcyclesAction.fulfilled]: (state, action) => {
      state.data = action.payload?.motorcycles;
      state.motorCyclesStatus = REDUX_STATES.SUCCEEDED;
    },
    [MotorcyclesAction.rejected]: (state, { payload }) => {
      state.motorCyclesStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = motorcycleData.actions;

export default motorcycleData.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { writeReviewService } from "./service";

export const writeReviewPost = createAsyncThunk(
  "writeReview",
  async(data, { rejectWithValue }) => {
    try {
      const response = await writeReviewService.writeReview(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

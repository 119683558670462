import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: capitalize;
  animation: ${fadeIn} 1s ease-in-out;
  color: ${({ isLoading }) => isLoading ? COLORS.main_color : COLORS.black};

  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

export const Meta = styled.p`
  color: #999;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-in-out;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

export const BlogEveryImage = styled.img`
  max-width: 100%;
  height: 200px;
`;

export const Content = styled.div`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
  animation: ${fadeIn} 1s ease-in-out;

  a {
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

export const LazyImage = styled(LazyLoadImage)`
  width: 100%;
`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceSlugService } from "store/serviceSinglePage/service";

export const serviceSlug = createAsyncThunk(
  "service/serviceSlug",
  async(data, { rejectWithValue }) => {
    try {
      const response = await serviceSlugService.service_slug(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "assets";
import "containers/360CarCameraHondaENS1/style.css";
import { MainImagesBlock, MainImagesWrapper } from "components/camera360/styled";

export const CarCamera360DegreeHondaEns1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageUrls = [
    Images.HondaFrontBackground,
    Images.HondaToRight,
    Images.HondaToRightMid,
    Images.HondaMid,
    Images.HondaToRightBack,
    Images.HondaToRightEnd,
    Images.HondaBackBackground,
    Images.HondaToLeftEnd,
    Images.HondaToLeftBack,
    Images.HondaLeftMid,
    Images.HondaToLeftMid,
    Images.HondaToLeft,
    Images.HondaFrontBackground,
  ];

  useEffect(() => {
    const images = document.querySelectorAll(".card img");
    images[0].classList.add("active");

    if (windowWidth > 768) {
      const spans = document.querySelectorAll(".card span");
      const images = document.querySelectorAll(".card img");
      spans.forEach((span, index) => {
        span.addEventListener("mouseover", () => {
          images.forEach((image) => {
            image.classList.remove("active");
          });

          images[index].classList.add("active");
        });
      });
    } else {
      const card = document.querySelector(".card");
      let touchStartX = 0;
      let touchEndX = 0;
      let activeIndex = 0;

      card.addEventListener("touchstart", handleTouchStart, { passive: true });
      card.addEventListener("touchmove", handleTouchMove, { passive: true });
      card.addEventListener("touchend", handleTouchEnd, { passive: true });

      function handleTouchStart(event) {
        touchStartX = event.touches[0].clientX;
      }

      function handleTouchMove(event) {
        touchEndX = event.touches[0].clientX;
        handleGesture();
      }

      function handleTouchEnd() {
        touchStartX = 0;
        touchEndX = 0;
      }

      function handleGesture() {
        const gestureThreshold = 10;
        if (touchStartX - touchEndX > gestureThreshold) {
          showNextImage();
        } else if (touchEndX - touchStartX > gestureThreshold) {
          showPreviousImage();
        }
      }

      function showNextImage() {
        activeIndex = (activeIndex + 1) % images.length;
        setActiveImage();
      }

      function showPreviousImage() {
        activeIndex = (activeIndex - 1 + images.length) % images.length;
        setActiveImage();
      }

      function setActiveImage() {
        images.forEach((image) => {
          image.classList.remove("active");
        });
        images[activeIndex].classList.add("active");
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <MainImagesWrapper backgroundWhite>
      <MainImagesBlock>{t("hover_on_pictures")}</MainImagesBlock>
      <div className="parent-card">
        <div className="card">
          {imageUrls.map((element, index) => {
            return (
              <div>
                <span style={{ "--i": index }}></span>
                <img src={element} alt="image" />
              </div>
            );
          })}
        </div>
      </div>
    </MainImagesWrapper>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carsheader } from "components/cars/carsheader.js";
import { aboutUsData } from "store/aboutUs/actions";
import { ourTeamData } from "store/ourTeam/actions";
import { selectAboutUsData } from "store/aboutUs/selector";
import { Loading } from "components/loading";
import { selectOurTeamData } from "store/ourTeam/selector";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useTranslation } from "react-i18next";
import { WelcomeSlider } from "components/welcome/welcomeSlider";
import { IMAGE_STARTING_URL } from "utils/constants";
import { CarModelsSlider } from "components/carModelsSlider";
import {
  AboutUsBlock,
  AboutUsContainer,
  AboutUsContent,
  AboutUsInfoText,
  AboutUsWrapper,
  OurTeamCards, OurTeamLeadCard,
  OurTeamText,
  OurTeamWrapper,
  OurWorkerCard,
  OurWorkerCardDescriptionBlock, TeamLeadCardWrapper,
  WorkerDescription,
  WorkerName, WorkerPosition
} from "containers/about/styled";


export const About = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const AboutUsData = useSelector(selectAboutUsData);
  const selectOurTeam = useSelector(selectOurTeamData);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(aboutUsData({ language }));
    dispatch(ourTeamData(language));
  }, [dispatch, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <AboutUsWrapper>
      <Carsheader titleText={t("aboutUs")} />
      {AboutUsData ? <AboutUsBlock>
          <AboutUsContainer>
            <AboutUsContent>
              <AboutUsInfoText dangerouslySetInnerHTML={{ __html: AboutUsData?.aboutUs?.about_us_text }} />
            </AboutUsContent>
            <OurTeamWrapper>
              <OurTeamText>{t("our_team")}</OurTeamText>
              <OurTeamCards>
                {selectOurTeam?.map((team) => {
                    return (team.team_leader ?
                        (
                          <TeamLeadCardWrapper>
                            <OurTeamLeadCard backgroundImage={`${IMAGE_STARTING_URL}/${team?.image}`}>
                              <OurWorkerCardDescriptionBlock className="hover">
                                <WorkerName isteamlead>{team?.personal_full_name}</WorkerName>
                                <WorkerPosition role>{team?.personal_position}</WorkerPosition>
                                <WorkerDescription>{team?.personal_description}</WorkerDescription>
                              </OurWorkerCardDescriptionBlock>
                            </OurTeamLeadCard>
                          </TeamLeadCardWrapper>
                        )
                        :
                        (
                          <OurWorkerCard backgroundImage={`${IMAGE_STARTING_URL}/${team?.image}`}>
                            <OurWorkerCardDescriptionBlock className="hover">
                              <WorkerName>{team?.personal_full_name}</WorkerName>
                              <WorkerPosition role>{team?.personal_position}</WorkerPosition>
                              <WorkerDescription>{team?.personal_description}</WorkerDescription>
                            </OurWorkerCardDescriptionBlock>
                          </OurWorkerCard>
                        )
                    );
                  }
                )}
              </OurTeamCards>


            </OurTeamWrapper>
            <WelcomeSlider AboutUsData={AboutUsData} />
            <CarModelsSlider />
          </AboutUsContainer>
        </AboutUsBlock> :
        <Loading />
      }
    </AboutUsWrapper>
  )
    ;
};

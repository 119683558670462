import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { requestSparePartPost } from "store/requestSparePart/actions";

const initialState = {
  requestSparePartStatus: null,
  errors: null,
  errorMessage: "",
};

export const requestSparePart = createSlice({
  name: "requestSparePart",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "requestSparePartStatus":
          state.requestSparePartStatus = null;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: {
    [requestSparePartPost.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.requestSparePartStatus = REDUX_STATES.PENDING;
    },
    [requestSparePartPost.fulfilled]: (state, action) => {
      state.requestSparePartStatus = REDUX_STATES.SUCCEEDED;
    },
    [requestSparePartPost.rejected]: (state, { payload }) => {
      state.requestSparePartStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = requestSparePart.actions;

export default requestSparePart.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ourTeamService } from "store/ourTeam/service";

export const ourTeamData = createAsyncThunk(
  "ourTeamData/fetch",
  async(language, { rejectWithValue }) => {
    try {
      const response = await ourTeamService.ourTeam(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { CallToCompanyWrapper, PhoneIcon } from "components/callToCompany/styled";
import { FooterHref } from "components/footer/styled";

export const CallToCompany = () => {
  return (
    <FooterHref href={`tel:+374-96-94-94-66`}>
      <CallToCompanyWrapper>
        <PhoneIcon />
      </CallToCompanyWrapper>
    </FooterHref>
  )
}
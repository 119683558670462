import { createSlice } from "@reduxjs/toolkit";
import { carNames } from "store/carNames/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  carNamesStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const CarNamesData = createSlice({
  name: "carNames",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carNamesStatus":
        state.carNamesStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [carNames.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carNamesStatus = REDUX_STATES.PENDING;
    },
    [carNames.fulfilled]: (state, action) => {
      state.data = action.payload.carModels;
      state.is_loading = false;
      state.carNamesStatus = REDUX_STATES.SUCCEEDED;
    },
    [carNames.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carNamesStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarNamesData.actions;

export default CarNamesData.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { profileData } from "store/profile/actions";

const initialState = {
  profileDataStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const UserProfileData = createSlice({
  name: "profileData/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "profileDataStatus":
        state.profileDataStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [profileData.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.profileDataStatus = REDUX_STATES.PENDING;
    },
    [profileData.fulfilled]: (state, action) => {
      state.data = action?.payload?.profile;
      state.is_loading = false;
      state.profileDataStatus = REDUX_STATES.SUCCEEDED;
    },
    [profileData.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.profileDataStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = UserProfileData.actions;

export default UserProfileData.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { signIn, signUp } from "./actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  accessToken: "",
  error: null,
  signInStatus: null,
  signUpStatus: null,
  errors: null,
  errorMessage: "",
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "signInStatus":
        state.signInStatus = null;
        break;
      case "signUpStatus":
        state.signUpStatus = null;
        break;
      case "profileStatus":
        state.profilesStatus = null;
        break;
      default:
        break;
      }
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.signInStatus = REDUX_STATES.PENDING;
    },
    [signIn.fulfilled]: (state, action) => {
      state.accessToken = action?.payload?.data?.access_token;
      state.signInStatus = REDUX_STATES.SUCCEEDED;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.signInStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = payload.data.message;
      }
    },
    [signUp.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.signUpStatus = REDUX_STATES.PENDING;
    },
    [signUp.fulfilled]: (state, action) => {
      state.accessToken = action?.payload?.data?.access_token;
      state.signUpStatus = REDUX_STATES.SUCCEEDED;
    },
    [signUp.rejected]: (state, { payload }) => {
      state.signUpStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = auth.actions;

export default auth.reducer;

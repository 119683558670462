import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { creditWithCardPost } from "store/creditWithCardPost/actions";

const initialState = {
  creditWithCardPostStatus: null,
  errors: null,
  errorMessage: "",
};

export const CreditWithCardPost = createSlice({
  name: "creditWithCardPost",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "creditWithCardPostStatus":
        state.creditWithCardPostStatus = null;
        break;
      default:
        break;
      }
    },
  },
  extraReducers: {
    [creditWithCardPost.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.creditWithCardPostStatus = REDUX_STATES.PENDING;
    },
    [creditWithCardPost.fulfilled]: (state, action) => {
      state.creditWithCardPostStatus = REDUX_STATES.SUCCEEDED;
    },
    [creditWithCardPost.rejected]: (state, { payload }) => {
      state.creditWithCardPostStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CreditWithCardPost.actions;

export default CreditWithCardPost.reducer;

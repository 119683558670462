import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const SignInSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    email: yup.string().email(t("error_valid_email")).required(t("error_required_email")),
    password: yup.string().min(6, t("error_password_min")).max(10, t("error_password_max")).required(t("error_password_required"))
  });
};


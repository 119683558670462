import { createAsyncThunk } from "@reduxjs/toolkit";
import { changeProfileService } from "store/changeProfileInfo/service";

export const changeProfileData = createAsyncThunk(
  "changeProfileData/fetch",
  async(body, { rejectWithValue }) => {
    try {
      const response = await changeProfileService.change_profile_data(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

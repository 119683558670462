import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useForm } from "react-hook-form";
import { carSlug } from "store/carSinglePage/actions";
import { cars } from "store/cars/actions";
import { carModels } from "store/carModels/actions";
import { carNames } from "store/carNames/actions";
import { selectCarModels } from "store/carModels/selector";
import { selectCarNames } from "store/carNames/selector";
import { selectCars } from "store/cars/selector";
import { selectCarSlug } from "store/carSinglePage/selector";
import { COLORS } from "utils/colors";
import { IMAGE_STARTING_URL } from "utils/constants";
import { SelectField, SendButton } from "containers/compareCars/styled";
import {
  AboutCarBlock, AboutCarBlockKeyDiv,
} from "containers/carPage/styled";
import {
  CarContentBlock,
  CarContentImage,
  RightSideFullWrapper,
  RightSideWithFilteration,
  AboutCarValue,
  AboutCarKey
} from "components/welcome/styled";


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.white,
    color: state.isFocused || state.isSelected ? COLORS.white : COLORS.main_color
  }),
  control: (provided, state) => ({
    ...provided,
    color: COLORS.main_color,
    boxShadow: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.main_color,
    borderColor: state.isFocused || state.isSelected ? COLORS.main_color : COLORS.main_color
  })
};

export const LeftSide = () => {
  const carSelected = useSelector(selectCars);
  const slug = carSelected[0]?.car_slug;
  const carSelectedData = useSelector(selectCarSlug);
  const { t } = useTranslation();
  const CarModels = useSelector(selectCarModels);
  const dispatch = useDispatch();
  const CarDataNames = useSelector(selectCarNames);
  const { handleSubmit } = useForm();

  const [carName, setCarName] = useState({
    value: "",
    label: t("car_name")
  });
  const [carModel, setCarModel] = useState({
    value: "",
    label: t("car_model")
  });

  const carSelectedDataNameOptions = CarModels.map(option => ({
    value: option.car_name,
    label: option.car_name,
    id: option.id
  }));

  const carSelectedDataModelOptions = CarDataNames.map(option => ({
    value: option.model_name,
    label: option.model_name,
    id: option.id
  }));

  const onSubmit = (data) => {
    const filteredData = {
      car_name_id: carName.id,
      car_model_id: carModel.id,
      car_year_from: null,
      car_year_to: null,
      car_price_from: null,
      car_price_to: null,
      is_filter: 1
    };

    if (filteredData.car_name_id !== "" || filteredData.car_model_id !== "") {
      dispatch(cars(filteredData));
    }
  };

  useEffect(() => {
    dispatch(carModels());
  }, [dispatch]);

  useEffect(() => {
    if (slug) {
      dispatch(carSlug({ slug, language: "am" }));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (carName.id) {
      dispatch(carNames({ car_name_id: carName.id }));
    }
  }, [carName]);

  return (
    <RightSideFullWrapper>
      <RightSideWithFilteration onSubmit={handleSubmit(onSubmit)}>
        <SelectField
          options={carSelectedDataNameOptions}
          value={carName}
          onChange={(selectedCarNameOption) => setCarName(selectedCarNameOption)}
          styles={customStyles}
        />

        <SelectField
          options={carSelectedDataModelOptions}
          value={carModel}
          onChange={(selectedCarModelOption) => setCarModel(selectedCarModelOption)}
          styles={customStyles}
        />
        <SendButton>{t("search_text")}</SendButton>
      </RightSideWithFilteration>

      {carSelectedData?.product_images ?
      <CarContentBlock>
        {
          carSelectedData?.product_images?.map((item) => {
            return item.main_image ? <CarContentImage
              key={item.id}
              effect="blur"
              src={item.main_image ? `${IMAGE_STARTING_URL}/${item?.image}` : null}
            /> : null;
          })
        }
        <AboutCarBlock>
          <AboutCarKey is_car_additional>{`${t("car_model")}/${t("car_name")}`}</AboutCarKey>
          <AboutCarValue is_car_additional isTextBig>
            {carSelectedData?.product_name?.car_name} {carSelectedData?.product_model?.model_name}</AboutCarValue>
        </AboutCarBlock>

        <AboutCarBlock>
          <AboutCarKey>{t("car_type")}</AboutCarKey>
          <AboutCarValue>
            {carSelectedData?.product_body?.car_body_name}
          </AboutCarValue>
        </AboutCarBlock>

        <AboutCarBlock>
          <AboutCarKey>{t("car_product_year")}</AboutCarKey>
          <AboutCarValue>
            {carSelectedData?.product_year?.car_year}
          </AboutCarValue>
        </AboutCarBlock>

        <AboutCarBlock>
          <AboutCarKey>{t("car_motor")}</AboutCarKey>
          <AboutCarValue>
            {carSelectedData?.product_motor?.car_motor &&
              `${carSelectedData?.product_motor?.car_motor} KW`}
          </AboutCarValue>
        </AboutCarBlock>

        {carSelectedData?.car_horsepower ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_horsepower")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_horsepower}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_cruising_range ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_cruising_range")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_cruising_range} կմ
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_max_speed ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_max_speed")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_max_speed} կմ/ժ
            </AboutCarValue>
          </AboutCarBlock>) : null}

        {carSelectedData?.car_official ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_official")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_official}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_torque ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_torque")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_torque}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_battery_capacity ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_battery_capacity")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_battery_capacity} կվտ/ժ
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_battery_energy_density ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_battery_energy_density")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_battery_energy_density} (վտ/կգ)
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_wheel_size ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_wheel_size")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_wheel_size}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_wheel_size_back ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_wheel_size_back")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_wheel_size_back}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_fast_charging ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_fast_charging")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_fast_charging} ժ
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_slow_charging ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_slow_charging")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_slow_charging} {t('hour')}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_driving_mode ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_driving_mode")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_driving_mode}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_doors ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_doors")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_doors}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.product_seat_count?.seat_count ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_seat_count_id")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.product_seat_count.seat_count}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_length_width_height ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_length_width_height")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_length_width_height} (մմ)
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_wheelbase ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_wheelbase")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_wheelbase} (մմ)
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_limited_weight ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_limited_weight")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_limited_weight} (կգ)
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_full_load_array ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_full_load_array")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_full_load_array} (կգ)
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_driver_support_video ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_driver_support_video")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_driver_support_video}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_parking_radar ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_parking_radar")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_parking_radar}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_battery_temperature_control_system ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_battery_temperature_control_system")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_battery_temperature_control_system}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_abs_anti_lock_system ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_abs_anti_lock_system")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_abs_anti_lock_system}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_brake_force_distribution_ebd_cbc ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_brake_force_distribution_ebd_cbc")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_brake_force_distribution_ebd_cbc}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_braking_assistant_eba_ba ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_braking_assistant_eba_ba")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_braking_assistant_eba_ba}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_traction_control_tcs_asr ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_traction_control_tcs_asr")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_traction_control_tcs_asr}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_body_stabilization_system_esp_dsc ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_body_stabilization_system_esp_dsc")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_body_stabilization_system_esp_dsc}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_active_safety_warning_system ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_active_safety_warning_system")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_active_safety_warning_system}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_active_braking ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_active_braking")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_active_braking}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_lane_keeping_assistant ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_lane_keeping_assistant")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_lane_keeping_assistant}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_consultants_design_development_engineering ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_consultants_design_development_engineering")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_consultants_design_development_engineering}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_child_seat_interface ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_child_seat_interface")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_child_seat_interface}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_navigation_system ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_navigation_system")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_navigation_system}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_hill_assistant ? (
          <AboutCarBlock>
            <AboutCarKey>HILL assistant (HAC)</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_hill_assistant}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_brake_power_recovery_system ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_brake_power_recovery_system")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_brake_power_recovery_system}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {carSelectedData?.car_choice_of_driving_modes ? (
          <AboutCarBlock>
            <AboutCarKey>{t("car_choice_of_driving_modes")}</AboutCarKey>
            <AboutCarValue>
              {carSelectedData?.car_choice_of_driving_modes}
            </AboutCarValue>
          </AboutCarBlock>
        ) : null}

        {/*<AboutCarBlock>*/}
        {/*  <AboutCarKey>{t("car_tugboat")}</AboutCarKey>*/}
        {/*  <AboutCarValue>*/}
        {/*    {carSelectedData?.product_tugboat?.car_tugboat}*/}
        {/*  </AboutCarValue>*/}
        {/*</AboutCarBlock>*/}

        {carSelectedData?.product_additional
          ? carSelectedData?.product_additional.map(
            ({ car_additional, car_additional_value }, index) => (
              <AboutCarBlock key={index}>
                <AboutCarBlockKeyDiv>
                  <IoIosCheckmarkCircleOutline />
                  <AboutCarKey>{car_additional}</AboutCarKey>
                </AboutCarBlockKeyDiv>
                <AboutCarValue>{car_additional_value}</AboutCarValue>
              </AboutCarBlock>
            )
          )
          : null}
      </CarContentBlock> : null}
    </RightSideFullWrapper>
  );
};

import styled from "styled-components";
import { COLORS } from "utils/colors.js";
import { DefaultFont } from "utils/defaultFont.js";
import { BiHeart } from "react-icons/bi";
import { REDUX_STATES } from "utils/constants";

export const CarPageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const CarPageComparingButton = styled.button`
  width: 280px;
  height: 40px;
  outline: none;
  background: ${COLORS.main_color};
  border: 2px solid ${COLORS.main_color};
  margin-bottom: 20px;
  border-radius: 16px;
  color: ${COLORS.white};
  font-size: 16px;
  transition: 0.4s;
  transition-timing-function: ease-in;
  cursor: pointer;
  
  &:hover {
    background: ${COLORS.white};
    box-shadow: 0 0 50px -4px rgba(145, 198, 93, 1);
    color: ${COLORS.main_color};
  }
`;

export const CarWithBigImage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
  z-index: 11;
`;

export const CarBigImageBlock = styled.div`
  width: 70% !important;
  height: 506px !important;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  cursor: pointer !important;

  @media only screen and (max-width: 1280px) {
    width: 95% !important;
    height: 400px !important;
  }

  @media only screen and (max-width: 768px) {
    height: 250px !important;
  }

  @media only screen and (max-width: 428px) {
    height: 200px !important;
  }
`;

export const LikeIconParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 2px;
  z-index: 2;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: ${({ selectFavouriteStatus }) =>
          selectFavouriteStatus === REDUX_STATES.SUCCEEDED
                  ? COLORS.white
                  : COLORS.main_color};
  border-radius: 10px;
`;

export const LikeIcon = styled(BiHeart)`
  z-index: 2;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: ${({ selectfavouritestatus }) =>
          selectfavouritestatus === REDUX_STATES.SUCCEEDED
                  ? COLORS.main_color
                  : COLORS.white};
`;

export const CarPageContent = styled.div`
  width: 1920px;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.main_color};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  @media only screen and (max-width: 1920px) {
    width: 1275px;
    justify-content: space-between;
  }

  @media only screen and (max-width: 1280px) {
    width: 760px;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    width: 420px;
    flex-direction: column;
    margin-top: 32px;
  }

  @media only screen and (max-width: 428px) {
    width: 320px;
    flex-direction: column;
  }
`;

export const ServicesAdvertisementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 50px;
  border: 2px solid ${COLORS.main_color};
  background: ${COLORS.white};
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 20;
  cursor: pointer;
  transition: 0.4s linear;

  &:hover {
    background: rgba(145, 198, 93, 0.41);
  }
  
  @media only screen and (max-width: 768px) {
    top: 70px;
  }

  .service_icon {
    font-size: 20px;
    color: ${COLORS.black};
  }
`;

export const ServiceName = styled.span`
  font-size: 20px;
  color: ${COLORS.black};
`;

export const CarPageLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 768px;
  height: auto;
  min-height: 800px;
  flex-shrink: 0;

  @media only screen and (max-width: 1280px) {
    min-height: unset;
    width: 100%;
  }
  
  .swiper-button-prev {
    color: black !important;
  }

  .swiper-button-next {
    color: black !important;
  }
`;

export const CarShareBlock = styled.div`
  width: 100%;

  .css-1dimb5e-singleValue {
    color: ${COLORS.main_color};
  }

  .css-1u9des2-indicatorSeparator {
    color: ${COLORS.main_color};
  }

  .css-1xc3v61-indicatorContainer svg {
    color: ${COLORS.main_color};
  }
`;

export const CarShareDiv = styled.div`
  display: flex;
  justify-content: start;
  font-weight: 700;
  gap: 6px;
  align-items: center;

  span {
    color: ${COLORS.main_color};
    font-size: 18px;
  }

  img {
    width: 20px;
  }
`;

export const CarPageRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 504px;
  height: auto;
  min-height: 800px;
  flex-shrink: 0;
  border-left: 1px solid ${COLORS.main_color};

  @media only screen and (max-width: 1280px) {
    width: 100%;
    min-height: unset;
  }
`;

export const CarCharacteristics = styled.div`
  display: flex;
  flex-direction: column;
  width: 504px;
  height: auto;

  @media only screen and (max-width: 1280px) {
    width: 100%;
    min-height: unset;
  }
`;

export const CarTitleAndAddFavorite = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  border-bottom: 1px solid ${COLORS.main_color};
  padding-inline: 10px;
`;

export const AddToFavorites = styled.button`
  width: 140px;
  height: 48px;
  background: ${COLORS.main_color};
  border: unset;
  outline: unset;
  color: ${COLORS.white};
  transition: 0.4s;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  ${DefaultFont};

  &:hover {
    background: ${COLORS.white};
    border: 1px solid ${COLORS.main_color};
    border-radius: 4px;
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 428px) {
    width: 80px;
    height: 24px;
    font-size: 14px;
  }
`;

export const CarRightSideTitle = styled.span`
  font-size: 1.1vw;
  color: ${COLORS.main_color};

  @media only screen and (max-width: 1280px) {
    font-size: 1.6vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14.5px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 11px;
  }
`;

export const CarRightSideTitleH1 = styled.span`
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  color: ${COLORS.main_color};
  text-transform: capitalize;

  @media only screen and (max-width: 1280px) {
    font-size: 1.6vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14.5px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 11px;
  }
`;

export const AboutCarBlock = styled.div`
  width: 100%;
  height: auto;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({isArticulate}) => isArticulate ? "none" : `1px solid ${COLORS.main_color}`};
`;

export const AboutCarBlockKeyDiv = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 10px;
`;

export const AboutCarKey = styled.span`
  color: ${({ is_car_additional }) =>
          is_car_additional ? COLORS.main_color : COLORS.black};
  cursor: ${({ is_car_additional }) =>
          is_car_additional ? "pointer" : "unset"};
  font-size: ${({ is_car_additional }) =>
          is_car_additional ? "20px" : "14px"};
  font-weight: 600;
  ${DefaultFont};
  padding-inline: 10px;
  text-transform: capitalize;

  @media only screen and (max-width: 428px) {
    font-size: ${({ is_car_additional }) =>
            is_car_additional ? "14px" : "10px"};
  }
`;

export const AboutCarValue = styled.span`
  color: ${({ is_car_additional }) =>
          is_car_additional ? COLORS.main_color : COLORS.black};
  font-size: ${({ is_car_additional }) =>
          is_car_additional ? "20px" : "14px"};
  font-weight: 800;
  box-sizing: content-box;
  padding-right: 10px;
  ${DefaultFont};
  text-transform: capitalize;
  word-break: break-all;

  @media only screen and (max-width: 428px) {
    font-size: 12px;
  }
`;

export const AboutCarValueName = styled.h1`
  color: ${({ is_car_additional, isYoutube }) =>
  is_car_additional ? COLORS.main_color : isYoutube ? 'red !important' : COLORS.black};
  font-size: ${({ is_car_additional, isYoutube }) =>
  is_car_additional ? "20px" : isYoutube ? "20px" : "14px"};
  font-weight: 800;
  box-sizing: content-box;
  padding-right: 10px;
  ${DefaultFont};
  text-transform: capitalize;
  word-break: break-all;

  @media only screen and (max-width: 428px) {
    font-size: 12px;
  }
  
  a {
    color: red;
  }
`;

export const CallSellerAndInfoAboutSeller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${COLORS.main_color};
  padding-top: 10px;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  @media only screen and (max-width: 428px) {
    flex-direction: column;
  }
`;

export const CallToSeller = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 10px;
  padding-bottom: 8px;
`;

export const PhoneImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const CallNumberHref = styled.a`
  text-decoration: none;
`;

export const CallNumber = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  ${DefaultFont};
  color: ${({ isInModal }) => (isInModal ? COLORS.white : COLORS.main_color)};

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CarsWithOrder = styled.div`
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  transition: top 2.5s ease-in-out;
  width: 400px;
  background: ${COLORS.main_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 15;
  border-radius: 8px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media only screen and (max-width: 428px) {
    width: 280px;
  }
`;

export const CarsWithOrderText = styled.span`
  font-size: 26px;
  color: ${COLORS.main_color};
  font-weight: 600;
  ${DefaultFont};
  position: relative;
  z-index: 10;
  cursor: pointer;
  text-align: ${({ isWithCredit }) => isWithCredit && "right"};

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: center;
  }

  &:hover {
    opacity: 0.9;
    color: #59931f;
  }
`;

export const OrderBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-height: 100px;
  height: auto;
  border-bottom: 1px solid ${COLORS.main_color};
`;

export const OrderFrom = styled.span`
  position: relative;
  z-index: 12;
  font-size: 22px;
  color: ${COLORS.white};
  ${DefaultFont};

  @media only screen and (max-width: 428px) {
    font-size: 16px;
  }
`;

export const CloseButton = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 15;
  cursor: pointer;
  right: 20px;
  top: 20px;
`;

export const CallBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const VideoLink = styled.video`
  width: 100%;
`;

export const SeeMoreInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: ${COLORS.dark_blue};
  border-bottom: 1px solid ${COLORS.main_color};
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  text-decoration: underline;
`;

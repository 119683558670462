  import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CarSpecialPageSlider } from "components/cars/carSpecialPageSlider.js";
import { useDispatch, useSelector } from "react-redux";
import { carSlug } from "store/carSinglePage/actions";
import { selectCarSlug, selectCarStatus } from "store/carSinglePage/selector";
import { Images } from "assets";
import { Loading } from "components/loading";
import { selectWebsiteMainData } from "store/websiteMainData/selector";
import { websiteMainData } from "store/websiteMainData/actions";
import { favouriteCars } from "store/favourite/actions";
import { useTranslation } from "react-i18next";
import { useCloseOnClickOutside } from "hooks/useCloseOnClickOutside";
import { NumberWithDots } from "HOC/numberWithDots";
import { currencyExchange } from "store/currencyExchange/action";
import { selectCurrencyExchange } from "store/currencyExchange/selector";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { NotFound } from "containers";
import { CreditModal } from "modals/creditModal";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { resetStatus } from "store/favourite/slice";
import {
  AboutCarBlock,
  AboutCarBlockKeyDiv,
  AboutCarKey,
  AboutCarValue, AboutCarValueName,
  CallNumber,
  CallNumberHref,
  CallSellerAndInfoAboutSeller,
  CallToSeller,
  CarCharacteristics,
  CarPageContent,
  CarPageLeftSide,
  CarPageRightSide,
  CarPageWrapper,
  CarRightSideTitle,
  CarRightSideTitleH1,
  CarsWithOrderText,
  CarTitleAndAddFavorite,
  PhoneImage, SeeMoreInfo,
  VideoLink
} from "containers/carPage/styled.js";
import { FaYoutube } from "react-icons/fa";
  import { InvisibleH1 } from "../../components/welcome/styled";

export const CarPage = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
    const carSelectedData = useSelector(selectCarSlug);
  const carSelectedStatus = useSelector(selectCarStatus);
  const selectWebsiteData = useSelector(selectWebsiteMainData);
  const currency = useSelector(selectCurrencyExchange);
  const usd = currency.usd?.slice(0, 3);
  const rub = currency.rub?.slice(0, 4);
  const { secondRef, secondIsOpen, setSecondIsOpen } = useCloseOnClickOutside();
  const { thirdRef, thirdIsOpen, setThirdIsOpen } = useCloseOnClickOutside();
  const [isRestOpen, setIsRestOpen] = useState(false);

  const handleCreditModal = () => {
    setSecondIsOpen(!secondIsOpen);
  };

  const saveCarAsFavourite = (id) => {
    dispatch(
      favouriteCars({
        product_car_id: id
      })
    );
  };

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    if (!secondIsOpen) {
      document.body.style.overflow = "unset";
    }
  });

  useEffect(() => {
    dispatch(websiteMainData(language));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(carSlug({ slug, language }));
  }, [dispatch, language, location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    dispatch(resetStatus());
  }, [slug]);

  useEffect(() => {
    const title = carSelectedData?.product_name?.car_name + " " + carSelectedData?.product_model?.model_name;
    const imageUrl = `${IMAGE_STARTING_URL}/${carSelectedData?.product_images?.[0]?.image}`;

    const setMetaTags = () => {
      const metaTags = [
        { property: "og:title", content: title },
        { property: "og:image", content: imageUrl }
      ];

      metaTags.forEach((tag) => {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("property", tag.property);
        metaTag.setAttribute("content", tag.content);
        document.head.appendChild(metaTag);
      });
    };

    setMetaTags();

    return () => {
      const metaTags = document.querySelectorAll("meta[property^=\"og:\"]");
      metaTags.forEach((tag) => {
        document.head.removeChild(tag);
      });
    };
  }, [carSelectedData]);

  return (
    <>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
      <CarPageWrapper>
        {carSelectedData?.length === 0 ||
        carSelectedStatus === REDUX_STATES.PENDING ? (
          <Loading />
        ) : carSelectedData == null ? (
          <NotFound />
        ) : (
          <CarPageContent>
            {secondIsOpen ? (
              <CreditModal
                firstRef={secondRef}
                secondIsOpen={secondIsOpen}
                setSecondIsOpen={setSecondIsOpen}
              />
            ) : null}
            <CarPageLeftSide>
              <CarSpecialPageSlider
                setThirdIsOpen={setThirdIsOpen}
                thirdIsOpen={thirdIsOpen}
                carData={carSelectedData}
                saveCarAsFavourite={saveCarAsFavourite}
              />
              <CallSellerAndInfoAboutSeller>
                <CallToSeller>
                  <CallNumberHref
                    href={`tel:${
                      selectWebsiteData?.phone_one
                        ? selectWebsiteData?.phone_one
                        : "+374-96-94-94-66"
                    }`}
                  >
                    <CallNumber>
                      <PhoneImage src={Images.phoneIcon} alt='phone icon'/>
                      {selectWebsiteData?.phone_one
                        ? selectWebsiteData?.phone_one
                        : "+374-96-94-94-66"}{" "}
                    </CallNumber>
                  </CallNumberHref>

                  <CallNumberHref
                    href={`tel:${
                      selectWebsiteData?.phone_two
                        ? selectWebsiteData?.phone_two
                        : "+374-96-94-94-66"
                    }`}
                  >
                    <CallNumber>
                      <PhoneImage src={Images.phoneIcon} alt='phone icon'/>
                      {selectWebsiteData?.phone_two
                        ? selectWebsiteData?.phone_two
                        : "+374-96-94-94-66"}{" "}
                    </CallNumber>
                  </CallNumberHref>
                </CallToSeller>
                <CallToSeller>
                  <CarsWithOrderText isWithCredit onClick={handleCreditModal}>
                    {t("buy_with_credit")}
                  </CarsWithOrderText>
                  <AboutCarBlock isArticulate>
                    <AboutCarKey>{t("car_articulate")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_articulate}
                    </AboutCarValue>
                  </AboutCarBlock>
                </CallToSeller>
              </CallSellerAndInfoAboutSeller>

              {carSelectedData?.car_video_link ? (
                <VideoLink alt='video link' controls autoPlay muted>
                  <source
                    src={`https://admin.ecomotors.am/storage/${carSelectedData?.car_video_link}`}
                    type="video/mp4"
                  />
                </VideoLink>
              ) : null}
            </CarPageLeftSide>

            <CarPageRightSide>
              <CarCharacteristics>

                <AboutCarBlock>
                  <AboutCarKey is_car_additional>{t('we_in_youtube')}</AboutCarKey>
                  <AboutCarValueName isYoutube isTextBig>
                    <a href='https://www.youtube.com/@ecomotors.am23' target='_blank' rel="noreferrer">
                      <FaYoutube />
                    </a>
                    </AboutCarValueName>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey is_car_additional>{`${t("car_model")}/${t("car_name")}`}</AboutCarKey>
                  <AboutCarValueName is_car_additional isTextBig>{carSelectedData?.product_name?.car_name}{" "}
                    {carSelectedData?.product_model?.model_name}</AboutCarValueName>
                </AboutCarBlock>

                <CarTitleAndAddFavorite>
                  <CarRightSideTitleH1>
                    {t("car_price")}
                  </CarRightSideTitleH1>
                  <CarRightSideTitle>
                    {carSelectedData?.car_price
                      ? `${NumberWithDots(+carSelectedData.car_price)} ${currency.usd_symbol} / 
                  ${NumberWithDots(
                        Math.ceil((+carSelectedData.car_price * usd))
                      ) + currency.amd_symbol
                      }  
                  ${" / " +
                      NumberWithDots(
                        Math.ceil(+carSelectedData.car_price * usd / rub)
                      ) +
                      currency.rub_symbol
                      } ` : t("with_order")}
                  </CarRightSideTitle>
                </CarTitleAndAddFavorite>

                {carSelectedData.product_country_price.length
                  ? carSelectedData.product_country_price.map((element, index) => {
                    return (
                      <React.Fragment key={index}>
                        {element.country_price_value ? <AboutCarBlock key={index}>
                          <AboutCarKey>
                            {t("order_from")} {element?.name_country}
                          </AboutCarKey>
                          <AboutCarValue>
                            {element.country_price_value
                              ? NumberWithDots(element.country_price_value) + " $"
                              : t("no_order_price")}
                          </AboutCarValue>
                        </AboutCarBlock> : null}
                      </React.Fragment>
                    );
                  })
                  : null}

                <AboutCarBlock>
                  <AboutCarKey>{t("car_type")}</AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.product_body?.car_body_name}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>{t("car_product_year")}</AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.product_year?.car_year}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>{t("car_motor")}</AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.product_motor?.car_motor &&
                      `${carSelectedData?.product_motor?.car_motor} KW`}
                  </AboutCarValue>
                </AboutCarBlock>

                {carSelectedData?.car_horsepower ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_horsepower")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_horsepower}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_cruising_range ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_cruising_range")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_cruising_range} կմ
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                <AboutCarBlock>
                  <AboutCarKey>{t("car_tugboat")}</AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.product_tugboat?.car_tugboat}
                  </AboutCarValue>
                </AboutCarBlock>

                {carSelectedData?.car_max_speed ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_max_speed")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_max_speed} կմ/ժ
                    </AboutCarValue>
                  </AboutCarBlock>) : null}

                {carSelectedData?.car_official ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_official")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_official}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_torque ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_torque")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_torque}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_battery_capacity ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_battery_capacity")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_battery_capacity} կվտ/ժ
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_battery_energy_density ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_battery_energy_density")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_battery_energy_density} (վտ/կգ)
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_wheel_size ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_wheel_size")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_wheel_size}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_wheel_size_back ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_wheel_size_back")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_wheel_size_back}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_fast_charging ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_fast_charging")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_fast_charging} ժ
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_slow_charging ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_slow_charging")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_slow_charging} {t("hour")}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_driving_mode ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_driving_mode")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_driving_mode}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_doors ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_doors")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_doors}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData.product_seat_count.seat_count ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_seat_count_id")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData.product_seat_count.seat_count}
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_length_width_height ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_length_width_height")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_length_width_height} (մմ)
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_wheelbase ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_wheelbase")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_wheelbase} (մմ)
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_limited_weight ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_limited_weight")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_limited_weight} (կգ)
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {carSelectedData?.car_full_load_array ? (
                  <AboutCarBlock>
                    <AboutCarKey>{t("car_full_load_array")}</AboutCarKey>
                    <AboutCarValue>
                      {carSelectedData?.car_full_load_array} (կգ)
                    </AboutCarValue>
                  </AboutCarBlock>
                ) : null}

                {isRestOpen ?
                  <>
                    {carSelectedData?.car_driver_support_video ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_driver_support_video")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_driver_support_video}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_parking_radar ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_parking_radar")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_parking_radar}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_battery_temperature_control_system ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_battery_temperature_control_system")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_battery_temperature_control_system}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_abs_anti_lock_system ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_abs_anti_lock_system")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_abs_anti_lock_system}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_brake_force_distribution_ebd_cbc ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_brake_force_distribution_ebd_cbc")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_brake_force_distribution_ebd_cbc}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_braking_assistant_eba_ba ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_braking_assistant_eba_ba")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_braking_assistant_eba_ba}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_traction_control_tcs_asr ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_traction_control_tcs_asr")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_traction_control_tcs_asr}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_body_stabilization_system_esp_dsc ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_body_stabilization_system_esp_dsc")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_body_stabilization_system_esp_dsc}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_active_safety_warning_system ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_active_safety_warning_system")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_active_safety_warning_system}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_active_braking ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_active_braking")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_active_braking}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_lane_keeping_assistant ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_lane_keeping_assistant")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_lane_keeping_assistant}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_consultants_design_development_engineering ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_consultants_design_development_engineering")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_consultants_design_development_engineering}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_child_seat_interface ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_child_seat_interface")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_child_seat_interface}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_navigation_system ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_navigation_system")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_navigation_system}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_hill_assistant ? (
                      <AboutCarBlock>
                        <AboutCarKey>HILL assistant (HAC)</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_hill_assistant}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_brake_power_recovery_system ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_brake_power_recovery_system")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_brake_power_recovery_system}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {carSelectedData?.car_choice_of_driving_modes ? (
                      <AboutCarBlock>
                        <AboutCarKey>{t("car_choice_of_driving_modes")}</AboutCarKey>
                        <AboutCarValue>
                          {carSelectedData?.car_choice_of_driving_modes}
                        </AboutCarValue>
                      </AboutCarBlock>
                    ) : null}

                    {/*<AboutCarBlock>*/}
                    {/*  <AboutCarKey>{t("car_tugboat")}</AboutCarKey>*/}
                    {/*  <AboutCarValue>*/}
                    {/*    {carSelectedData?.product_tugboat?.car_tugboat}*/}
                    {/*  </AboutCarValue>*/}
                    {/*</AboutCarBlock>*/}

                    {carSelectedData?.product_additional
                      ? carSelectedData.product_additional.map(
                        ({ car_additional, car_additional_value }, index) => (
                          <AboutCarBlock key={index}>
                            <AboutCarBlockKeyDiv>
                              <IoIosCheckmarkCircleOutline />
                              <AboutCarKey>{car_additional}</AboutCarKey>
                            </AboutCarBlockKeyDiv>
                            <AboutCarValue>{car_additional_value}</AboutCarValue>
                          </AboutCarBlock>
                        )
                      )
                      : null}
                  </> : null}
                <SeeMoreInfo onClick={() => setIsRestOpen(!isRestOpen)}>{isRestOpen ? t('close') : t('see_more')}</SeeMoreInfo>
              </CarCharacteristics>
            </CarPageRightSide>
          </CarPageContent>
        )}
      </CarPageWrapper>
    </>
  );
};

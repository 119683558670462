export const keywordMappings = {
  "/": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/about": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/cars": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/contact": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/blog": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/eco-motors/sign-in": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/eco-motors/sign-up": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Electric Vehicle in Armenia, Electric Vehicle Refueling, Electric Vehicle from China, Electric Vehicle from Dubai, Electric Vehicle Charger, Green Vehicle, Green Vehicles, Eco-friendly vehicles, Electric Motors, Volkswagen, Tesla, Zeeker, Honda, Cherry, audi, bmw, kia, Mercedes",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ,Էլեկտրոմոբիլ էյեմ, Էլեկտրական մեքենա Հայաստանում, Էլեկտրական մեքենա լցակայան, Էլեկտրական մեքենա Չինաստանից, Էլեկտրական մեքենա Դուբայից, Էլեկտրական մեքենա լիցքավորիչ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար առանց արտանետումների մեքենա, էլեկտրական շորժիչով մեքենա, electormobil, Electormobil.am, Elektrakan meqena, hosanqov meqena, hosanqov avto, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электромобиль в Армении, Заправка электромобилей, Электромобиль из Китая, Электромобиль из Дубая, Зарядное устройство для электромобиля, Зеленый транспорт, Экологически чистые автомобили, Электрические двигатели, Volkswagen, Tesla, Zeeker, Honda, Cherry , audi, bmw, kia, Mercedes"
  },
  "/car/0001-honda-ens1-full-top-2022": {
    en: "Honda e:NS1  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Honda e:NS1  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Honda e:NS1  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0003-volkswagen-id-4-pure-2022": {
    en: "Volkswagen ID 4 PURE +  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 4 PURE +  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 4 PURE +  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0004-volkswagen-id-4-lite-pro-2022": {
    en: "Volkswagen ID 4 Lite Pro  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 4 Lite Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 4 Lite Pro  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0005-volkswagen-id-4-pro-2023": {
    en: "Volkswagen ID 4 PRO  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 4 PRO  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 4 PRO  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0006-qiteng-m70ev-2021": {
    en: "Qiteng M70EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Qiteng M70EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Qiteng M70EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0007-dongfeng-ec31-2022": {
    en: "Dongfeng EC31  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Dongfeng EC31  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Dongfeng EC31  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0006-changan-eado460ev-2022": {
    en: "Changan EADO460EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Changan EADO460EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Changan EADO460EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0007-byd-tang-2022": {
    en: "BYD Tang  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели.",
    am: "BYD Tang  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "BYD Tang  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0008-volkswagen-id-6-prime-4wd-2022": {
    en: "Volkswagen ID 6 Prime 4WD  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 6 Prime 4WD  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 6 Prime 4WD  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0009-volvo-c40-recharge-2022": {
    en: "Volvo C40 RECHARGE  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volvo C40 RECHARGE  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volvo C40 RECHARGE  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0010-byd-han-ev-2022": {
    en: "BYD Han EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "BYD Han EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "BYD Han EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0011-toyota-bz4x-ultra-2022": {
    en: "Toyota BZ4X Ultra  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Toyota BZ4X Ultra  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Toyota BZ4X Ultra  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0012-bmw-ix-3-2023": {
    en: "BMW IX 3  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "BMW IX 3  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "BMW IX 3  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0013-audi-q5-e-tron-quattro-2022": {
    en: "Audi Q5 E-Tron Quattro  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Audi Q5 E-Tron Quattro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Audi Q5 E-Tron Quattro  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0013-zeekr-001-2023": {
    en: "ZEEKR 001  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "ZEEKR 001  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "ZEEKR 001  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0014-kia-k3-ev-2022": {
    en: "KIA K3 EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "KIA K3 EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "KIA K3 EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0015-hongqi-e-qm5-2022": {
    en: "Hongqi E-QM5  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Hongqi E-QM5  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Hongqi E-QM5  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0018-hongqi-e-hs9-6-seats-2022": {
    en: "Hongqi E-HS9 6 Seats  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Hongqi E-HS9 6 Seats  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Hongqi E-HS9 6 Seats  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0015-hongqi-e-hs9-7-seats-2022": {
    en: "Hongqi E-HS9 7 Seats  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Hongqi E-HS9 7 Seats  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Hongqi E-HS9 7 Seats  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0020-mercedes-benz-eqs-580-4matic-2023": {
    en: "Mercedes-Benz EQS 580 4matic  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Mercedes-Benz EQS 580 4matic  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Mercedes-Benz EQS 580 4matic  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0019-mazda-cx30-ev-2023": {
    en: "Mazda CX30 EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Mazda CX30 EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Mazda CX30 EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0021-nissan-ariya-2022": {
    en: "Nissan Ariya  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Nissan Ariya  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Nissan Ariya  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0022-mitsubishi-airtrek-ev-2022": {
    en: "Mitsubishi Airtrek EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Mitsubishi Airtrek EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Mitsubishi Airtrek EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0023-voyah-dreamer-ev-2022": {
    en: "Voyah DREAMER EV  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Voyah DREAMER EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Voyah DREAMER EV  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0024-baojun-kiwi-2022": {
    en: "Baojun Kiwi  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Baojun Kiwi  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Baojun Kiwi  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0025-volkswagen-id-3-pure-2022": {
    en: "Volkswagen ID 3 Pure  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 3 Pure  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 3 Pure  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0026-volkswagen-id-3-pro-2022": {
    en: "Volkswagen ID 3 Pro  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Volkswagen ID 3 Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 3 Pro  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0027-dayun-yuehu-2022": {
    en: "Dayun Yuehu  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Dayun Yuehu  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Dayun Yuehu  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0028-weltmeister-ex5-z-2022": {
    en: "Weltmeister EX5-Z  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Weltmeister EX5-Z  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Weltmeister EX5-Z  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0029-changan-benben-star-2022": {
    en: "Changan BENBEN Star  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors",
    am: "Changan BENBEN Star  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Changan BENBEN Star  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0032-tesla-model-3-performance-2022": {
    en: "Tesla Model 3 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Tesla Model 3 Performance Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Tesla Model 3 Performance Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0033-tesla-model-3-2022": {
    en: "Tesla Model 3 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Tesla Model 3 Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Tesla Model 3 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0034-audi-q5-e-tron-quattro-2022": {
    en: "Audi Q5 E-Tron Quattro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Audi Q5 E-Tron Quattro Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Audi Q5 E-Tron Quattro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0035-dongfeng-ex1-2022": {
    en: "Dongfeng EX1 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Dongfeng EX1 Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Dongfeng EX1 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "cars/0036-volkswagen-id-6-pro-2022": {
    en: "Volkswagen ID 6 Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Volkswagen ID 6 Pro Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 6 Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0037-honda-m-nv-2022": {
    en: "Honda M-NV Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Honda M-NV Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Honda M-NV Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0038-volkswagen-id-4-lite-pro-2022": {
    en: "Volkswagen ID 4 Lite Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Volkswagen ID 4 Lite Pro Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 4 Lite Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0039-byd-dolphin-2022": {
    en: "BYD Dolphin Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "BYD Dolphin Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "BYD Dolphin Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0040-genesis-gv60ev-2022": {
    en: "Genesis GV60EV Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Genesis GV60EV Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Genesis GV60EV Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0041-volkswagen-id-4-prime-4wd-2022": {
    en: "Volkswagen ID 4 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Volkswagen ID 4 PRIME 4WD Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Volkswagen ID 4 PRIME 4WD Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0042-honda-ens1-full-top-2022": {
    en: "Honda e:NS1 Full TOP Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors.",
    am: "Honda e:NS1 Full TOP Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար",
    ru: "Honda e:NS1 Full TOP Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0043-volkswagen-id-4-lite-pro-2022": {
    en: "Volkswagen ID 4 Lite Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 4 Lite Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 4 Lite Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0044-toyota-bz4x-long-range-2023": {
    en: "Volkswagen ID 4 PRIME 4WD Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Toyota BZ4X Long Range  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Toyota BZ4X Long Range Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0045-mercedes-benz-eqc-400-4matic-2022": {
    en: "Mercedes-Benz EQC 400 4Matic Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Mercedes-Benz EQC 400 4Matic  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Mercedes-Benz EQC 400 4Matic Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0046-lotus-eletre-2022": {
    en: "Lotus Eletre Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Lotus Eletre  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Lotus Eletre Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0047-nissan-leaf-2022": {
    en: "Nissan Leaf Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Nissan Leaf  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Nissan Leaf Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0048-chery-qq-ice-cream-2022": {
    en: "Chery QQ Ice cream Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Chery QQ Ice cream  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Chery QQ Ice cream Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0049-chery-small-2022": {
    en: "Chery SMALL Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Chery SMALL  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Chery SMALL Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0051-volkswagen-id-6-pro-2022": {
    en: "Volkswagen ID 6 Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 6 Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 6 Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0052-byd-song-plus-ev-honor-flagship-version-2021": {
    en: "BYD Song Plus EV Honor / Flagship VersionElectric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "BYD Song Plus EV Honor / Flagship Version Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "BYD Song Plus EV Honor / Flagship VersionЭлектромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0053-xpeng-g3i-520g-edtion-2022": {
    en: "XPENG G3i 520G+ Edtion Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "XPENG G3i 520G+ Edtion  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "XPENG G3i 520G+ Edtion Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0054-aion-lx-plus-80d-thousand-miles-edition-2022": {
    en: "Aion LX Plus 80D Thousand Miles Edition Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion LX Plus 80D Thousand Miles Edition  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Aion LX Plus 80D Thousand Miles Edition Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0055-aion-lx-plus-80d-max-edition-2022": {
    en: "Aion LX Plus 80D Max Edition Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion LX Plus 80D Max Edition  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Aion LX Plus 80D Max Edition Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0056-audi-q4-e-tron-40-2022": {
    en: "Audi Q4 E-Tron 40 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Audi Q4 E-Tron 40  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Audi Q4 E-Tron 40 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0057-aion-y-plus-80-smart-driving-edition-2023": {
    en: "Aion Y Plus 80 Smart Driving Edition Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion Y Plus 80 Smart Driving Edition  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Aion Y Plus 80 Smart Driving Edition Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0058-aion-lx-plus-80d-flagship-edition-2022": {
    en: "Aion LX Plus 80D Flagship Edition Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion LX Plus 80D Flagship Edition  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Aion LX Plus 80D Flagship Edition Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0059-aion-s-plus-70-smart-driving-version-2022": {
    en: "Aion S Plus 70 Smart Driving Version Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion S Plus 70 Smart Driving Version  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Aion S Plus 70 Smart Driving Version Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "https://www.ecomotors.am/car/0060-aion-s-plus-70-smart-pilot-version-2022": {
    en: "Aion S Plus 70 Smart Pilot Version Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Aion S Plus 70 Smart Pilot Version  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Aion S Plus 70 Smart Pilot Version Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/0061-arcfox-as-hi-premium-version-2022": {
    en: "ARCFOX αS HI Premium Version Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "ARCFOX αS HI Premium Version  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "ARCFOX αS HI Premium Version Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0062-arcfox-at-653s-2022": {
    en: "ARCFOX αT 653S+  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "ARCFOX αT 653S+   Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "ARCFOX αT 653S+  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "https://www.ecomotors.am/car/0063-nio-es8-pilot-2022": {
    en: "NIO ES8 Pilot Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "NIO ES8 Pilot  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "NIO ES8 Pilot Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0063-nio-es8-pilot-2022": {
    en: "NIO ES8 Pilot Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "NIO ES8 Pilot  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "NIO ES8 Pilot Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0064-bmw-i7-xdrive-60-2023": {
    en: "BMW I7 xDrive 60 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "BMW I7 xDrive 60  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "BMW I7 xDrive 60 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0065-leapmotor-t03-haoyu-2022": {
    en: "LEAPMOTOR T03 Haoyu Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "LEAPMOTOR T03 Haoyu  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "LEAPMOTOR T03 Haoyu Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0066-volkswagen-id-4-pro-2022": {
    en: "Volkswagen ID 4 PRO Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 4 PRO  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 4 PRO Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0067-volkswagen-id-6-pro-2022": {
    en: "Volkswagen ID 6 Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 6 Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 6 Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0068-honda-ens1-2022": {
    en: "Honda e:NS1   Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Honda e:NS1    էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել",
    ru: "Honda e:NS1   Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0069-audi-q4-e-tron-40-2022": {
    en: "Audi Q4 E-Tron 40 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Audi Q4 E-Tron 40  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Audi Q4 E-Tron 40 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0070-volkswagen-id-6-lite-pro-2022": {
    en: "Volkswagen ID 6 Lite Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 6 Lite Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 6 Lite Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0071-volkswagen-id-6-pure-2022": {
    en: "Volkswagen ID 6 Pure + Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 6 Pure +  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 6 Pure + Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0072-volkswagen-id-4-pure-2023": {
    en: "Volkswagen ID 4 PURE + Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Volkswagen ID 4 PURE +  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Volkswagen ID 4 PURE + Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0073-zeekr-x-2023": {
    en: "ZEEKR X Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "ZEEKR X  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "ZEEKR X Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0074-zeekr-me-2023": {
    en: "ZEEKR X ME  Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "ZEEKR X ME   Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "ZEEKR X ME  Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0075-honda-enp1-2023": {
    en: "Honda e:NP1 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Honda e:NP1  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Honda e:NP1 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0076-hiphi-x-2022": {
    en: "HiPhi X Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "HiPhi X  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "HiPhi X Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0077-honda-enp1-2023": {
    en: "Honda e:NP1 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Honda e:NP1  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Honda e:NP1 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0078-toyota-bz3-premium-2023": {
    en: "Toyota BZ3 Premium Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Toyota BZ3 Premium  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Toyota BZ3 Premium Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0079-toyota-bz3-pro-2023": {
    en: "Toyota BZ3 Pro Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor",
    am: "Toyota BZ3 Pro  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա",
    ru: "Toyota BZ3 Pro Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели."
  },
  "/car/0099-zeekr-009-2022": {
    en: "ZEEKR 009 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor ZEEKR 009 electric car from ECOMOTORS at the best price or order any other model.",
    am: "ZEEKR 009 Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա ZEEKR 009 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "ZEEKR 009 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль ZEEKR 009 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0097-zeekr-009-2022": {
    en: "ZEEKR 009 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor ZEEKR 009 electric car from ECOMOTORS at the best price or order any other model.",
    am: "ZEEKR 009  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա ZEEKR 009 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "ZEEKR 009 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль ZEEKR 009 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0096-radar-ed6-2022": {
    en: "Radar ED6 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Radar ED6 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Radar ED6  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Radar ED6 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Radar ED6 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Radar ED6 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0095-maxus-t90-ev-2022": {
    en: "Maxus T90 EV Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Maxus T90 EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Maxus T90 EV  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Maxus T90 EV էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Maxus T90 EV Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Maxus T90 EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0094-byd-yuan-plus-2022": {
    en: "BYD Yuan PLUS Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor BYD Yuan PLUS electric car from ECOMOTORS at the best price or order any other model.",
    am: "BYD Yuan PLUS Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա BYD Yuan PLUS էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "BYD Yuan PLUS Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль BYD Yuan PLUS от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0093-mercedes-benz-eqe-350-delux-2022": {
    en: "Mercedes-Benz EQE 350 Delux Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Mercedes-Benz EQE 350 Delux electric car from ECOMOTORS at the best price or order any other model.",
    am: "Mercedes-Benz EQE 350 Delux  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Mercedes-Benz EQE 350 Delux էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Mercedes-Benz EQE 350 Delux Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Mercedes-Benz EQE 350 Delux от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0092-mercedes-benz-eqe-350-pioner-2022": {
    en: "Mercedes-Benz EQE 350 Pioner Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Mercedes-Benz EQE 350 Pioner electric car from ECOMOTORS at the best price or order any other model.",
    am: "Mercedes-Benz EQE 350 Pioner  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Mercedes-Benz EQE 350 Pioner էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Mercedes-Benz EQE 350 Pioner Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Mercedes-Benz EQE 350 Pioner от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0091-byd-seagull-2023": {
    en: "BYD seagull Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor BYD seagull electric car from ECOMOTORS at the best price or order any other model.",
    am: "BYD seagull  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա BYD seagull էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "BYD seagull Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль BYD seagull от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0090-byd-seal-2023": {
    en: "BYD seal Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor BYD seal electric car from ECOMOTORS at the best price or order any other model.",
    am: "BYD seal Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա BYD seal էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "BYD seal Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль BYD seal от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0089-citroen-berlingo-van-2022": {
    en: "Citroen Berlingo Van Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Citroen Berlingo Van electric car from ECOMOTORS at the best price or order any other model.",
    am: "Citroen Berlingo Van Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Citroen Berlingo Van էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Citroen Berlingo Van Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Citroen Berlingo Van от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0088-citroen-my-ami-cargo-2022": {
    en: "Citroen My Ami Cargo Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Citroen My Ami Cargo electric car from ECOMOTORS at the best price or order any other model.",
    am: "Citroen My Ami Cargo Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Citroen My Ami Cargo էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Citroen My Ami Cargo Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Citroen My Ami Cargo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0087-mercedes-benz-eqb-350-2022": {
    en: "Mercedes-Benz EQB 350 Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Mercedes-Benz EQB 350 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Mercedes-Benz EQB 350 Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Mercedes-Benz EQB 350 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Mercedes-Benz EQB 350 Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Mercedes-Benz EQB 350 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0086-foton-grand-general-ev-pickup-2022": {
    en: "Foton Grand General EV Pickup Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Foton Grand General EV Pickup electric car from ECOMOTORS at the best price or order any other model.",
    am: "Foton Grand General EV Pickup Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Foton Grand General EV Pickup էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Foton Grand General EV Pickup Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Foton Grand General EV Pickup от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0085-lexus-direct4-vertical-collar-2023": {
    en: "Lexus Direct4 Vertical Collar Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Lexus Direct4 Vertical Collar electric car from ECOMOTORS at the best price or order any other model.",
    am: "Lexus Direct4 Vertical Collar  Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Lexus Direct4 Vertical Collar էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Lexus Direct4 Vertical Collar Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Lexus Direct4 Vertical Collar от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0084-lexus-direct4-indulgence-2023": {
    en: "Lexus Direct4 Indulgence Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Lexus Direct4 Indulgence electric car from ECOMOTORS at the best price or order any other model.",
    am: "Lexus Direct4 Indulgence Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Lexus Direct4 Indulgence էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Lexus Direct4 Indulgence Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Lexus Direct4 Indulgence от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0083-lexus-direct4-vertical-edtition-2023": {
    en: "Lexus Direct4 Vertical Edtition Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Lexus Direct4 Vertical Edtition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Lexus Direct4 Vertical Edtition Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Lexus Direct4 Vertical Edtition էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Lexus Direct4 Vertical Edtition Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Lexus Direct4 Vertical Edtition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0082-porsche-taycan-4s-cross-turismo-2022": {
    en: "Porsche Taycan 4S cross Turismo Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Porsche Taycan 4S cross Turismo Pickup electric car from ECOMOTORS at the best price or order any other model.",
    am: "Porsche Taycan 4S cross Turismo Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Porsche Taycan 4S cross Turismo Pickup էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Porsche Taycan 4S cross Turismo Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Porsche Taycan 4S cross Turismo Pickup от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0081-porsche-taycan-4s-cross-turismo-2022": {
    en: "Porsche Taycan 4S cross Turismo Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motor Porsche Taycan 4S cross Turismo electric car from ECOMOTORS at the best price or order any other model.",
    am: "Porsche Taycan 4S cross Turismo Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատա Porsche Taycan 4S cross Turismo էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Porsche Taycan 4S cross Turismo Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели. электромобиль Porsche Taycan 4S cross Turismo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00100-mercedes-benz-eqa-260-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00101-arcfox-a-s-708-km-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00102-toyota-c-hr-ev-izoa-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00103-toyota-bz4x-long-range-joy-version-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00105-byd-yuan-pro-premium-model-401-km-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00106-porsche-taycan-4-cross-turismo-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00108-zeekr-001-you-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00109-ford-electric-horse-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00110-zeekr-x-you-4seat-500km-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00111-toyota-bz4x-four-wheel-drive-high-performance-pro-version-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00112-audi-e-tron-50-quattro-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00113-byd-song-plus-flagship-model-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00114-byd-song-plus-champion-flagship-plus-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00115-xpeng-g3i-520-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00116-denza-n7-basic-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00117-denza-n7-top-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00118-buick-e4-gs-version-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00119-buick-e4-smart-edition-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00120-byd-genesis-edition-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00121-mitsubishi-airtrek-basic-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00122-hiphi-y-flagship-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00123-hiphi-y-elite-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00124-hiphi-x-630-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00125-honda-enp1-full-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00126-chery-eq7-max-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00127-lotus-eletre-s-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00128-dongfeng-mengshi-917-2023-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00129-audi-30-e-tron-q2l-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00130-zeekr-x-you-5-seat-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00131-mercedes-benz-eqe-suv-500-pioner-basic-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00132-hongqi-ehs9-4-seats-2022": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00133-zhiji-ls7-pro-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00134-kia-ev6-gt-opt-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00135-nissan-ariya-performance-plus-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00136-avatr-11-model-011-mmw-4-seats-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00137-audi-e-tron-sportback-50-quattro-2020": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00138-zeekr-001-z-sport-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00140-dongfeng-forthing-friday-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00141-honda-enp-1-e-dynamic-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
  "/car/00142-honda-enp-1-e-type-2023": {
    en: "Electric Vehicle, EVs, Electric Transport, Battery Cars, Electric Cars, Electric Trucks, Eco-friendly vehicles, Electric Motors, Elmotors, imotors",
    am: "Էլեկտրական մեքենա, Էլեկտրո մեքենա, հոսանքով ավտո, էլեկտրոմոբիլ, հոսանքով մեքենա, էլեկտրական ավտո, Էլեկտրո մոբիլ, էլեկտրական Էլեկտրական բեռնատար, էլմոթորս, այմոթորս",
    ru: "Электромобиль, Электротранспорт, Батарейные автомобили, Электрокары, Электрические грузовики, Электрические двигатели, Элмоторс, Аймоторс"
  },
};

import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { Salon } from "store/salonLocalizationsIds/actions";

const initialState = {
  salonStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const SalonData = createSlice({
  name: "salon/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "salonStatus":
          state.salonStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [Salon.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.salonStatus = REDUX_STATES.PENDING;
    },
    [Salon.fulfilled]: (state, action) => {
      state.data = action.payload.salonLocalization;
      state.is_loading = false;
      state.salonStatus = REDUX_STATES.SUCCEEDED;
    },
    [Salon.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.salonStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = SalonData.actions;

export default SalonData.reducer;

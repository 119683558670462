import styled from 'styled-components';

export const PrivacyPolicyWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-inline: 120px;
  box-sizing: border-box;
  font-size: 20px;
  white-space: pre-wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  
  @media only screen and (max-width: 768px) {
    padding-inline: 60px;
    font-size: 16px;
  }

  @media only screen and (max-width: 428px) {
    padding-inline: 30px;
  }
`;

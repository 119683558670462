import styled, { css, keyframes } from "styled-components";
import { Images } from "assets";
import { COLORS } from "utils/colors.js";
import { DefaultFont } from "utils/defaultFont.js";
import { SwiperSlide } from "swiper/react";
import Select from "react-select";
import { REDUX_STATES } from "utils/constants";

export const AvailableCarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 40%;
  align-self: center;
  height: 50px;
  background: ${COLORS.main_color};
  color: ${COLORS.white};
  border: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s linear;
  
  &:hover {
    border: 2px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
    background: transparent;
  }
`;

export const CarListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  background: url(${Images.carCharger});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .lazy-load-image-background {
    width: 100%;
    height: 200px;
  
    
    @media only screen and (max-width: 420px) {
      height: 100px;
    }
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
`;

export const CarsTitle = styled.h2`
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  word-spacing: 10px;
  color: ${COLORS.white};
  ${DefaultFont};
  font-size: 2.2vw;
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    font-size: 3.5vw;
  }

  @media only screen and (max-width: 428px) {
    font-size: 4vw;
  }
`;

export const CarsAmount = styled.span`
  font-size: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 2.5vw;
  }
`;

export const CarsContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: auto;
  background: ${COLORS.white};
  padding: 20px 100px;
  overflow-x: hidden;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    padding: 20px 60px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }

  @media only screen and (max-width: 428px) {
    padding: 2px;
  }
`;

export const LeftCategoriesSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  flex-shrink: 0;
  height: auto;

  @media only screen and (max-width: 1280px) {
    width: 100%;
    gap: 30px;
  }
  @media only screen and (max-width: 768px) {
    gap: 10px;
  }
`;

export const AuthorizedRepresentative = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
`;

export const TwoCompaniesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const TwoCompaniesImage = styled.img`
  width: 100px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
`;

export const TwoCompaniesImageWidth = styled.img`
  width: 130px;
  height: 70px;
  object-fit: contain;
  cursor: pointer;
`;

export const AuthorizedRepresentativeText = styled.span`
  font-size: 20px;
  color: ${COLORS.main_color};
  ${DefaultFont};
`;


export const CategoriesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${COLORS.main_color};
    transition: 0.2s;
    transition-timing-function: linear;
    transition-duration: 0.3s;

    &:hover {
      box-shadow: 10px 10px 5px 0px rgb(114, 194, 30, 0.5);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(114, 194, 30, 0.3);
      -moz-box-shadow: 10px 10px 5px 0px rgba(114, 194, 30, 0.2);
    }
  }
`;


export const LeftCarsWithCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${COLORS.white};
  width: auto;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
`;

export const SelectField = styled(Select)`
  width: 45%;
  height: 40px;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${COLORS.main_color} !important;
  font-size: 12px;

  span {
    display: none;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
    color: ${COLORS.main_color};
  }

  .css-1dimb5e-singleValue {
    color: ${COLORS.main_color};
  }

  div {
    border-style: unset !important;
    padding: unset !important;

    &:hover {
      border: unset !important;
      box-shadow: unset !important;
    }


  }

  .css-12t3mxd-control {
    @media only screen and (max-width: 768px) {
      min-height: 28px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 30px;
  }
`;

export const SelectOption = styled.select`
  width: 45%;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid ${COLORS.main_color};
  border-radius: 4px;
  cursor: pointer;

  option {
    color: ${COLORS.black};
  }
`;

export const SendButton = styled.button`
  width: 100%;
  height: 40px;
  border: unset;
  border-radius: 4px;
  color: ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  font-size: 18px;
  text-transform: capitalize;

  &:hover {
    background: ${COLORS.main_color};
    color: ${COLORS.white};
    cursor: pointer;
  }
`;

export const RightCarSection = styled.div`
  width: 100%;
  background: white;
`;

export const CarsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: auto;
`;

export const slideFromRight = keyframes`
  from {
    transform: translateX(200%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;

export const CarRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 6px;
  width: 100%;
  height: auto;

  animation: ${({ SelectCarDataStatus }) =>
          SelectCarDataStatus === REDUX_STATES.SUCCEEDED
                  ? css`
                    ${slideFromRight} 0.2s ease-in-out forwards
                  `
                  : null};

  @media only screen and (max-width: 768px) {
    align-items: center;
    gap: 10px;
    justify-content: center;
  }

  @media only screen and (max-width: 450px) {
    align-items: center;
    gap: unset;
    column-gap: 4px;
    row-gap: 10px;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }
`;

export const CarBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  width: 300px;
  height: 350px;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 3px solid #f0f0ff;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  
  @media only screen and (max-width: 768px) {
    width: 240px;
  }

  @media only screen and (max-width: 530px) {
    width: 200px;
  }

  @media only screen and (max-width: 450px) {
    width: 200px;
  }

  @media only screen and (max-width: 450px) {
    width: 160px;
  }

  @media only screen and (max-width: 370px) {
    height: 320px;
    width: 150px;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CarStatement = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;
  padding: 4px 14px;
  border-bottom-left-radius: 20px;
  color: ${COLORS.white};
  font-size: 1.2vw;
  background: ${({ statement }) =>
          statement === 1
                  ? COLORS.red
                  : statement === 2
                          ? COLORS.main_color
                          : statement === 3
                                  ? COLORS.blue
                                  : null};
  text-transform: capitalize;

  @media only screen and (max-width: 548px) {
    font-size: 10px !important;
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1268px) {
    font-size: 1vw;
  }
  @media only screen and (min-width: 1920px) {
    font-size: 0.8vw;
  }
`;

export const CarImage = styled.div`
  width: 100%;
  height: 200px;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-position: center; 
  background-size: cover;
  flex-shrink: 0;
  
  img {
    object-fit: contain !important;
  }
`;

export const CarMainInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

export const CarModelName = styled.h2`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${DefaultFont};
  font-size: ${({ noData }) => (noData ? "24px" : "20px")};
  color: ${({ noData }) => (noData ? COLORS.main_color : COLORS.black)};
  text-align: center;
  margin: 0;
  font-weight: 500; 
  cursor: ${({cursorPointer}) => cursorPointer && 'pointer'};
  
  @media only screen and (max-width: 480px) {
    font-size: 14px !important;
  }
`;

export const CarPrice = styled.span`
  ${DefaultFont};
  font-size: 16px;
  color: ${COLORS.black};
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
  @media only screen and (max-width: 1536px) {
    font-size: 15px !important;
  }

  @media only screen and (max-width: 480px) {
    font-size: 10px !important;
  }
`;

export const CarDateEndineDrive = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
`;

export const CarModelDate = styled.span`
  ${DefaultFont};
  font-size: 12px;
  color: ${COLORS.black};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    color: ${COLORS.main_color};
  }
`;

export const BigCarImage = styled.img`
  width: 100% !important;
  height: 506px !important;
  object-fit: contain !important;
  cursor: text;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    height: 300px !important;
  }
`;

export const CarsSlider = styled(SwiperSlide)`
  width: 100%;
  margin-top: ${({ isbigimage }) => (isbigimage ? 0 : "20px")};
  height: max-content;

  @media only screen and (max-width: 768px) {
    height: ${({ isbigimage }) => (isbigimage ? "300px" : "80px")};
  }

  img {
    cursor: pointer;
    object-fit: contain !important;
  }
`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { carCompareService } from "./service";

export const carCompare = createAsyncThunk(
  "carCompare/fetch",
  async(data, { rejectWithValue }) => {
    try {
      const response = await carCompareService.cars(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

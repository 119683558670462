import { Images } from "assets"

export const REDUX_STATES = {
  PENDING: "loading",
  SUCCEEDED: "successful",
  FAILED: "failed"
};

export const CREDENTIALS = {
  ACCESS_TOKEN: "access_token",
  language: "lang"
};

export const MODAL_CONSTANTS = {
  message_sent: "Your Message Was Sent."
};

export const IMAGE_STARTING_URL = "https://admin.ecomotors.am/storage";

export const ECO_MOTORS_MAIL = "ecomotors.am23@gmail.com";

export const SELECTED_TYPES = {
  CONTAINER: "CONTAINER",
  LIST: "LIST",
  COLUMN: "COLUMN"
};

export const CAR_NAMES = [
  {
    name:"XPENG",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:31,%22is_filter%22:1}"
  },
  {
    name: "AUDI",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:14,%22is_filter%22:1}"
  },
  {
    name: "AVITA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:52,%22is_filter%22:1}"
  },
  {
    name: "NISSAN",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:20,%22is_filter%22:1}"
  },
  {
    name: "KIA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:5,%22is_filter%22:1}"
  },
  {
    name: "ZHIJI",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:51,%22is_filter%22:1}"
  },
  {
    name: "MERCEDES BENZ",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:3,%22is_filter%22:1}"
  },
  {
    name: "ZEEKR",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:15,%22is_filter%22:1}"
  },
  {
    name: "VOLKSWAGEN",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:6,%22is_filter%22:1}"
  },
  {
    name: "DONGFENG",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:8,%22is_filter%22:1}"
  },
]

export const CAR_NAMES_SECOND = [
  {
    name: "HONDA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:4,%22is_filter%22:1}"
  },
  {
    name: "LOTUS",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:28,%22is_filter%22:1}"
  },
  {
    name: "HIPHI",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:37,%22is_filter%22:1}"
  },
  {
    name:  "BYD",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:11,%22is_filter%22:1}"
  },
  {
    name: "BUICK",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:50,%22is_filter%22:1}"
  },
  {
    name: "DENZA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:49,%22is_filter%22:1}"
  },
  {
    name: "TOYOTA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:13,%22is_filter%22:1}"
  },
  {
    name: "FORD",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:48,%22is_filter%22:1}"
  },
  {
    name: "RADAR",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:43,%22is_filter%22:1}"
  },
  {
    name: "LEXUS",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:39,%22is_filter%22:1}"
  },
]

export const CAR_NAMES_THIRD = [
  {
    name: "BMW",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:12,%22is_filter%22:1}"
  },
  {
    name: "LEAPMOTOR",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:35,%22is_filter%22:1}"
  },
  {
    name: "ARCFOX",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:33,%22is_filter%22:1}"
  },
  {
    name: "AION",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:32,%22is_filter%22:1}"
  },
  {
    name: "CHERY",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:30,%22is_filter%22:1}"
  },
  {
    name: "GENESIS",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:27,%22is_filter%22:1}"
  },
  {
    name: "TESLA",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:26,%22is_filter%22:1}"
  },
  {
    name: "CHANGAN",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:9,%22is_filter%22:1}"
  },
  {
    name: "WELTMEISTER",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:25,%22is_filter%22:1}"
  },
  {
    name: "PORSCHE",
    link: "/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:38,%22is_filter%22:1}"
  },
]

export const MARQUEE_SPEED = 70;

export const CAR_SLIDER_NAMES = [
  {
    name: "Honda ENS 1",
    image: Images.hondaENS1Slider,
    link: "https://www.ecomotors.am/car/00274-honda-ens1-full-2023"
  },
  {
    name: "Honda ENP 1",
    image: Images.hondaENP1Slider,
    link: "https://www.ecomotors.am/car/00223-honda-enp1-full-2023"
  },
  {
    name: "Volkswagen ID 4",
    image: Images.id4Slider,
    link: "https://www.ecomotors.am/car/00310-volkswagen-id-4-prime-2023"
  },
  {
    name: "Volkswagen ID 6",
    image: Images.id6slider,
    link: "https://www.ecomotors.am/car/0036-volkswagen-id-6-pro-7-seats-2023"
  },
  {
    name: "Mercedes EQC",
    image: Images.mercedesEQC,
    link: "https://www.ecomotors.am/car/0045-mercedes-benz-eqc-400-4matic-2022"
  },
  {
    name: "Toyota BZ4",
    image: Images.toyotaBZ4Slider,
    link: "https://www.ecomotors.am/car/00281-toyota-bz4x-long-range-joy-2023"
  },
  {
    name: "Volkswagen ID 4",
    image: Images.id4Slider,
    link: "https://www.ecomotors.am/car/00310-volkswagen-id-4-prime-2023"
  },
];

export const TITLE_MAPPINGS = {
  "/": {
    en: "Էլեկտրոմոբիլներ | Ecomotors.am",
    am: "Էլեկտրոմոբիլներ | Ecomotors.am",
    ru: "Էլեկտրոմոբիլներ | Ecomotors.am"
  },
  "/blog": {
    en: "Blogs | Ecomotors.am",
    am: "Բլոգ | Ecomotors.am",
    ru: "Блог | Ecomotors.am"
  },
  "/services": {
    en: "Services | Ecomotors.am",
    am: "Ծառայություններ | Ecomotors.am",
    ru: "Услуги | Ecomotors.am"
  },
  "/credit-calculator": {
    en: "Credit calculator | Ecomotors.am",
    am: "Վարկի Հաշվիչ | Ecomotors.am",
    ru: "Кредитный Калькулятор | Ecomotors.am"
  },
  "/cars": {
    en: "Cars | Ecomotors.am",
    am: "Մեքենաներ | Ecomotors.am",
    ru: "Машины | Ecomotors.am"
  },
  "/available-cars": {
    en: "Available cars | Ecomotors.am",
    am: "Առկա մեքենաներ | Ecomotors.am",
    ru: "Доступный Автомобили | Ecomotors.am"
  },
  "/eco-motors/sign-in": {
    en: "Sign In | Ecomotors.am",
    am: "Մուտք | Ecomotors.am",
    ru: "Войти | Ecomotors.am"
  },
  "/eco-motors/sign-up": {
    en: "Sign Up | Ecomotors.am",
    am: "Գրանցում | Ecomotors.am",
    ru: "Регистрация | Ecomotors.am"
  },
  "/about": {
    en: "About Us | Ecomotors.am",
    am: "Մեր Մասին | Ecomotors.am",
    ru: "О Нас | Ecomotors.am"
  },
  "/contact": {
    en: "Contact Us | Ecomotors.am",
    am: "Կապ Մեզ Հետ | Ecomotors.am",
    ru: "Связаться с Нами | Ecomotors.am"
  },
  "/reviews": {
    en: "Reviews | Ecomotors.am",
    am: "Կարծիքներ | Ecomotors.am",
    ru: "Отзывы | Ecomotors.am"
  },
  "/car/0001-honda-ens1-full-top-2022": {
    en: "Honda e:NS1 l Ecomotors.am",
    am: "Honda e:NS1 l Ecomotors.am",
    ru: "Honda e:NS1 l Ecomotors.am"
  },
  "/car/0003-volkswagen-id-4-pure-2022": {
    en: "Volkswagen ID 4 PURE + | Ecomotors.am",
    am: "Volkswagen ID 4 PURE + | Ecomotors.am",
    ru: "Volkswagen ID 4 PURE + | Ecomotors.am"
  },
  "/car/0004-volkswagen-id-4-lite-pro-2022": {
    en: "Volkswagen ID 4 Lite Pro l Ecomotors.am",
    am: "Volkswagen ID 4 Lite Pro l Ecomotors.am",
    ru: "Volkswagen ID 4 Lite Pro l Ecomotors.am"
  },
  "/car/0005-volkswagen-id-4-pro-2023": {
    en: "Volkswagen ID 4 PRO l Ecomotors.am",
    am: "Volkswagen ID 4 PRO l Ecomotors.am",
    ru: "Volkswagen ID 4 PRO l Ecomotors.am"
  },
  "/car/0006-qiteng-m70ev-2021": {
    en: "Qiteng M70EV l Ecomotors.am",
    am: "Qiteng M70EV l Ecomotors.am",
    ru: "Qiteng M70EV l Ecomotors.am"
  },
  "/car/0007-dongfeng-ec31-2022": {
    en: "/car/0007-dongfeng-ec31-2022",
    am: "/car/0007-dongfeng-ec31-2022",
    ru: "/car/0007-dongfeng-ec31-2022"
  },
  "/car/0006-changan-eado460ev-2022": {
    en: "Changan EADO460EV l Ecomotors.am",
    am: "Changan EADO460EV l Ecomotors.am",
    ru: "Changan EADO460EV l Ecomotors.am"
  },
  "/car/0007-byd-tang-2022": {
    en: "BYD Tang l Ecomotors.am",
    am: "BYD Tang l Ecomotors.am",
    ru: "BYD Tang l Ecomotors.am"
  },
};


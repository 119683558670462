import { useCloseOnClickOutside } from "hooks/useCloseOnClickOutside";
import { IMAGE_STARTING_URL } from "utils/constants";
import { SpareModal } from "spareParts/components/spareModal";
import { useState } from "react";
import {
  ItemId,
  ItemImage,
  ItemPrice,
  ItemProviderName,
  MainItemAvailableBlock,
  OrderNowButton
} from "spareParts/containers/sparePartSinglePage/styled";

export const SparePartModal = ({ sparePart, id, model }) => {
  const { ref, isOpen, setIsOpen } = useCloseOnClickOutside();

  const requestSparePart = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <MainItemAvailableBlock key={sparePart.id}>
      {isOpen ? (
        <SpareModal forwardRef={ref} id={id} model={model} requestSparePart={requestSparePart} />
      ) : null}
      <ItemImage
        src={`${IMAGE_STARTING_URL}/${sparePart?.product_images[0].image}`}
        alt='spare-part-image'
      />
      <ItemProviderName>{sparePart.name_spare_part}</ItemProviderName>
      <ItemId>{sparePart.articulate}</ItemId>
      <ItemPrice>Արժեք - {sparePart.price}</ItemPrice>
      <OrderNowButton onClick={requestSparePart}>Պատվիրել</OrderNowButton>
    </MainItemAvailableBlock>
  );
};

import {
  IntroWrapper,
  MarqueeEveryBlock,
  InfoChild,
  BlockTitle,
  BlockTitleWrapper,
  BlockImage
} from "components/welcome/styled";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { CAR_NAMES, CAR_NAMES_SECOND, CAR_NAMES_THIRD, MARQUEE_SPEED } from "utils/constants";
import { Link } from "react-router-dom";

export const IntroBlock = ({big}) => {
  const { t } = useTranslation();

  return (
    <IntroWrapper big={big}>
      <BlockTitleWrapper>
        <BlockTitle>{t("our_models")}</BlockTitle>
      </BlockTitleWrapper>
      <InfoChild>
        <Marquee pauseOnHover direction="right" speed={MARQUEE_SPEED}>
          {CAR_NAMES.map((element) =>(
            <Link to={element.link} key={element.link}>
              <MarqueeEveryBlock>{element.name}</MarqueeEveryBlock>
            </Link>
            )
          )}
        </Marquee>
        <Marquee pauseOnHover direction="left" speed={MARQUEE_SPEED}>
          {CAR_NAMES_SECOND.map((element) =>(
              <Link to={element.link} key={element.link}>
                <MarqueeEveryBlock>{element.name}</MarqueeEveryBlock>
              </Link>
            )
          )}
        </Marquee>
        <Marquee pauseOnHover={true} direction="right" speed={MARQUEE_SPEED}>
          {CAR_NAMES_THIRD.map((element) =>(
              <Link to={element.link} key={element.link}>
                <MarqueeEveryBlock>{element.name}</MarqueeEveryBlock>
              </Link>
            )
          )}
        </Marquee>
      </InfoChild>
    </IntroWrapper>
  )
}
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectEcoMotorsServices } from "store/services/selector";
import { servicesEcoMotors } from "store/services/actions";
import { ROUTENAMES } from "routes/routeNames";
import { IMAGE_STARTING_URL } from "utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  BlackWithOpacity,
  LinkForBlock,
  ServiceAnimatedTitle,
  ServiceBlock,
  ServicesMainBlock,
  ServicesTitleBlock,
  ServicesWrapper
} from "containers/services/styled";

export const Services = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const services = useSelector(selectEcoMotorsServices);
  const [language, setLanguage] = useState(localStorage.getItem("lang"));

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(servicesEcoMotors({ language }));
  }, [dispatch, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <ServicesWrapper>
      <ServicesTitleBlock>
        <ServiceAnimatedTitle>{t("services")}</ServiceAnimatedTitle>
      </ServicesTitleBlock>
      <ServicesMainBlock>
        {services.map((element) => (
          <LinkForBlock key={element.id} to={`${ROUTENAMES.services}/${element.slug}`}
            target="_blank">
            <ServiceBlock>
              <LazyLoadImage
                effect="blur"
                alt='service'
                src={`${IMAGE_STARTING_URL}/${element.image}`}
              />
              <BlackWithOpacity>{element.name_service}</BlackWithOpacity>
            </ServiceBlock>
          </LinkForBlock>
        ))}
      </ServicesMainBlock>
    </ServicesWrapper>
  );
};

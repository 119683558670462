import { createAsyncThunk } from "@reduxjs/toolkit";
import { creditWithBankService } from "store/creditWithBank/service";

export const creditWithBank = createAsyncThunk(
  "creditWithBank/fetch",
  async(_, { rejectWithValue }) => {
    try {
      const response = await creditWithBankService.creditWithBank();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { carFilterCountryService } from "./service";

export const carFilterCountry = createAsyncThunk(
  "carFilterCountry/fetch",
  async(id, { rejectWithValue }) => {
    try {
      const response = await carFilterCountryService.carFilter(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
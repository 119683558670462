import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditWithBank } from "store/creditWithBank/actions";
import { selectCarWithCredit } from "store/creditWithBank/selector";
import { NumberWithDots, NumberWithoutDots } from "HOC/numberWithDots";
import { SendCreditButton } from "containers/profile/styled";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreditValidation } from "validations/creditValidation";
import { selectCreditWithCard } from "store/creditWithCardPost/selector";
import { REDUX_STATES } from "utils/constants";
import { resetStatus } from "store/creditWithCardPost/slice";
import { creditWithCardPost } from "store/creditWithCardPost/actions";
import { HeaderAdvertisement } from "components/header/headerAdvertisement";
import {
  CreditFieldSuccessText,
  CreditFieldSuccessWrapper,
  CreditFieldWrapper,
  CreditMainInput,
  CreditMainModal,
  CreditMainModalLeftSide,
  CreditMainModalRightSide,
  CreditMainModalTitle,
  CreditMainText,
  EcoMotorsLogo,
  ErrorMessage,
  FormInput,
  PaymentPerMonth,
  PerMonthText,
  PhoneNumberBlock,
  PriceText,
  RangeInput,
  RangeInputBlock,
  RangeInputBlockWrapper,
  RangeInputValue,
  RangeInputWrapper
} from "containers/CreditField/styled";

export const CreditField = ({ secondIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectBankCreditValueStatus = useSelector(selectCreditWithCard);
  const selectCreditWithBankValue = useSelector(selectCarWithCredit);
  const pricePercent = selectCreditWithBankValue.credit_percent;
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const [creditValue, setCreditValue] = useState(
    selectCreditWithBankValue.credit_price_min
  );
  const [timeValue, setTimeValue] = useState(
    selectCreditWithBankValue.credit_time_min
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(useCreditValidation())
  });

  function handleChange(event, text) {
    if (text === "credit_value") {
      setCreditValue(event.target.value);
    } else {
      setTimeValue(event.target.value);
    }
  }

  const changeCreditValue = (e) => {
    setCreditValue(NumberWithoutDots(e.target.value));
  };

  const onSubmit = (data) => {
      dispatch(
        creditWithCardPost({
          credit_first_name: data.name_person,
          credit_last_name: data.surName_person,
          credit_phone: data.phone_number,
          credit_price: creditValue,
          credit_time: timeValue,
          credit_price_mount: pricePerMonth
        })
      );
  };

  useEffect(() => {
    setCreditValue(selectCreditWithBankValue.credit_price_min);
    setTimeValue(selectCreditWithBankValue.credit_time_min);
  }, [selectCreditWithBankValue]);

  useEffect(() => {
    dispatch(creditWithBank());
  }, [dispatch]);

  useEffect(() => {
    if (secondIsOpen) {
      document.body.style.overflow = "hidden";
    }
  }, [secondIsOpen]);

  useEffect(() => {
    setPricePerMonth(
      Math.round(
        (+creditValue + (+creditValue * parseFloat(pricePercent)) / 100) /
        +timeValue
      )
    );
  }, [timeValue, creditValue]);

  useEffect(() => {
    if (selectBankCreditValueStatus === REDUX_STATES.SUCCEEDED) {
      setTimeout(() => {
        dispatch(resetStatus());
      }, 2000);
    }
  }, [selectBankCreditValueStatus]);

  return (
    <>
      <HeaderAdvertisement />
      <CreditFieldWrapper onSubmit={handleSubmit(onSubmit)}>
        {selectBankCreditValueStatus === REDUX_STATES.SUCCEEDED ? <CreditFieldSuccessWrapper>
          <CreditFieldSuccessText> Ուղարկվել է բանկ</CreditFieldSuccessText>
        </CreditFieldSuccessWrapper> : null
        }
        <CreditMainModalTitle> {t("credit_calculator")} </CreditMainModalTitle>
        <CreditMainModal>
          <CreditMainModalLeftSide>
            <EcoMotorsLogo />
            <RangeInputWrapper>
              <RangeInputBlockWrapper>
                <RangeInputBlock
                  type="text"
                  value={NumberWithDots(creditValue)}
                  onChange={changeCreditValue}
                  pattern="[0-9,]*"
                  inputMode="numeric"
                  minLength={
                    selectCreditWithBankValue?.credit_price_min?.length || 0
                  }
                  maxLength={
                    selectCreditWithBankValue?.credit_price_max?.length + 2 ||
                    Infinity
                  }
                />
                <RangeInputValue>{t("amd")}</RangeInputValue>
              </RangeInputBlockWrapper>
              <RangeInput
                type="range"
                value={creditValue}
                min={selectCreditWithBankValue.credit_price_min}
                max={selectCreditWithBankValue.credit_price_max}
                onChange={(e) => handleChange(e, "credit_value")}
              />
            </RangeInputWrapper>
            <RangeInputWrapper>
              <RangeInputValue>
                {timeValue} {t("month")}
              </RangeInputValue>
              <RangeInput
                type="range"
                value={timeValue}
                min={selectCreditWithBankValue.credit_time_min}
                max={selectCreditWithBankValue.credit_time_max}
                onChange={(e) => handleChange(e, "time_value")}
              />
            </RangeInputWrapper>
            <PaymentPerMonth>
              <PerMonthText>{t("monthly_fee")}</PerMonthText>
              <PriceText>{NumberWithDots(pricePerMonth) + " ֏"} </PriceText>
            </PaymentPerMonth>
          </CreditMainModalLeftSide>
          <CreditMainModalRightSide>
            <CreditMainText>
              *{t("credit_first_text")}
              <br />
              <br />
              <br />
              {t("credit_second_text")}
            </CreditMainText>
            <FormInput>
              <CreditMainInput
                placeholder={t("name_text")}
                {...register("name_person")}
              />
              <ErrorMessage>{errors?.name_person?.message}</ErrorMessage>
            </FormInput>
            <FormInput>
              <CreditMainInput
                placeholder={t("surname_text")}
                {...register("surName_person")}
              />
              <ErrorMessage>{errors?.surName_person?.message}</ErrorMessage>
            </FormInput>
            <FormInput>
              <Controller
                control={control}
                name="phone_number"
                render={({ field }) => (
                  <PhoneNumberBlock
                    isWithFull
                    country="am"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <ErrorMessage>{errors?.phone_number?.message}</ErrorMessage>
            </FormInput>

            <SendCreditButton>
              {selectBankCreditValueStatus === REDUX_STATES.PENDING
                ? "..."
                : errors.name_person || errors.surName_person
                  ? "Գրեք նորից"
                  : selectBankCreditValueStatus === REDUX_STATES.SUCCEEDED
                    ? "Ուղարկվել է բանկ"
                    : t("apply_now")}
            </SendCreditButton>
          </CreditMainModalRightSide>
        </CreditMainModal>
      </CreditFieldWrapper>
    </>
  );
};

import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";
import { Images } from "assets";

export const ModalWrapper = styled.div`
  position: absolute;
  top: 10px;
  width: max-content;
  display: flex;
  height: 220px;
  z-index: 11;
  padding-left: 10px;
  background-color: aqua;
  left: 0;

  @media only screen and (max-width: 1280px) {
    height: 180px;
  }
`;

export const ModalMainBlock = styled.div`
  width: 500px;
  height: 220px;
  background: rgba(115, 198, 93, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;

  @media only screen and (max-width: 1280px) {
    min-width: 300px;
    width: auto;
    height: auto;
  }
`;

export const ModalSuccessMessage = styled.span`
  ${DefaultFont};
  color: ${COLORS.white};
  font-size: 22px;
`;


export const CreditModalWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
  position: absolute;
  z-index: 200;
`;

export const CreditMainModal = styled.div`
  display: flex;
  justify-content: space-between;
  width: 700px;
  height: 300px;
  background: ${COLORS.white};
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 200px;

  @media only screen and (max-width: 768px) {
    width: 400px;
    height: 450px;
    top: 140px;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLORS.white};
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLORS.main_color};
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${COLORS.main_color};
    }
  }

  @media only screen and (max-width: 428px) {
    width: 300px;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const CreditMainModalLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  height: 300px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RangeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 60px;
`;

export const RangeInput = styled.input.attrs({
  type: "range"
})`
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  -webkit-appearance: none;
  width: ${({ isWidth }) => isWidth ? "100%" : "80%"};
  height: 20px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: ${COLORS.main_color};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${COLORS.white};
    cursor: pointer;
  }
`;

export const RangeInputValue = styled.span`
  width: auto;
  height: 30px;
  ${DefaultFont};
  font-weight: 800;
  color: ${COLORS.main_color};
  display: flex;
  align-items: center;
`;

export const CreditMainModalRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 32px;
  }
`;
export const CreditMainText = styled.span`
  font-size: 10px;
  ${DefaultFont};
`;

export const CreditMainInput = styled.input`
  width: 97%;
  border: unset;
  outline: none;
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.main_color};

  &::placeholder {
    color: ${COLORS.main_color};
  }
`;

export const EcoMotorsLogo = styled.div`
  width: 100%;
  height: 40px;
  background: url(${Images.logo});
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    background-position: center;
  }
`;

export const PaymentPerMonth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const PerMonthText = styled.span`
  color: ${COLORS.main_color};
  font-size: 12px;
`;

export const PriceText = styled.span`
  padding: 4px;
  color: ${COLORS.white};
  font-size: 14px;
  background-color: ${COLORS.main_color};
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FormInput = styled.div`
  width: 100%;
  height: 35px;
`;

export const ErrorMessage = styled.span`
  position: relative;
  width: 100%;
  font-size: 12px;
  color: ${COLORS.red};
`;


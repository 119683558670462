import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";

export const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: ${COLORS.main_color};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spin} 0.8s linear infinite;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

import React, { useEffect, useState } from "react";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Thumbs } from "swiper";
import { CREDENTIALS, IMAGE_STARTING_URL } from "utils/constants";
import { BigCarImage, CarsSlider } from "components/cars/styled.js";
import { LikeIcon, LikeIconParent } from "containers/carPage/styled";
import { Images } from "assets";
import { ROUTENAMES } from "routes/routeNames";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { selectFavouriteCarStatus } from "store/favourite/selector";

export const CarSpecialPageSlider = ({
                                       carData,
                                       selectFavouriteCar,
                                       saveCarAsFavourite,
                                       setThirdIsOpen,
                                       thirdIsOpen
                                     }) => {
  const [imagesData, setImagesData] = useState();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigate = useNavigate();
  const selectFavouriteStatus = useSelector(selectFavouriteCarStatus);

  const likeCar = () => {
    const isAuthenticated = localStorage.getItem(CREDENTIALS.ACCESS_TOKEN);
    if (isAuthenticated === null) {
      navigate(ROUTENAMES.sign_in);
    } else if (isAuthenticated) {
      saveCarAsFavourite(carData.id);
    }
  };

  const changeSelectedImage = () => {
    setThirdIsOpen(!thirdIsOpen);
  };

  useEffect(() => {
    setImagesData(carData?.product_images);

    if (carData?.product_images) {
      const index = carData.product_images.findIndex(obj => obj.main_image === 1);

      if (index !== -1 && index !== 0) {
        const updatedImagesData = [...carData.product_images];

        const removedObject = updatedImagesData.splice(index, 1)[0];

        updatedImagesData.unshift(removedObject);

        setImagesData(updatedImagesData);
      }
    }
  }, [carData?.product_images]);

  return (
    <>
      <Swiper
        style={{
          "height": "auto",
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff"
        }}
        spaceBetween={10}
        slidesPerView={1}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs]}
        className="mySwiper2"
      >
        {imagesData?.map((element) => (
          <CarsSlider isbigimage="true" key={element.id}>
            <BigCarImage
              style={{objectFit: "contain"}}
              onClick={() => changeSelectedImage(`${IMAGE_STARTING_URL}/${element.image}`)}
              src={`${IMAGE_STARTING_URL}/${element.image}`}
              alt='slider big car'
            />
          </CarsSlider>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        watchSlidesProgress={true}
        className="mySwiper"
        style={{
          "height": "auto"
        }}
      >
        {imagesData?.map((element) => (
          <CarsSlider key={element.id}>
            <LazyLoadImage
              effect="blur"
              src={`${IMAGE_STARTING_URL}/${element.image}`}
              alt='slider small'
            />
          </CarsSlider>
        ))}
      </Swiper>
    </>
  );
};

export const descriptionMappings = {
  "/": {
    en: "Ecomotors is engaged in the import and sale of electric vehicles and charging stations. We offer the best range in stock and on order at the best prices l Ecomotors.am",
    am: "Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով: Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am",
    ru: "Компания Ecomotors занимается импортом и продажей электромобилей и зарядных станций. Мы предлагаем лучший ассортимент в наличии и под заказ по самым выгодным ценам l Ecomotors.am"
  },
  "/about": {
    en: "Ecomotors is engaged in the import and sale of electric vehicles and charging stations. We offer the best range in stock and on order at the best prices l Ecomotors.am",
    am: "Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով: Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am",
    ru: "Компания Ecomotors занимается импортом и продажей электромобилей и зарядных станций. Мы предлагаем лучший ассортимент в наличии и под заказ по самым выгодным ценам l Ecomotors.am"
  },
  "/cars": {
    en: "Ecomotors offers a wide range of electric vehicles in stock and on order at the best prices l Ecomotors.am",
    am: "Ecomotors ընկերությունը առաջարկում է Էլեկտրական մեքենաների և առկա և պատվերով տեսականին ամենահարմար գներով l Ecomotors.am",
    ru: "Компания Ecomotors предлагает широкий выбор электромобилей в наличии и на заказ по самым выгодным ценам l Ecomotors.am"
  },
  "/contact": {
    en: "Contact Us l Ecomotors.am",
    am: "Կապ Մեզ Հետ l Ecomotors.am",
    ru: "Связаться с Нами l Ecomotors.am"
  },
  "/blog": {
    en: "Blog section for electric vehicles l Ecomotors.am",
    am: "Բլոգ բաժին ՝ էլեկտրական մեքենաների համար l Ecomotors.am",
    ru: "Раздел блога для электромобилей l Ecomotors.am"
  },
  "/eco-motors/sign-in": {
    en: "Login to the personal page l Ecomotors.am",
    am: "Մուտք անձնական Էջ  l Ecomotors.am",
    ru: "Вход на персональную страницу l Ecomotors.am"
  },
  "/eco-motors/sign-up": {
    en: "Registration on our website l Ecomotors.am",
    am: "Գրանցում մեր կայքում l Ecomotors.am",
    ru: "Зарегистрация на нашем сайте l Ecomotors.am"
  },
  "/car/0001-honda-ens1-full-top-2022": {
    en: "Buy Honda e:NS1 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda e:NS1  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Купить электромобиль Honda e:NS1 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0003-volkswagen-id-4-pure-2022": {
    en: "Buy Volkswagen ID 4 PURE + electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 PURE +  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 PURE + от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0004-volkswagen-id-4-lite-pro-2022": {
    en: "Buy Volkswagen ID 4 Lite Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 Lite Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 Lite Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0005-volkswagen-id-4-pro-2023": {
    en: "Buy Volkswagen ID 4 PROelectric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 PRO էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 PROот ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0006-qiteng-m70ev-2021": {
    en: "Buy Qiteng M70EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Qiteng M70EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Qiteng M70EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0007-dongfeng-ec31-2022": {
    en: "Buy Dongfeng EC31 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Dongfeng EC31  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Dongfeng EC31 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0006-changan-eado460ev-2022": {
    en: "Buy Changan EADO460EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Changan EADO460EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Купить электромобиль Changan EADO460EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0007-byd-tang-2022": {
    en: "Buy BYD Tang electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Tang  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Купить электромобиль BYD Tang от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0008-volkswagen-id-6-prime-4wd-2022": {
    en: "Buy Volkswagen ID 6 Prime 4WD electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Prime 4WD  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Prime 4WD от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0009-volvo-c40-recharge-2022": {
    en: "Buy Volvo C40 RECHARGE electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volvo C40 RECHARGE  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volvo C40 RECHARGE от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0010-byd-han-ev-2022": {
    en: "Buy BYD Han EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Han EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Han EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0011-toyota-bz4x-ultra-2022": {
    en: "Buy Toyota BZ4X Ultra electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota BZ4X Ultra  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota BZ4X Ultra от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0012-bmw-ix-3-2023": {
    en: "Buy BMW IX 3 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BMW IX 3  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BMW IX 3 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0013-audi-q5-e-tron-quattro-2022": {
    en: "Buy Audi Q5 E-Tron Quattro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi Q5 E-Tron Quattro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi Q5 E-Tron Quattro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0013-zeekr-001-2023": {
    en: "Buy ZEEK001 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR 001  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR 001 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0014-kia-k3-ev-2022": {
    en: "Buy KIA K3 EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք KIA K3 EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль KIA K3 EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0015-hongqi-e-qm5-2022": {
    en: "Buy Hongqi E-QM5 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Hongqi E-QM5  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Hongqi E-QM5 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0018-hongqi-e-hs9-6-seats-2022": {
    en: "Buy Hongqi E-HS9 6 Seats electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Hongqi E-HS9 6 Seats  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Hongqi E-HS9 6 Seats от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0015-hongqi-e-hs9-7-seats-2022": {
    en: "Buy Hongqi E-HS9 7 Seats electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Hongqi E-HS9 7 Seats  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Hongqi E-HS9 7 Seats от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0020-mercedes-benz-eqs-580-4matic-2023": {
    en: "Buy Mercedes-Benz EQS 580 4matic electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQS 580 4matic  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQS 580 4matic от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0019-mazda-cx30-ev-2023": {
    en: "Buy Mazda CX30 EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mazda CX30 EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mazda CX30 EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0021-nissan-ariya-2022": {
    en: "Buy Nissan Ariya electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Nissan Ariya  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Nissan Ariya от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0022-mitsubishi-airtrek-ev-2022": {
    en: "Buy Mitsubishi Airtrek EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mitsubishi Airtrek EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mitsubishi Airtrek EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0023-voyah-dreamer-ev-2022": {
    en: "Buy Voyah DREAMER EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Voyah DREAMER EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Voyah DREAMER EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0024-baojun-kiwi-2022": {
    en: "Buy Baojun Kiwi electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Baojun Kiwi  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Baojun Kiwi от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0025-volkswagen-id-3-pure-2022": {
    en: "Buy Volkswagen ID 3 Pure electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 3 Pure  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 3 Pure от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0026-volkswagen-id-3-pro-2022": {
    en: "Buy Volkswagen ID 3 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 3 Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 3 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0027-dayun-yuehu-2022": {
    en: "Buy Dayun Yuehu electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Dayun Yuehu  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Dayun Yuehu от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0028-weltmeister-ex5-z-2022": {
    en: "Buy Weltmeister EX5-Z electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Weltmeister EX5-Z  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Weltmeister EX5-Z от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0029-changan-benben-star-2022": {
    en: "Buy Changan BENBEN Star electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Changan BENBEN Star  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Changan BENBEN Star от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0032-tesla-model-3-performance-2022": {
    en: "Buy Tesla Model 3 Performance electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Tesla Model 3 Performance  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Tesla Model 3 Performance от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0033-tesla-model-3-2022": {
    en: "Buy Tesla Model 3 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Tesla Model 3  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Tesla Model 3 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0034-audi-q5-e-tron-quattro-2022": {
    en: "Buy Audi Q5 E-Tron Quattro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi Q5 E-Tron Quattro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi Q5 E-Tron Quattro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0035-dongfeng-ex1-2022": {
    en: "Buy Dongfeng EX1 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Dongfeng EX1  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Dongfeng EX1 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "cars/0036-volkswagen-id-6-pro-2022": {
    en: "Buy Volkswagen ID 6 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0037-honda-m-nv-2022": {
    en: "Buy Honda M-NV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda M-NV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda M-NV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0038-volkswagen-id-4-lite-pro-2022": {
    en: "Buy Volkswagen ID 4 Lite Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 Lite Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 Lite Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0039-byd-dolphin-2022": {
    en: "Buy BYD Dolphin electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Dolphin  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Dolphin от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0040-genesis-gv60ev-2022": {
    en: "Buy Genesis GV60EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Genesis GV60EV  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Genesis GV60EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0041-volkswagen-id-4-prime-4wd-2022": {
    en: "Buy Volkswagen ID 4 PRIME 4WD electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 PRIME 4WD  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 PRIME 4WD от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0042-honda-ens1-full-top-2022": {
    en: "Buy Honda e:NS1 Full TOP electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda e:NS1 Full TOP  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Купить электромобиль Honda e:NS1 Full TOP от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0043-volkswagen-id-4-lite-pro-2022": {
    en: "Buy Volkswagen ID 4 Lite Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 Lite Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 Lite Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0044-toyota-bz4x-long-range-2023": {
    en: "Buy Volkswagen ID 4 PRIME 4WD electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota BZ4X Long Range  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota BZ4X Long Range от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0045-mercedes-benz-eqc-400-4matic-2022": {
    en: "Buy Mercedes-Benz EQC 400 4Matic electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQC 400 4Matic  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQC 400 4Matic от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0046-lotus-eletre-2022": {
    en: "Buy Lotus Eletre electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Lotus Eletre  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Lotus Eletre от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0047-nissan-leaf-2022": {
    en: "Buy Nissan Leaf electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Nissan Leaf  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Nissan Leaf от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0048-chery-qq-ice-cream-2022": {
    en: "Buy Chery QQ Ice cream electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Chery QQ Ice cream  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Chery QQ Ice cream от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0049-chery-small-2022": {
    en: "Buy Chery SMALL electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Chery SMALL  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Chery SMALL от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0051-volkswagen-id-6-pro-2022": {
    en: "Buy Volkswagen ID 6 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0052-byd-song-plus-ev-honor-flagship-version-2021": {
    en: "Buy BYD Song Plus EV Honor / Flagship Versionelectric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Song Plus EV Honor / Flagship Version էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Song Plus EV Honor / Flagship Versionот ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0053-xpeng-g3i-520g-edtion-2022": {
    en: "Buy XPENG G3i 520G+ Edtion electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք XPENG G3i 520G+ Edtion  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль XPENG G3i 520G+ Edtion от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0054-aion-lx-plus-80d-thousand-miles-edition-2022": {
    en: "Buy Aion LX Plus 80D Thousand Miles Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion LX Plus 80D Thousand Miles Edition  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Aion LX Plus 80D Thousand Miles Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0055-aion-lx-plus-80d-max-edition-2022": {
    en: "Buy Aion LX Plus 80D Max Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion LX Plus 80D Max Edition  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։",
    ru: "Купить электромобиль Aion LX Plus 80D Max Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0056-audi-q4-e-tron-40-2022": {
    en: "Buy Audi Q4 E-Tron 40 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi Q4 E-Tron 40  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi Q4 E-Tron 40 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0057-aion-y-plus-80-smart-driving-edition-2023": {
    en: "Buy Aion Y Plus 80 Smart Driving Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion Y Plus 80 Smart Driving Edition  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Aion Y Plus 80 Smart Driving Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0058-aion-lx-plus-80d-flagship-edition-2022": {
    en: "Buy Aion LX Plus 80D Flagship Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion LX Plus 80D Flagship Edition  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Aion LX Plus 80D Flagship Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0059-aion-s-plus-70-smart-driving-version-2022": {
    en: "Buy Aion S Plus 70 Smart Driving Version electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion S Plus 70 Smart Driving Version  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Aion S Plus 70 Smart Driving Version от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "https://www.ecomotors.am/car/0060-aion-s-plus-70-smart-pilot-version-2022": {
    en: "Buy Aion S Plus 70 Smart Pilot Version electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Aion S Plus 70 Smart Pilot Version  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Aion S Plus 70 Smart Pilot Version от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/0061-arcfox-as-hi-premium-version-2022": {
    en: "Buy ARCFOX αS HI Premium Version electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ARCFOX αS HI Premium Version  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ARCFOX αS HI Premium Version от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0062-arcfox-at-653s-2022": {
    en: "Buy ARCFOX αT 653S+  electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ARCFOX αT 653S+   էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ARCFOX αT 653S+  от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "https://www.ecomotors.am/car/0063-nio-es8-pilot-2022": {
    en: "Buy NIO ES8 Pilot electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք NIO ES8 Pilot  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль NIO ES8 Pilot от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0063-nio-es8-pilot-2022": {
    en: "Buy NIO ES8 Pilot electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք NIO ES8 Pilot  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль NIO ES8 Pilot от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0064-bmw-i7-xdrive-60-2023": {
    en: "Buy BMW I7 xDrive 60 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BMW I7 xDrive 60  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BMW I7 xDrive 60 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0065-leapmotor-t03-haoyu-2022": {
    en: "Buy LEAPMOTOR T03 Haoyu electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք LEAPMOTOR T03 Haoyu  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль LEAPMOTOR T03 Haoyu от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0066-volkswagen-id-4-pro-2022": {
    en: "Buy Volkswagen ID 4 PRO electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 PRO  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 PRO от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0067-volkswagen-id-6-pro-2022": {
    en: "Buy Volkswagen ID 6 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0068-honda-ens1-2022": {
    en: "Buy Honda e:NS1   electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda e:NS1    էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել",
    ru: "Купить электромобиль Honda e:NS1   от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0069-audi-q4-e-tron-40-2022": {
    en: "Buy Audi Q4 E-Tron 40 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi Q4 E-Tron 40  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi Q4 E-Tron 40 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0070-volkswagen-id-6-lite-pro-2022": {
    en: "Buy Volkswagen ID 6 Lite Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Lite Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Lite Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0071-volkswagen-id-6-pure-2022": {
    en: "Buy Volkswagen ID 6 Pure + electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 6 Pure +  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 6 Pure + от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0072-volkswagen-id-4-pure-2023": {
    en: "Buy Volkswagen ID 4 PURE + electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Volkswagen ID 4 PURE +  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Volkswagen ID 4 PURE + от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0073-zeekr-x-2023": {
    en: "Buy ZEEKR X electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR X  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR X от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0074-zeekr-me-2023": {
    en: "Buy ZEEKR X ME  electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR X ME   էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR X ME  от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0075-honda-enp1-2023": {
    en: "Buy Honda e:NP1 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda e:NP1  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda e:NP1 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0076-hiphi-x-2022": {
    en: "Buy HiPhi X electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք HiPhi X  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль HiPhi X от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0077-honda-enp1-2023": {
    en: "Buy Honda e:NP1 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda e:NP1  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda e:NP1 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "https://www.ecomotors.am/car/0078-toyota-bz3-premium-2023": {
    en: "Buy Toyota BZ3 Premium electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota BZ3 Premium  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota BZ3 Premium от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0079-toyota-bz3-pro-2023": {
    en: "Buy Toyota BZ3 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota BZ3 Pro  էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota BZ3 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0099-zeekr-009-2022": {
    en: "Buy ZEEKR 009 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR 009 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR 009 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0097-zeekr-009-2022": {
    en: "Buy ZEEKR 009 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR 009 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR 009 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0096-radar-ed6-2022": {
    en: "Buy Radar ED6 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Radar ED6 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Radar ED6 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0095-maxus-t90-ev-2022": {
    en: "Buy Maxus T90 EV electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Maxus T90 EV էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Maxus T90 EV от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0094-byd-yuan-plus-2022": {
    en: "Buy BYD Yuan PLUS electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Yuan PLUS էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Yuan PLUS от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0093-mercedes-benz-eqe-350-delux-2022": {
    en: "Buy Mercedes-Benz EQE 350 Delux electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQE 350 Delux էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQE 350 Delux от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0092-mercedes-benz-eqe-350-pioner-2022": {
    en: "Buy Mercedes-Benz EQE 350 Pioner electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQE 350 Pioner էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQE 350 Pioner от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0091-byd-seagull-2023": {
    en: "Buy BYD seagull electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD seagull էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD seagull от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0090-byd-seal-2023": {
    en: "Buy BYD seal electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD seal էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD seal от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0089-citroen-berlingo-van-2022": {
    en: "Buy Citroen Berlingo Van electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Citroen Berlingo Van էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Citroen Berlingo Van от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0088-citroen-my-ami-cargo-2022": {
    en: "Buy Citroen My Ami Cargo electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Citroen My Ami Cargo էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Citroen My Ami Cargo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0087-mercedes-benz-eqb-350-2022": {
    en: "Buy Mercedes-Benz EQB 350 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQB 350 էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQB 350 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0086-foton-grand-general-ev-pickup-2022": {
    en: "Buy Foton Grand General EV Pickup electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Foton Grand General EV Pickup էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Foton Grand General EV Pickup от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0085-lexus-direct4-vertical-collar-2023": {
    en: "Buy Lexus Direct4 Vertical Collar electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Lexus Direct4 Vertical Collar էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Lexus Direct4 Vertical Collar от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0084-lexus-direct4-indulgence-2023": {
    en: "Buy Lexus Direct4 Indulgence electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Lexus Direct4 Indulgence էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Lexus Direct4 Indulgence от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0083-lexus-direct4-vertical-edtition-2023": {
    en: "Buy Lexus Direct4 Vertical Edtition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Lexus Direct4 Vertical Edtition էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Lexus Direct4 Vertical Edtition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0082-porsche-taycan-4s-cross-turismo-2022": {
    en: "Buy Porsche Taycan 4S cross Turismo  electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Porsche Taycan 4S cross Turismo էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Porsche Taycan 4S cross Turismo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/0081-porsche-taycan-4s-cross-turismo-2022": {
    en: "Buy Porsche Taycan 4S cross Turismo electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Porsche Taycan 4S cross Turismo էլեկտրական մեքենան ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Porsche Taycan 4S cross Turismo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00100-mercedes-benz-eqa-260-2023": {
    en: "Buy Mercedes-Benz EQA 260 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQA 260 էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQA 260 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00101-arcfox-a-s-708-km-2022": {
    en: "Buy ARCFOX Α S+ electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ARCFOX Α S+ էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ARCFOX Α S+ от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00102-toyota-c-hr-ev-izoa-2022": {
    en: "Buy Toyota C-HR EV IZOA electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota C-HR EV IZOA էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota C-HR EV IZOA от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00103-toyota-bz4x-long-range-joy-version-2023": {
    en: "Buy Toyota BZ4X Long Range JOY Version electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Toyota BZ4X Long Range JOY Version էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Toyota BZ4X Long Range JOY Version от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00105-byd-yuan-pro-premium-model-401-km-2023": {
    en: "Buy BYD Yuan Pro Premium Model electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Yuan Pro Premium Model էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Yuan Pro Premium Model от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00106-porsche-taycan-4-cross-turismo-2022": {
    en: "Buy Porsche Taycan 4 Cross Turismo electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Porsche Taycan 4 Cross Turismo էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Porsche Taycan 4 Cross Turismo от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00108-zeekr-001-you-2023": {
    en: "Buy ZEEKR 001 You electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR 001 You էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR 001 You от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00109-ford-electric-horse-2022": {
    en: "Buy Ford Electric Horse electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Ford Electric Horse էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Ford Electric Horse от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00110-zeekr-x-you-4seat-500km-2023": {
    en: "Buy ZEEKR X YOU (4seat, 500km) electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR X YOU (4seat, 500km) էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR X YOU (4seat, 500km) от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00111-toyota-bz4x-four-wheel-drive-high-performance-pro-version-2023": {
    en: "Buy  Toyota BZ4X Pro  electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք  Toyota BZ4X Pro  էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль  Toyota BZ4X Pro  от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00112-audi-e-tron-50-quattro-2023": {
    en: "Buy Audi E-Tron 50 Quattro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi E-Tron 50 Quattro էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi E-Tron 50 Quattro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00113-byd-song-plus-flagship-model-2023": {
    en: "Buy BYD Song Plus Flagship Model electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Song Plus Flagship Model էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Song Plus Flagship Model от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00114-byd-song-plus-champion-flagship-plus-2023": {
    en: "Buy BYD Song Plus Champion Flagship PLUS electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Song Plus Champion Flagship PLUS էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Song Plus Champion Flagship PLUS от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00115-xpeng-g3i-520-2022": {
    en: "Buy XPENG G3I 520 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք XPENG G3I 520 էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль XPENG G3I 520 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00116-denza-n7-basic-2023": {
    en: "Buy Denza N7 Basic electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Denza N7 Basic էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Denza N7 Basic от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00117-denza-n7-top-2023": {
    en: "Buy Denza N7 Top electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Denza N7 Top էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Denza N7 Top от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00118-buick-e4-gs-version-2023": {
    en: "Buy BUICK E4 GS Version electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BUICK E4 GS Version էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BUICK E4 GS Version от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00119-buick-e4-smart-edition-2023": {
    en: "Buy BUICK E4 Smart Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BUICK E4 Smart Edition էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BUICK E4 Smart Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00120-byd-genesis-edition-2023": {
    en: "Buy BYD Genesis Edition electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք BYD Genesis Edition էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль BYD Genesis Edition от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00121-mitsubishi-airtrek-basic-2022": {
    en: "Buy Mitsubishi Airtrek Basic electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mitsubishi Airtrek Basic էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mitsubishi Airtrek Basic от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00122-hiphi-y-flagship-2023": {
    en: "Buy HiPhi Y Flagship electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք HiPhi Y Flagship էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль HiPhi Y Flagship от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00123-hiphi-y-elite-2023": {
    en: "Buy HiPhi Y Elite electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք HiPhi Y Elite էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль HiPhi Y Elite от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00124-hiphi-x-630-2023": {
    en: "Buy HiPhi X 630 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք HiPhi X 630 էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль HiPhi X 630 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00125-honda-enp1-full-2023": {
    en: "Buy Honda E:NP1 Full electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda E:NP1 Full էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda E:NP1 Full от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00126-chery-eq7-max-2023": {
    en: "Buy Chery EQ7 MAX electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Chery EQ7 MAX էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Chery EQ7 MAX от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00127-lotus-eletre-s-2022": {
    en: "Buy Lotus Eletre S+ electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Lotus Eletre S+ էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Lotus Eletre S+ от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00128-dongfeng-mengshi-917-2023-2023": {
    en: "Buy Dongfeng Mengshi 917 2023 electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Dongfeng Mengshi 917 2023 էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Dongfeng Mengshi 917 2023 от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00129-audi-30-e-tron-q2l-2022": {
    en: "Buy Audi 30 E-Tron Q2L electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi 30 E-Tron Q2L էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi 30 E-Tron Q2L от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00130-zeekr-x-you-5-seat-2023": {
    en: "Buy ZEEKR X YOU 5 Seat electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR X YOU 5 Seat էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR X YOU 5 Seat от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00131-mercedes-benz-eqe-suv-500-pioner-basic-2023": {
    en: "Buy Mercedes-Benz EQE SUV 500 Pioner (Basic) electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Mercedes-Benz EQE SUV 500 Pioner (Basic) էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Mercedes-Benz EQE SUV 500 Pioner (Basic) от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00132-hongqi-ehs9-4-seats-2022": {
    en: "Buy Hongqi EHS9 4 Seats electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Hongqi EHS9 4 Seats էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Hongqi EHS9 4 Seats от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00133-zhiji-ls7-pro-2023": {
    en: "Buy Zhiji LS7 Pro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Zhiji LS7 Pro էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Zhiji LS7 Pro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00134-kia-ev6-gt-opt-2023": {
    en: "Buy KIA EV6 GT Opt electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք KIA EV6 GT Opt էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль KIA EV6 GT Opt от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00135-nissan-ariya-performance-plus-2023": {
    en: "Buy Nissan Ariya Performance Plus electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Nissan Ariya Performance Plus էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Nissan Ariya Performance Plus от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00136-avatr-11-model-011-mmw-4-seats-2023": {
    en: "Buy Avatr 11 Model 011 MMW 4 Seats electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Avatr 11 Model 011 MMW 4 Seats էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Avatr 11 Model 011 MMW 4 Seats от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00137-audi-e-tron-sportback-50-quattro-2020": {
    en: "Buy Audi E-Tron Sportback 50 Quattro electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Audi E-Tron Sportback 50 Quattro էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Audi E-Tron Sportback 50 Quattro от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00138-zeekr-001-z-sport-2023": {
    en: "Buy ZEEKR 001 Z Sport electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք ZEEKR 001 Z Sport էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль ZEEKR 001 Z Sport от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00140-dongfeng-forthing-friday-2023": {
    en: "Buy Dongfeng Forthing Friday electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Dongfeng Forthing Friday էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Dongfeng Forthing Friday от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00141-honda-enp-1-e-dynamic-2023": {
    en: "Buy Honda E:Np 1 E-Dynamic electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda E:Np 1 E-Dynamic էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda E:Np 1 E-Dynamic от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
  "/car/00142-honda-enp-1-e-type-2023": {
    en: "Buy Honda E:Np 1 E-Type electric car from ECOMOTORS at the best price or order any other model.",
    am: "Գնեք Honda E:Np 1 E-Type էլեկտրոմոբիլը ECOMOTORS-ից ամենամատչելի գնով կամ պատվիրեք այլ ցանկացած մոդել։ ",
    ru: "Купить электромобиль Honda E:Np 1 E-Type от ECOMOTORS по лучшей цене или закажите любую другую модель."
  },
};
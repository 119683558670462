import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AddressesBlock,
  EveryAddressContainer,
  EveryAddressTitle,
  Iframe,
  OurAddressesTitle,
  OurAddressesWrapper
} from "containers/ourAdresses/styled";

export const OurAdresses = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <OurAddressesWrapper>
      <OurAddressesTitle>{t("our_addresses")}</OurAddressesTitle>
      <AddressesBlock>
        <EveryAddressContainer>
          <EveryAddressTitle>{t("headOffice")}</EveryAddressTitle>
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12185.629056200443!2d44.4558709!3d40.222
            2462!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406a973a1b064dab%3A0x6f01ee1ebf2a60b4!2sECOMOTORS%20AM!5e0!3m2!1sru!2sam!4v1693159572973!5m2!1sru!2sam"
            allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
          </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>{t("second_market")}</EveryAddressTitle>
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4718.391853586417!2d44.57796010130143!3d40.25026402236693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa3dcfaec09a5%3A0xb2d3ddcb1e65d895!2sECOMOTORS%20AM!5e1!3m2!1sru!2sam!4v1695659592931!5m2!1sru!2sam"
            allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
        </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>{t("third_market")}</EveryAddressTitle>
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d390343.1385317667!2d43.92881530717536!3d40.15217799736966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd80cbbbd947%3A0x534ff3232770cc06!2sECOMOTORS%20AM!5e0!3m2!1sru!2sam!4v1700817786806!5m2!1sru!2sam"
            allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"/>
        </EveryAddressContainer>
      </AddressesBlock>
    </OurAddressesWrapper>
  )
}
import { createSlice } from "@reduxjs/toolkit";
import { sparePartsCategory } from "store/sparePartsCategory/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  sparePartsCategoryStatus: null,
  data: [],
  errors: "",
};

export const sparePartsInfoData = createSlice({
  name: "sparePartsCategory",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "sparePartsCategoryStatus":
          state.sparePartsCategoryStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [sparePartsCategory.pending]: (state) => {
      state.errors = null;
      state.sparePartsCategoryStatus = REDUX_STATES.PENDING;
    },
    [sparePartsCategory.fulfilled]: (state, action) => {
      state.data = action.payload.sparePartsCategory;
      state.sparePartsCategoryStatus = REDUX_STATES.SUCCEEDED;
    },
    [sparePartsCategory.rejected]: (state, { payload }) => {
      state.sparePartsCategoryStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = sparePartsInfoData.actions;

export default sparePartsInfoData.reducer;

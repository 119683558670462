import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { carCompare } from "store/carsCompare/actions";

const initialState = {
  carCompareStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const CarCompareData = createSlice({
  name: "carCompare",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "carCompareStatus":
          state.carCompareStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [carCompare.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.carCompareStatus = REDUX_STATES.PENDING;
    },
    [carCompare.fulfilled]: (state, action) => {
      state.data = action?.payload?.cars;
      state.carCompareStatus = REDUX_STATES.SUCCEEDED;
    },
    [carCompare.rejected]: (state, { payload }) => {
      state.carCompareStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarCompareData.actions;

export default CarCompareData.reducer;

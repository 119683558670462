import { createAsyncThunk } from "@reduxjs/toolkit";
import { countryService } from "store/country/service";

export const country = createAsyncThunk(
  "country/fetch",
  async(language, { rejectWithValue }) => {
    try {
      const response = await countryService.country(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
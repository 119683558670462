import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectAvailableCars } from "store/availableCars/selector";
import { availableCars } from "store/availableCars/actions";
import { useTranslation } from "react-i18next";
import { IMAGE_STARTING_URL } from "utils/constants";
import { Link } from "react-router-dom";
import { ROUTENAMES } from "routes/routeNames";
import { Salon } from "store/salonLocalizationsIds/actions";
import { selectSalons } from "store/salonLocalizationsIds/selector";
import { onlySalonData } from "store/onlySalon/actions";
import { selectOnlySalonData } from "store/onlySalon/selector";
import {
  AvailableWrapper,
  CarEveryBlock, CarEveryPlace,
  CarEveryTitle,
  CarInfo,
  CarsWrapperAvailable,
  CarWrappingContent, CheckboxForSalon, ChooseSalonText,
  Content,
  ContentTitle,
  EverySalonWrapper,
  FilterWithPlace,
  LinkToCarButton,
  SalonText,
  WrapperContent
} from "containers/availableCars/styled";


export const AvailableCars = () => {
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectActiveCarsData = useSelector(selectAvailableCars);
  const selectNumberCars = useSelector(selectOnlySalonData);
  const selectActiveSalons = useSelector(selectSalons);

  const filteredCars = number > 0 ? selectNumberCars : selectActiveCarsData;

  useEffect(() => {
    dispatch(availableCars());
    dispatch(Salon());
  }, [dispatch]);

  useEffect(() => {
    if(number >= 1) {
      dispatch(onlySalonData(number))
    }
  }, [dispatch, number])

  return (
    <AvailableWrapper>
      <Content>
        <ContentTitle>{t("car_available")}</ContentTitle>

        <CarsWrapperAvailable>
          <FilterWithPlace>
            <ChooseSalonText>{t('select_auto_salon')}</ChooseSalonText>
            <EverySalonWrapper key={'id-1'}>
              <CheckboxForSalon
                type='checkbox'
                onChange={() => setNumber(0)}
                checked={number === 0}
              />
              <SalonText>{t('all_cars')}</SalonText>
            </EverySalonWrapper>
            {selectActiveSalons?.map((element, index) =>
              element.id > 8 || element.id === 6 || element.id === 5 ? null : (
                <EverySalonWrapper key={index}>
                  <CheckboxForSalon
                    type='checkbox'
                    onChange={() => setNumber(element.id)}
                    checked={number === element.id}
                  />
                  <SalonText>{element.salon_localization_text}</SalonText>
                </EverySalonWrapper>
              )
            )}
          </FilterWithPlace>
          <WrapperContent>
            {filteredCars?.map((element, index) => {
              return (
                <CarWrappingContent key={index}>
                  {
                    element?.product_images.map((item) => {
                      return item.main_image ? <CarEveryBlock
                        key={item.id}
                        background={item.main_image ? `${IMAGE_STARTING_URL}/${item?.image}` : null}
                      /> : null;
                    })
                  }
                  <CarInfo>
                    <CarEveryTitle>{element?.product_name?.car_name} {element?.product_model?.model_name}</CarEveryTitle>
                    <CarEveryPlace isTextSmall>{element?.product_car_localization[0]?.salon_localization_text_am}</CarEveryPlace>
                    <Link to={`${ROUTENAMES.car}/${element?.car_slug}`} target="_blank">
                      <LinkToCarButton>{t("see_car")}</LinkToCarButton>
                    </Link>
                  </CarInfo>
                </CarWrappingContent>
              );
            })}

          </WrapperContent>
        </CarsWrapperAvailable>
      </Content>
    </AvailableWrapper>
  );
};
import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { availableCars } from "store/availableCars/actions";

const initialState = {
  availableCarStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
};

export const AvailableCarsSlice = createSlice({
  name: "availableCars",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "availableCarStatus":
          state.availableCarStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [availableCars.pending]: (state) => {
      state.errors = null;
      state.carNamesStatus = REDUX_STATES.PENDING;
    },
    [availableCars.fulfilled]: (state, action) => {
      state.data = action.payload.cars;
      state.carNamesStatus = REDUX_STATES.SUCCEEDED;
    },
    [availableCars.rejected]: (state, { payload }) => {
      state.carNamesStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = AvailableCarsSlice.actions;

export default AvailableCarsSlice.reducer;

import styled from "styled-components";
import { COLORS } from "utils/colors";

export const FullLayout = styled.div`
  width: 100%;
  padding-top: 10px;
`;

export const CarsMainImages = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 150px;
  height: auto;
  padding-bottom: 30px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    padding-inline: 40px;
  }
`;

export const CarsMainImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;


  @media only screen and (max-width: 1280px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CarImagesBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  flex-shrink: 1;
  background: transparent;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  border-radius: 4px;
  border-bottom: ${({selected}) => selected ? '1px solid black' : "1px solid transparent"};
  padding-bottom: 4px;

  &:hover {
    border-bottom: 1px solid ${COLORS.main_color};
  }
`;

export const CarImage = styled.img`
  width: 40px;
  object-fit: contain;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
`;

export const CarTitle = styled.span`
  font-size: 16px;
  color: ${COLORS.dark_blue};
`;

export const CarDataModelsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  background: white;
  top: 0;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
`;

export const CarModel = styled.span`
  color: ${COLORS.main_color};
  cursor: pointer;
  border-bottom: ${({selected}) => selected ? '1px solid black' : "1px solid transparent"};
  transition: 0.3s linear;
  padding-bottom: 4px;
  
  &:hover {
    border-bottom: 1px solid ${COLORS.main_color};
  }
`;

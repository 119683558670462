export const NumberWithDots = (number) => {
  const priceNum = Number(number);

  const formattedPrice = priceNum.toLocaleString("en-US", {
    style: "decimal",
    useGrouping: true
  });

  return formattedPrice;
};

export const NumberWithoutDots = (formattedNumber) => {
  const numberWithoutDots = formattedNumber.replace(/,/g, "");
  return numberWithoutDots;
};



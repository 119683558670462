import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { requestSparePartPost } from "store/requestSparePart/actions";
import {
  SparePartModal,
  SparePartModalButton,
  SparePartModalNameAndNumber
} from "spareParts/components/spareModal/styled";


export const SpareModal = ({ forwardRef, id, model, requestSparePart }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(requestSparePartPost({
      name_person: data.name,
      phone_person: data.phone_number,
      car_model_id: model,
      spare_part_id: id
    }));
    requestSparePart();
  };

  return (
    <SparePartModal ref={forwardRef} onSubmit={handleSubmit(onSubmit)}>
      <SparePartModalNameAndNumber placeholder={t("name")}  {...register("name")}/>
      <SparePartModalNameAndNumber placeholder={t("phone_number")} {...register("phone_number")} />
      <SparePartModalButton>{t("send_message")}</SparePartModalButton>
    </SparePartModal>
  );
};
import { useTranslation } from "react-i18next";
import { Container, Heading, Subheading } from "containers/notFound/styled";
import { useEffect } from "react";

export const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <Container>
      <Heading>404</Heading>
      <Subheading>{t("not_found_page")}</Subheading>
    </Container>
  );
};
import styled, { css } from "styled-components";
import { REDUX_STATES } from "utils/constants";
import { slideFromRight } from "./styled";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const CarList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: auto;
  animation: ${({ SelectCarDataStatus }) =>
          SelectCarDataStatus === REDUX_STATES.SUCCEEDED
                  ? css`
                    ${slideFromRight} 0.2s ease-in-out forwards
                  `
                  : null};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  a {
    width: 100%;
    text-decoration: none;
  }
`;

export const CarListBlock = styled.div`
  overflow: hidden;
  display: flex;
  position: relative;
  gap: 10px;
  width: 100%;
  height: 200px;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 3px solid #f0f0ff;
  position: relative;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 160px;
  }
`;

export const CarListImage = styled.div`
  width: 350px;
  height: 200px;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;

  @media only screen and (max-width: 768px) {
    width: 200px;
    height: 100%;
  }
`;

export const CarListMainInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

export const CarListModelName = styled.h1`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${DefaultFont};
  font-size: 24px;
  color: ${COLORS.main_color};
  text-align: center;
  margin: 0;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CarListStatement = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: max-content;
  padding: 4px 14px;
  border-bottom-right-radius: 20px;
  color: ${COLORS.white};
  font-size: 1.2vw;
  background: ${({ statement }) =>
          statement === 1
                  ? COLORS.red
                  : statement === 2
                          ? COLORS.main_color
                          : statement === 3
                                  ? COLORS.blue
                                  : null};
  text-transform: capitalize;

  @media only screen and (max-width: 548px) {
    font-size: 10px !important;
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1268px) {
    font-size: 1vw;
  }
  @media only screen and (min-width: 1920px) {
    font-size: 0.8vw;
  }
`;

export const CarDateEndListDrive = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
`;
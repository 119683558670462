import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceSlug } from "store/serviceSinglePage/actions";
import { SelectServiceSlug, SelectServiceStatus } from "store/serviceSinglePage/selector";
import { IMAGE_STARTING_URL } from "utils/constants";
import { Container, Content, Image, Title } from "containers/blogPage/styled";

export const ServicePage = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const selectServiceData = useSelector(SelectServiceSlug);
  const selectServiceStatus = useSelector(SelectServiceStatus);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(serviceSlug({ slug, language }));
  }, [dispatch, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setLanguage(localStorage.getItem("lang"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      {selectServiceStatus === "loading"
        ? <Container>
          <Title> Loading... </Title>
        </Container>
        : <Container>
          <Title>{selectServiceData.name_service}</Title>
          <Image
            src={`${IMAGE_STARTING_URL}/${selectServiceData.image}`}
            alt="Blog post image"
          />
          <Content dangerouslySetInnerHTML={{ __html: selectServiceData.text_service }} />
        </Container>
      }
    </>
  );
};
import axios from "axios";
import { API } from "configs/api";

const token = localStorage.getItem("access_token");

const axiosInstance = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${token}`
    }
  }
});

export const changeProfileService = {
  change_profile_data: (body) => axiosInstance.post(`${API.profile}`, { ...body })
};

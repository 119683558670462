import { createAsyncThunk } from "@reduxjs/toolkit";
import { sparePartWithModelId } from "store/sparePartsProductWithModelId/service";

export const sparePartsWithModelID = createAsyncThunk(
  "sparePartWithModelId/fetch",
  async(data, { rejectWithValue }) => {
    try {
      const response = await sparePartWithModelId.sparePart(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f1f1f1;
`;

export const shake = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Heading = styled.h1`
  font-size: 10rem;
  font-weight: bold;
  color: ${COLORS.main_color};
  text-align: center;
  margin-bottom: 0;
  animation: ${shake} 2s ease-in-out infinite;

  @media only screen and (max-width: 1280px) {
    font-size: 6rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 428px) {
    font-size: 2rem;
  }
`;

export const Subheading = styled.h2`
  font-size: 4rem;
  font-weight: normal;
  color: ${COLORS.main_color};
  text-align: center;
  margin-top: 0;

  @media only screen and (max-width: 1280px) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 428px) {
    font-size: 2rem;
  }
`;

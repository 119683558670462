import { createAsyncThunk } from "@reduxjs/toolkit";
import { websiteMainDataService } from "store/websiteMainData/service";

export const websiteMainData = createAsyncThunk(
  "websiteMainData/fetch",
  async(language, { rejectWithValue }) => {
    try {
      const response = await websiteMainDataService.website_main_data(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { CheckBox, CheckBoxContainer, EveryCheckBoxWithText, Label } from "components/welcome/styled";
import { cars } from "store/cars/actions";
import { ROUTENAMES } from "routes/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { selectCarModels } from "store/carModels/selector";
import { selectCarNames } from "store/carNames/selector";
import { selectCountry } from "store/country/selector";
import { carNames } from "store/carNames/actions";
import { COLORS } from "utils/colors";
import { carModels } from "store/carModels/actions";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import { carPriceFromOptions, carYearFromOptions, carYearToOptions } from "utils/options";
import { SELECTED_TYPES } from "utils/constants";
import {
  AuthorizedRepresentative, AuthorizedRepresentativeText,
  CarModelName,
  CategoriesContainer,
  InputContainer,
  LeftCarsWithCheckbox,
  LeftCategoriesSection,
  SelectField,
  SendButton, TwoCompaniesImage, TwoCompaniesImageWidth, TwoCompaniesWrapper
} from "components/cars/styled";
import { Images } from "../../assets";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background:
      state.isFocused || state.isSelected ? COLORS.main_color : COLORS.white,
    color:
      state.isFocused || state.isSelected ? COLORS.white : COLORS.main_color
  }),
  control: (provided, state) => ({
    ...provided,
    color: COLORS.main_color,
    boxShadow:
      state.isFocused || state.isSelected
        ? COLORS.main_color
        : COLORS.main_color,
    borderColor:
      state.isFocused || state.isSelected
        ? COLORS.main_color
        : COLORS.main_color
  })
};

export const CarLeftSideBlock = ({ carName, setCarName, setSelectedType, isFilterCountryAvailable }) => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [carModel, setCarModel] = useState({
    value: "",
    label: t("car_model")
  });
  const [selectPriceFrom, setSelectPriceFrom] = useState({
    value: "",
    label: t("price_from")
  });
  const [selectPriceTo, setSelectPriceTo] = useState({
    value: "",
    label: t("price_to")
  });
  const [selectYearFrom, setSelectYearFrom] = useState({
    value: "",
    label: t("year_from")
  });
  const [selectYearTo, setSelectYearTo] = useState({
    value: "",
    label: t("year_to")
  });
  const { handleSubmit } = useForm();
  const CarModels = useSelector(selectCarModels);
  const CarDataNames = useSelector(selectCarNames);
  const countryData = useSelector(selectCountry);

  const handleClick = (id) => {
    navigate(`${ROUTENAMES.cars}?filterCountry=true&id=${id}`);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [window.innerWidth]);

  const onSubmit = () => {
    const filteredData = {
      car_year_from: selectYearFrom.value,
      car_year_to: selectYearTo.value,
      car_price_from: selectPriceFrom.value,
      car_price_to: selectPriceTo.value,
      car_name_id: carName.id,
      car_model_id: carModel.id,
      is_filter: 1
    };

    if (
      filteredData.car_name_id !== "" ||
      filteredData.car_year_from !== "" ||
      filteredData.car_year_to !== "" ||
      filteredData.car_price_from !== "" ||
      filteredData.car_price_to !== "" ||
      filteredData.car_model_id !== ""
    ) {
      navigate(
        `${ROUTENAMES.cars}?filteredData=${JSON.stringify(filteredData)}`
      );
      dispatch(cars(filteredData));
    }
  };

  const selectOptions = CarModels.map((option) => ({
    value: option.car_name,
    label: option.car_name,
    id: option.id
  }));

  const selectCarModelOptions = CarDataNames.map((option) => ({
    value: option.model_name,
    label: option.model_name,
    id: option.id
  }));

  useEffect(() => {
    dispatch(carModels());
  }, [dispatch]);

  useEffect(() => {
    if (carName?.id) {
      dispatch(carNames({ car_name_id: carName.id }));
    }
  }, [carName]);

  useEffect(() => {
    if (carModel.id) {
    } else {
      setCarModel({
        value: "",
        label: t("car_model")
      });
    }
    setSelectPriceFrom({
      value: "",
      label: t("price_from")
    });
    setSelectPriceTo({
      value: "",
      label: t("price_to")
    });
    setSelectYearFrom({
      value: "",
      label: t("year_from")
    });
    setSelectYearTo({
      value: "",
      label: t("year_to")
    });
  }, [language]);

  const handleChangeUi = (constant) => {
    setSelectedType(constant);
  };

  return (
    <LeftCategoriesSection onSubmit={handleSubmit(onSubmit)}>
      <AuthorizedRepresentative>
        <AuthorizedRepresentativeText>{t("authorized_representative")}</AuthorizedRepresentativeText>
        <TwoCompaniesWrapper>
          <a target='_blank' href='https://ecomotors.am/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:8,%22is_filter%22:1}' rel="noreferrer">
            <TwoCompaniesImageWidth src={Images.donfengLogo}/>
          </a>
          <a target='_blank' href='https://ecomotors.am/cars?filteredData={%22car_year_from%22:%22%22,%22car_year_to%22:%22%22,%22car_price_from%22:%22%22,%22car_price_to%22:%22%22,%22car_name_id%22:59,%22is_filter%22:1}' rel='noreferrer'>
            <TwoCompaniesImage src={Images.ruichiLogo} />
          </a>
        </TwoCompaniesWrapper>
      </AuthorizedRepresentative>
      {!(windowWidth < 768) ? <CategoriesContainer>
        <BsFillGrid3X3GapFill onClick={() => handleChangeUi(SELECTED_TYPES.CONTAINER)} />
        <FaListUl onClick={() => handleChangeUi(SELECTED_TYPES.LIST)} />
      </CategoriesContainer> : null}
      <LeftCarsWithCheckbox>
        <CarModelName noData cursorPointer
                      onClick={() => navigate(ROUTENAMES.cars_compare)}>{t("compare_cars")}</CarModelName>
        <CarModelName noData>{t("findYourCar")}</CarModelName>
      </LeftCarsWithCheckbox>

      <InputContainer>
        <SelectField
          options={selectOptions}
          value={carName}
          onChange={(selectedOption) => setCarName(selectedOption)}
          styles={customStyles}
        />

        <SelectField
          options={selectCarModelOptions}
          value={carModel}
          onChange={(selectedCarModelOption) =>
            setCarModel(selectedCarModelOption)
          }
          styles={customStyles}
        />
      </InputContainer>

      <InputContainer>
        <SelectField
          options={carYearFromOptions}
          value={selectYearFrom}
          onChange={(carYearFromOptions) =>
            setSelectYearFrom(carYearFromOptions)
          }
          isSearchable={false}
          styles={customStyles}
        />

        <SelectField
          options={carYearToOptions}
          value={selectYearTo}
          onChange={(carYearToOptions) => setSelectYearFrom(carYearToOptions)}
          isSearchable={false}
          styles={customStyles}
        />
      </InputContainer>

      <InputContainer>
        <SelectField
          options={carPriceFromOptions}
          value={selectPriceFrom}
          onChange={(carPriceFromOptions) =>
            setSelectPriceFrom(carPriceFromOptions)
          }
          isSearchable={false}
          styles={customStyles}
        />

        <SelectField
          options={carPriceFromOptions}
          value={selectPriceTo}
          onChange={(carPriceToOptions) => setSelectPriceTo(carPriceToOptions)}
          isSearchable={false}
          styles={customStyles}
        />
      </InputContainer>

      <SendButton>{t("findCar")}</SendButton>

      <CheckBoxContainer>
        {countryData.map((element) => {
          return (
            <EveryCheckBoxWithText key={uuid()}>
              <CheckBox
                type="radio"
                id={element.name_country}
                name="drone"
                value={element.id}
                onClick={() => handleClick(element.id)}
                defaultChecked={+element.id === +isFilterCountryAvailable}
              />
              <Label htmlFor={element.name_country}>
                {element.name_country}
              </Label>
            </EveryCheckBoxWithText>
          );
        })}
      </CheckBoxContainer>
    </LeftCategoriesSection>
  );
};

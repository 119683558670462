import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { availableCarsSpareParts } from "store/availableCarSparePart/actions";

const initialState = {
  availableCarsSparePartsStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
};

export const AvailableSparePartsCarsSlice = createSlice({
  name: "availableCarsSpareParts",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "availableCarsSparePartsStatus":
          state.availableCarStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [availableCarsSpareParts.pending]: (state) => {
      state.errors = null;
      state.availableCarsSparePartsStatus = REDUX_STATES.PENDING;
    },
    [availableCarsSpareParts.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.availableCarsSparePartsStatus = REDUX_STATES.SUCCEEDED;
    },
    [availableCarsSpareParts.rejected]: (state, { payload }) => {
      state.availableCarsSparePartsStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = AvailableSparePartsCarsSlice.actions;

export default AvailableSparePartsCarsSlice.reducer;

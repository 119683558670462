import { createAsyncThunk } from "@reduxjs/toolkit";
import { availableCarsSparePartsService } from "./service";

export const availableCarsSpareParts = createAsyncThunk(
  "availableCarsSpareParts/fetch",
  async(_, { rejectWithValue }) => {
    try {
      const response = await availableCarsSparePartsService.availableCars();
      return response.data.carNames;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

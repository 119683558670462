import styled from "styled-components";
import { COLORS } from "utils/colors";

export const ReviewsMainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: ${COLORS.white};
  
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }

  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SeeWriteReviews = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 50%;
    flex-wrap: wrap;
  }
`;

export const ReviewsMainLeftSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const ReviewsMainRightSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  z-index: 2;

`;

export const LookReviewsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  cursor: pointer;
  height: 50px;
  border: 1px solid ${COLORS.black};
  border-radius: 8px;
  box-shadow: 10px 10px 5px 0px rgba(145, 198, 93, 1);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(145, 198, 93, 1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(145, 198, 93, 1);
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    box-shadow: 10px 10px 5px 0px rgb(114, 194, 30, 0.5);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(114, 194, 30, 0.3);
    -moz-box-shadow: 10px 10px 5px 0px rgba(114, 194, 30, 0.2);
  }

  @media only screen and (max-width: 768px) {
    width: 210px;
    height: 40px;
  }
`;

export const LookReviewsText = styled.span`
  font-size: 18px;
  color: ${COLORS.black};
  text-transform: capitalize;
`;


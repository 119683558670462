import { createSlice } from "@reduxjs/toolkit";
import { websiteMainData } from "store/websiteMainData/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  websiteMainDataStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const WebsiteData = createSlice({
  name: "websiteMainData",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carStatus":
        state.websiteMainDataStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [websiteMainData.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carSlugStatus = REDUX_STATES.PENDING;
    },
    [websiteMainData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.is_loading = false;
      state.carSlugStatus = REDUX_STATES.SUCCEEDED;
    },
    [websiteMainData.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.websiteMainDataStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = WebsiteData.actions;

export default WebsiteData.reducer;

import { CarModelImage, CarModelsSliderWrapper, CarModelsWrapper, CarModelName } from "components/carModelsSlider/styled.js";
import { CAR_SLIDER_NAMES, MARQUEE_SPEED } from "utils/constants";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export const CarModelsSlider = () => {
  return (
      <CarModelsSliderWrapper>
        <Marquee pauseOnHover direction="right" speed={MARQUEE_SPEED}>
          {CAR_SLIDER_NAMES.map((element) => {
            return (
              <Link to={element.link} target="_blank">
                <CarModelsWrapper>
                  <CarModelImage src={element.image} alt='slider car'/>
                  <CarModelName>{element.name}</CarModelName>
                </CarModelsWrapper>
              </Link>
            )
          })}
        </Marquee>
      </CarModelsSliderWrapper>
  )
}
import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper/core";
import "swiper/swiper-bundle.css";
import { Loading } from "components/loading";
import { useDispatch, useSelector } from "react-redux";
import { selectUrgentCars, selectUrgentCarsStatus } from "store/urgentSale/selector";
import { urgentSale } from "store/urgentSale/actions";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { NumberWithDots } from "HOC/numberWithDots";
import { useTranslation } from "react-i18next";
import { ROUTENAMES } from "routes/routeNames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  CarNameWithGreen,
  CarPriceSlideBlock,
  CarYearAndPriceBlock,
  CarYearAndPriceText,
  LazyLoadLink,
  LinkToCar,
  MainSliderItem,
  SliderBlock,
  SliderTitle,
  SwiperSliderParent,
  SwiperSliderWrapper
} from "components/welcome/styled";

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export const CarsSlider = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [replaceImg, setReplaceImg] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectUrgentCarData = useSelector(selectUrgentCars);
  const selectUrgentStatus = useSelector(selectUrgentCarsStatus);

  useEffect(() => {
    dispatch(urgentSale(language));
  }, [dispatch, language]);

  useEffect(() => {
    const handleStorageChange = () => {
      setLanguage(localStorage.getItem("lang"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <SwiperSliderWrapper>
      <SliderBlock>
      {selectUrgentStatus === REDUX_STATES.PENDING ? <Loading /> :
        <>
        <SliderTitle>{t("urgent_sale")}</SliderTitle>
        <SwiperSliderParent
          autoplay={{ delay: 5000 }}
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            428: {
              width: 428,
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            768: {
              width: 768,
              slidesPerView: 3,
              slidesPerGroup: 3
            }
          }}
          navigation
        >
          {selectUrgentCarData?.map((car) => (
            <SwiperSlide key={car.id}>
              <MainSliderItem>
                {car?.product_images?.map((element) => (
                  element?.main_image === 1 ?
                    <LazyLoadLink to={`${ROUTENAMES.car}/${car?.car_slug}`} target={"_blank"} key={element.id}>
                      <LazyLoadImage
                        effect="blur"
                        alt='replace image'
                        src={`${IMAGE_STARTING_URL}/${replaceImg === car?.id ? car?.product_images?.[2]?.image : element?.image}`}
                        onMouseEnter={() => setReplaceImg(car.id)}
                        onMouseLeave={() => setReplaceImg(null)}
                      />
                    </LazyLoadLink> : null
                ))}

                <CarNameWithGreen>{car?.product_name?.car_name ? car?.product_name?.car_name : null} {car.product_model.model_name}</CarNameWithGreen>
                <CarYearAndPriceBlock>
                  <CarYearAndPriceText>{car.product_year.car_year}</CarYearAndPriceText>
                  <CarPriceSlideBlock>{car.car_price
                    ? `${NumberWithDots(+car.car_price)} $` :
                    t("with_order")}</CarPriceSlideBlock>
                </CarYearAndPriceBlock>
                <LinkToCar to={`${ROUTENAMES.car}/${car.car_slug}`} target={"_blank"}>
                  {t("see_car")}
                </LinkToCar>
              </MainSliderItem>
            </SwiperSlide>
          ))}
        </SwiperSliderParent>
        </>
      }
      </SliderBlock>
    </SwiperSliderWrapper>
  );
};

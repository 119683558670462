import { CREDENTIALS } from "utils/constants";
import dayjs from "dayjs";
import "dayjs/locale/en";

export const isAuthenticated = localStorage.getItem(CREDENTIALS.ACCESS_TOKEN);
export const language = localStorage.getItem(CREDENTIALS.language);

export const DateFormatter = ({ date }) => {
  const Date = dayjs(date).locale("en").format("YYYY-MM-DD");

  return Date;
};

export const IsSessionExpired = () => {
  const sessionData = JSON.parse(localStorage.getItem("session_data"));
  if (sessionData) {
    const sessionDuration = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds
    const lastInteractionTime = sessionData.timestamp;
    const currentTime = Date.now();

    return currentTime - lastInteractionTime > sessionDuration;
  }
  return true;
}


import styled from "styled-components";
import { COLORS } from "utils/colors";

export const GoBackBlock = styled.div`
  display: ${({isHomePage}) => isHomePage ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 208px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 40;
  background: ${COLORS.main_color};
  border-radius: 100%;
  cursor: pointer;
`;

export const GoBackImage = styled.img`
  width: 34px;
`;

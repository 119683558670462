import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const SparePartsMainPageWrapper = styled.div`
  padding-inline: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${COLORS.white};
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    padding-inline: 60px;
  }

  @media only screen and (max-width: 428px) {
    padding-inline: 30px;
  }
`;

export const Backicon = styled.div`
  position: fixed;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: ${COLORS.main_color} !important;
  cursor: pointer;
  border-radius: 100%;
  border: 4px solid ${COLORS.main_color};
`;

export const SparePartsMainPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  border: 1px solid black;

  @media only screen and (max-width: 768px) {
    gap: unset;
  }
`;

export const MainItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  border-bottom: 2px solid ${COLORS.dark_blue};

  &:hover {
    border-bottom: 2px solid ${COLORS.main_color};
  }

  &:hover span {
    color: ${COLORS.main_color};
  }
`;

export const MainItemTitle = styled.span`
  color: ${COLORS.dark_blue};
  font-size: 24px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  font-weight: 700;
  padding-left: 10px;

  &:hover {
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
`;

export const MainItemAvailableAndNotBlock = styled.div`
  padding: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    padding: 20px;
  }
`;

export const MainItemAvailable = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const MainItemAvailableTitle = styled.div`
  color: ${({ isOrder }) => isOrder ? COLORS.dark_blue : COLORS.main_color};
  font-size: 24px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  font-weight: 700;
  padding-left: 10px;

  @media only screen and (max-width: 1280px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }

  @media only screen and (max-width: 428px) {
    font-size: 16px;
  }
`;

export const MainItemAvailableBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  padding-inline: 10px;
  width: 100%;
  min-height: 120px;
  border-radius: 20px;
  border: 1px solid ${COLORS.dark_blue};
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    gap: 8px;
  }

  @media only screen and (max-width: 1024px) {
    padding-inline: 10px;
    flex-direction: column;
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const ItemImage = styled.img`
  width: 140px;
  object-fit: cover;
  height: 90%;

  @media only screen and (max-width: 1280px) {
    width: 70px;
  }
`;

export const ItemProviderName = styled.span`
  font-size: 24px;
  ${DefaultFont};
  font-weight: 700;
  width: 240px;
  color: ${({ isOrder }) => isOrder ? COLORS.dark_blue : COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-left: 10px;

  &:hover {
    color: ${COLORS.main_color};
  }


  @media only screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }

  @media only screen and (max-width: 428px) {
    font-size: 16px;
    width: auto;
  }
`;

export const ItemId = styled.span`
  font-size: 24px;
  ${DefaultFont};
  font-weight: 700;
  color: ${({ isOrder }) => isOrder ? COLORS.dark_blue : COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-left: 10px;

  @media only screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 16px;
  }
`;

export const ItemPrice = styled.span`
  width: auto;
  overflow: hidden;
  font-size: 24px;
  ${DefaultFont};
  font-weight: 700;
  color: ${({ isOrder }) => isOrder ? COLORS.dark_blue : COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-left: 10px;

  @media only screen and (max-width: 1280px) {
    font-size: 1.5vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  @media only screen and (max-width: 428px) {
    font-size: 16px;
  }
`;

export const OrderNowButton = styled.button`
  position: absolute;
  right: 20px;
  top: 30px;
  width: 160px;
  height: 50px;
  background: transparent;
  color: ${COLORS.dark_blue};
  border: 1px solid ${COLORS.dark_blue};
  border-radius: 4px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.main_color};
    background: ${COLORS.dark_blue};
    border: 1px solid ${COLORS.main_color};
  }

  @media only screen and (max-width: 1280px) {
    width: 130px;
    height: 50px;
  }

  @media only screen and (max-width: 1024px) {
    position: unset;
    width: 100%;
  }
`;

export const shake = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const NoAvailableSparePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px;
`;

export const AvailableSparePartImage = styled.img`
  width: 200px;
  animation: ${shake} 2s ease-in-out infinite;
`;

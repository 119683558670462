import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { carCompareSlug } from "./actions";

const initialState = {
  carCompareStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const CarCompareSlugData = createSlice({
  name: "carCompare/carCompareSlug",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carCompareStatus":
        state.carSlugStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [carCompareSlug.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carCompareStatus = REDUX_STATES.PENDING;
    },
    [carCompareSlug.fulfilled]: (state, action) => {
      const cars = action.payload.cars || [];
      state.data = cars.length > 0 ? cars[0] : null;
      state.is_loading = false;
      state.carCompareStatus = REDUX_STATES.SUCCEEDED;
    },

    [carCompareSlug.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carCompareStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarCompareSlugData.actions;

export default CarCompareSlugData.reducer;

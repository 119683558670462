import { ScrollToTopWrapper } from "components/scrollToTop/styled";

export const ScrollToTop = () => {
  const test = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  };

  return <>
    <ScrollToTopWrapper onClick={test}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.49861 13.3858C3.43339 13.3221 3.38159 13.246 3.34627 13.1619C3.31095 13.0779 3.29283 12.9876 3.29297 12.8964C3.29311 12.8053 3.31151 12.7151 3.34709 12.6311C3.38266 12.5472 3.43469 12.4713 3.50011 12.4078L11.4756 4.63476C11.6157 4.49824 11.8035 4.42184 11.9991 4.42184C12.1947 4.42184 12.3825 4.49824 12.5226 4.63476L20.4981 12.4078C20.5634 12.4714 20.6153 12.5474 20.6508 12.6314C20.6862 12.7154 20.7045 12.8056 20.7045 12.8968C20.7045 12.9879 20.6862 13.0782 20.6508 13.1621C20.6153 13.2461 20.5634 13.3222 20.4981 13.3858C20.3642 13.5166 20.1843 13.5898 19.9971 13.5898C19.8099 13.5898 19.6301 13.5166 19.4961 13.3858L11.9991 6.07626L4.49911 13.3858C4.36523 13.5161
           4.18573 13.5891 3.99886 13.5891C3.81198 13.5891 3.63248 13.5161 3.49861 13.3858ZM3.49861 19.3858C3.43339 19.3221 3.38159 19.246 3.34627 19.1619C3.31095 19.0779 3.29283 18.9876 3.29297 18.8964C3.29311 18.8053 3.31151 18.7151 3.34709 18.6311C3.38266 18.5472 3.43469 18.4713 3.50011 18.4078L11.4756 10.6348C11.6157 10.4982 11.8035 10.4218 11.9991 10.4218C12.1947 10.4218 12.3825 10.4982 12.5226 10.6348L20.4981 18.4078C20.5634 18.4714 20.6153 18.5474 20.6508 18.6314C20.6862 18.7154 20.7045 18.8056 20.7045 18.8968C20.7045 18.9879 20.6862 19.0782 20.6508 19.1621C20.6153 19.2461 20.5634 19.3222 20.4981 19.3858C20.3642 19.5166 20.1843 19.5898 19.9971 19.5898C19.8099 19.5898 19.6301 19.5166 19.4961 19.3858L11.9991 12.0763L4.49911 19.3858C4.36523 19.5161 4.18573 19.5891 3.99886 19.5891C3.81198 19.5891 3.63248 19.5161 3.49861 19.3858Z"
          fill="#FFFFFF"></path>
      </svg>
    </ScrollToTopWrapper>;
  </>
};

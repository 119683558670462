export const ROUTENAMES = {
  welcome: "/",
  contact: "/contact",
  cars: "/cars",
  cars_search: "/cars/:search",
  car: "/car",
  cars_slug: "/car/:slug",
  sign_in: "/eco-motors/sign-in",
  sign_up: "/eco-motors/sign-up",
  blog: "/blog",
  blog_slug: "/blog/:slug",
  profile: "/eco-motors/profile",
  about: "/about",
  location_address: "/location-address",
  saved_cars: "/saved-cars",
  not_found: "*",
  calendar: "/calendar",
  services: "/services",
  services_slug: "/services/:slug",
  car_with_type: "/carType",
  car_with_type_slug: "/carType/:slug",
  reviews: "/reviews",
  see_reviews: "/see-reviews",
  write_reviews: "/write-reviews",
  advertisement: "/advertisement",
  credit_calculator: "/credit-calculator",
  camera_360: "/camera-360-id-4",
  camera_360_ID6: "/camera-360-id-6",
  camera_360_bz_4_x: "/camera-360-bz-4-x",
  camera_360_Honda_Ens1: "/camera-360-honda-ens-1",
  mitsubishi_360: "/camera-360-mitsubishi-airtrek",
  camera_360_EQS: '/camera-360-mercedes-benz-eqs',
  spare_parts_lobby: "/spare-parts-accessories-lobby",
  spare_parts: "/spare-parts-welcome",
  main_spare_parts: "/spare-parts-category",
  car_spare_parts_category_slug: "/spare-parts-category/:id/:modelId",
  car_spare_part_single_page: "/spare-parts-single-page",
  car_spare_part_single_page_slug: "/spare-parts-single-page/:id/:modelId",
  our_addresses: "/our-addresses",
  available_cars: "/available-cars",
  cars_compare: "/compare-cars",
  cars_compare_slug: "/compare-cars/:slug",
  privacy_policy: "/privacy-policy",
  faq: "/faq",
  motorcycles: "/motorcycles",
  active_motorcycles: "/active-motorcycles",
  motorcycles_single_page: "/motorcycle",
  motorcycles_single_page_slug: "/motorcycle/:slug"
};

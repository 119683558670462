import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { writeReviewPost } from "store/writeReview/actions";

const initialState = {
  writeReviewStatus: null,
  errors: null,
  errorMessage: "",
};

export const WriteReview = createSlice({
  name: "writeReview",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "writeReviewStatus":
        state.writeReviewStatus = null;
        break;
      default:
        break;
      }
    },
  },
  extraReducers: {
    [writeReviewPost.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.writeReviewStatus = REDUX_STATES.PENDING;
    },
    [writeReviewPost.fulfilled]: (state, action) => {
      state.writeReviewStatus = REDUX_STATES.SUCCEEDED;
    },
    [writeReviewPost.rejected]: (state, { payload }) => {
      state.writeReviewStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = WriteReview.actions;

export default WriteReview.reducer;

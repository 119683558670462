import { DefaultFont } from "utils/defaultFont.js";
import { COLORS } from "utils/colors.js";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export const ContactUsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;

  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
  z-index: 1;
`;

export const ContactusMainForm = styled.div`
  width: 1092px;
  position: absolute;
  border-radius: 25px;
  padding: 30px;
  z-index: 2;
  height: 500px;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;

  @media only screen and (max-width: 1280px) {
    width: 700px;
    height: 700px;
  }

  @media only screen and (max-width: 768px) {
    width: 400px;
    padding: 10px;
  }

  @media only screen and (max-width: 428px) {
    width: 280px;
  }
`;

export const ContactUsForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 428px) {
    gap: 40px;
  }
`;

export const ContactUsTitle = styled.span`
  ${DefaultFont};
  font-size: 24px;
  color: ${COLORS.white};
  text-transform: capitalize;

  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 450px;

  @media only screen and (max-width: 1280px) {
    justify-content: end;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  width: 480px;
  height: 80px;

  @media only screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const StyledInputTitle = styled.span`
  text-transform: capitalize;
  ${DefaultFont};
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.white};

  @media only screen and (max-width: 428px) {
    font-size: 12px;
  }
`;

export const StyledInput = styled.input`
  outline: unset;
  height: 40px;
  background: transparent;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 18px;
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.white};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  &:focus {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 428px) {
    font-size: 14px;
  }
`;


export const StyledTextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledTextArea = styled.textarea`
  height: 118px;
  padding-left: 8px;
  resize: none;
  outline: unset;
  background: transparent;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  &:focus {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.main_color};
    border-radius: 4px;
  }
`;

export const SendMessageButton = styled.button`
  width: 220px;
  height: 40px;
  outline: none;
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.white};
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  margin-top: 12px;

  &:hover {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 428px) {
    algin-self: unset;
    width: 100%;
  }

`;
export const PhoneNumberBlock = styled(PhoneInput)`
  width: 100% !important;
  height: 40px;
  position: relative;

  .form-control {
    width: 100% !important;
    height: 40px;
    background: transparent;
    border: 2px solid ${COLORS.main_color} !important;
    color: ${COLORS.main_color};
  }

  .flag-dropdown {
    background: ${COLORS.main_color} !important;
  }

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  right: 0;
  top: 8px;
  font-size: 16px;
  line-height: 8px;
  font-weight: 500;
  color: ${COLORS.red};

  @media only screen and (max-width: 428px) {
    font-size: 12px;
    top: 6px;
  }
`;

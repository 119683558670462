import styled from 'styled-components';
import Select from "react-select";
import { COLORS } from "utils/colors";

export const CompareCarsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  
  @media only screen and (max-width: 1280px) {
    gap: 4px;
    padding-inline: 10px;
  }
`;

export const EveryCarBlock = styled.div`
  width: 50%;
  min-height: 800px;
  border-right: ${({borderLeft}) => borderLeft ? `` : `2px solid ${COLORS.black}`};
  border-left: ${({borderLeft}) => borderLeft && `2px solid ${COLORS.black}`};
`;

export const SelectField = styled(Select)`
  width: 100% !important;
  border-radius: 5px;
  border: 1px solid ${COLORS.main_color} !important;
  font-size: 12px;

  span {
    display: none;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
    color: ${COLORS.main_color};
  }

  .css-1dimb5e-singleValue {
    color: ${COLORS.main_color};
  }

  div {
    border-style: unset !important;
    padding: 1px !important;

    &:hover {
      border: unset !important;
      box-shadow: unset !important;
    }
  }
`;

export const SendButton = styled.button`
  width: 50%;
  height: 40px;
  background-color: ${COLORS.main_color};
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 22px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.8s;
  color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 3.2vw;
  }
`;


import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTENAMES } from "routes/routeNames";
import { CarModelsSlider } from "components/carModelsSlider";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {
  LookReviewsBlock,
  LookReviewsText,
  ReviewsMainLeftSide,
  ReviewsMainRightSide,
  ReviewsMainWrapper,
  SeeWriteReviews
} from "containers/reviews/styled";
import { InvisibleH1 } from "../../components/welcome/styled";

export const Reviews = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <>
      <ReviewsMainWrapper>
        <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
          Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
        <SeeWriteReviews>
          <ReviewsMainLeftSide>
            <Link to={ROUTENAMES.see_reviews} target={"_blank"}>
              <LookReviewsBlock>
                <LookReviewsText>{t("see_reviews")}</LookReviewsText>
              </LookReviewsBlock>
            </Link>
          </ReviewsMainLeftSide>
          <ReviewsMainRightSide>
            <Link to={ROUTENAMES.write_reviews} target={"_blank"}>
              <LookReviewsBlock>
                <LookReviewsText>{t("write_review")}</LookReviewsText>
              </LookReviewsBlock>
            </Link>
          </ReviewsMainRightSide>
        </SeeWriteReviews>
      </ReviewsMainWrapper>
      <CarModelsSlider />
    </>
  );
};
import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const SeeReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-height: 500px;
  background: ${COLORS.white};
  padding-inline: 120px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-inline: 50px;
  }
`;

export const ReviewsMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid ${COLORS.main_color};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.white};
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.main_color};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.main_color};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    gap: 10px;
  }
`;


export const ReviewsMainTitle = styled.span`
  font-size: 48px;
  color: ${COLORS.main_color};
  ${DefaultFont};

  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export const ReviewsEveryBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  background: ${COLORS.main_color};
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  padding-inline: 10px;
  
  &:hover {
    height: auto;
  }
  
  &:hover div:nth-child(1){
    border-bottom: 2px solid white;
  }

  &:hover span:nth-child(2){
    display: block;
  }
`;

export const ReviewEveryBlockName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  gap: 30px;
`;

export const ReviewEveryBlockNameText = styled.span`
  font-size: 24px;
  color: ${COLORS.white};
  
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ReviewEveryBlockStarImageWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ReviewEveryBlockStarImage = styled.img`
  width: 16px;
  height: 16px;
`;

export const ReviewEveryBlockDescription = styled.span`
  display: none;
  font-size: 16px;
  color: ${COLORS.white};
  padding: 20px;
  
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCars } from "store/cars/selector";
import { ROUTENAMES } from "routes/routeNames";
import "components/welcome/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { v4 as uuid } from "uuid";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { Loading } from "components/loading";
import { useTranslation } from "react-i18next";
import { NumberWithDots } from "HOC/numberWithDots";
import { selectLikedCars, selectLikedCarsStatus } from "store/likedCars/selector";
import { likedCarsData } from "store/likedCars/actions";
import {
  AnnouncmentsWraper,
  CarDateAndPrice,
  CarMainBlock,
  CarMainBlockWrapper,
  CarMainImage,
  CarName,
  CarNameDateAndePrice,
  CarPriceAndDateText,
  TopAnnouncmentBlock,
  TopAnnouncmentsWrapper,
  TopAnnouncmentText
} from "components/welcome/styled.js";

export const SavedCars = ({ myRef }) => {
  const [language, setLanguage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SelectLikedCars = useSelector(selectLikedCars);
  const SelectLikedCarStatus = useSelector(selectLikedCarsStatus);
  const { t } = useTranslation();

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (SelectLikedCars.length) {
      return selectCars;
    } else {
      dispatch(likedCarsData());
    }
  }, [dispatch, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);
  
  return (
    <TopAnnouncmentsWrapper ref={myRef}>
      <TopAnnouncmentBlock>
        <TopAnnouncmentText>{t("liked_cars")}</TopAnnouncmentText>
        <AnnouncmentsWraper>
          <CarMainBlockWrapper>

            {SelectLikedCarStatus === REDUX_STATES.PENDING ? <Loading /> : SelectLikedCars?.map((element) => (
              <CarMainBlock key={uuid()}
                onClick={() => navigate(`${ROUTENAMES.cars}/${element.product_car_favourite.car_slug}`)}>
                <CarMainImage backgroundImage={element?.product_car_favourite?.product_images?.map((item) => {
                  return (
                    item.main_image ? `${IMAGE_STARTING_URL}/${item?.image}` : null
                  );
                })} />
                <CarNameDateAndePrice>
                  <CarName>{element?.product_car_favourite?.product_name?.car_name} {element?.product_car_favourite?.product_model.model_name}</CarName>
                  <CarDateAndPrice>
                    <CarPriceAndDateText>{element?.product_car_favourite?.car_price ? `${NumberWithDots(element?.product_car_favourite?.car_price)} ֏` : t("with_order")}</CarPriceAndDateText>
                    <CarPriceAndDateText>{element?.product_car_favourite?.product_year.car_year}</CarPriceAndDateText>
                  </CarDateAndPrice>
                </CarNameDateAndePrice>
              </CarMainBlock>
            ))}
          </CarMainBlockWrapper>
        </AnnouncmentsWraper>
      </TopAnnouncmentBlock>
    </TopAnnouncmentsWrapper>
  );
};

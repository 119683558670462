import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCars } from "store/cars/selector";
import { ROUTENAMES } from "routes/routeNames";
import { topCars } from "store/topAnnouncments/actions";
import "components/welcome/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { Loading } from "components/loading";
import { useTranslation } from "react-i18next";
import { selectTopCars, selectTopCarStatus } from "store/topAnnouncments/selector";
import { NumberWithDots } from "HOC/numberWithDots";
import { currencyExchange } from "store/currencyExchange/action";
import { selectCurrencyExchange } from "store/currencyExchange/selector";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  AnnouncmentsWraper, CarAvailableBlock,
  CarDateAndPrice,
  CarMainBlock,
  CarMainBlockWrapper,
  CarName,
  CarNameDateAndePrice, CarNameForWelcome,
  CarPriceAndDateText,
  GoToSeeMoreCars,
  GoToSeeMoreCarsButton, InvisibleH1,
  TopAnnouncmentBlock,
  TopAnnouncmentsWrapper,
  TopAnnouncmentText, TwoButtons
} from "components/welcome/styled.js";

export const TopAnnouncments = ({ myRef }) => {
  const [language, setLanguage] = useState();
  const [priceUsd, setPriceUsd] = useState({});
  const [priceRub, setPriceRub] = useState({});
  const [replaceImg, setReplaceImg] = useState(null);
  const [visibleCars, setVisibleCars] = useState({ start: 0, end: 10 });
  const [containerWidth, setContainerWidth] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SelectTopCarData = useSelector(selectTopCars);
  const { t } = useTranslation();
  const SelectCarDataStatus = useSelector(selectTopCarStatus);
  const currency = useSelector(selectCurrencyExchange);
  const usd = currency.usd?.slice(0, 3);
  const rub = currency.rub?.slice(0, 4);
  const carMainBlockRef = useRef(null);

  const calculateWidth = () => {
    if (containerWidth >= 1280) {
      return containerWidth / 5 - 30;
    } else if (containerWidth >= 768) {
      return containerWidth / 3 - 30;
    } else {
      return containerWidth / 2 - 30;
    }
  };


  const handleChevronLeftClick = () => {
    const newStart = Math.max(0, visibleCars.start - 10);
    const newEnd = Math.max(10, visibleCars.end - 10);
    setVisibleCars({ start: newStart, end: newEnd });
  };

  const handleChevronRightClick = () => {
    const newStart = Math.min(SelectTopCarData.length - 10, visibleCars.start + 10);
    const newEnd = Math.min(SelectTopCarData.length, visibleCars.end + 10);
    setVisibleCars({ start: newStart, end: newEnd });
  };

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (SelectTopCarData.length) {
      return selectCars;
    } else {
      dispatch(topCars({ language }));
    }
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    const carMainBlockWidth = carMainBlockRef.current.getBoundingClientRect().width;
    setContainerWidth(carMainBlockWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const carMainBlockWidth = carMainBlockRef.current.getBoundingClientRect().width;
      setContainerWidth(carMainBlockWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TopAnnouncmentsWrapper ref={myRef}>
      <TopAnnouncmentBlock>
        <TopAnnouncmentText>{t("topAnnouncments")}</TopAnnouncmentText>
        <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
          Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
        <TwoButtons>
          <FaChevronLeft className="chevron" onClick={handleChevronLeftClick} />
          <FaChevronRight className="chevron" onClick={handleChevronRightClick} />
        </TwoButtons>
        <AnnouncmentsWraper>
          <CarMainBlockWrapper ref={carMainBlockRef}>
            {SelectCarDataStatus === REDUX_STATES.PENDING ?
              <Loading /> : SelectTopCarData.slice(visibleCars.start, visibleCars.end).map((element) => (
                <Link className="main__link" to={`${ROUTENAMES.car}/${element.car_slug}`} target={"_blank"}
                      key={element.id}>
                  <CarMainBlock
                    width={calculateWidth()}
                    key={element.car_slug}
                    onMouseEnter={() => {
                      if (priceUsd[element.id]) {
                        setPriceUsd(() => ({
                          [element.id]: (Math.ceil((+element.car_price * usd)))
                        }));
                        setPriceRub(() => ({
                          [element.id]: Math.ceil((+element.car_price * usd / rub))
                        }));
                      } else {
                        setPriceUsd(() => ({
                          [element.id]: Math.ceil((+element.car_price * usd))
                        }));
                        setPriceRub(() => ({
                          [element.id]: Math.ceil((+element.car_price * usd / rub))
                        }));
                      }
                    }}
                    onMouseLeave={() => {
                      setPriceUsd([]);
                      setPriceRub([]);
                    }}>
                    {
                      element?.product_images.map((item) => {
                        return item?.main_image ? <LazyLoadImage
                          key={item.id}
                          effect="blur"
                          alt="main car image"
                          src={item?.main_image ? `${IMAGE_STARTING_URL}/${replaceImg === element.id ? element?.product_images[2]?.image : item?.image}` : null}
                          onMouseEnter={() => setReplaceImg(element.id)}
                          onMouseLeave={() => setReplaceImg(null)}
                        /> : null;
                      })
                    }
                    {element?.car_active ? <CarAvailableBlock>{t("available")}</CarAvailableBlock> : null}
                    <CarNameDateAndePrice>
                      <CarNameForWelcome
                        isWelcome>{element.product_name.car_name} {element.product_model.model_name}</CarNameForWelcome>
                      <CarDateAndPrice>
                        <CarPriceAndDateText>
                          {element.car_price
                            ? `${NumberWithDots(element.car_price)} ${currency.usd_symbol}
                      ${priceUsd[element.id] ? " / " + NumberWithDots(priceUsd[element.id]) + currency.amd_symbol : []} 
                      ${priceRub[element.id] ? " / " + NumberWithDots(priceRub[element.id]) + currency.rub_symbol : []} `
                            : t("with_order")}
                        </CarPriceAndDateText>
                        <CarPriceAndDateText>{element.product_year.car_year}</CarPriceAndDateText>
                      </CarDateAndPrice>
                    </CarNameDateAndePrice>
                  </CarMainBlock>
                </Link>
              ))}
          </CarMainBlockWrapper>
          <GoToSeeMoreCars>
            <GoToSeeMoreCarsButton
              onClick={() => navigate(ROUTENAMES.cars)}>{t("goToSeeMoreCars")}</GoToSeeMoreCarsButton>
          </GoToSeeMoreCars>
        </AnnouncmentsWraper>
      </TopAnnouncmentBlock>
    </TopAnnouncmentsWrapper>
  );
};


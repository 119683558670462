import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useReviewValidation = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    name: yup.string().required(t("error_name")),
    description: yup.string().required(t("this_field_is_required"))
  });
};

import { useDispatch, useSelector } from "react-redux";
import { selelectAuth } from "store/auth/selector";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ROUTENAMES } from "routes/routeNames";
import { useTranslation } from "react-i18next";
import { Images } from "assets";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSignUpSchema } from "validations/signUpValidation";
import { signUp } from "store/auth/actions";
import "containers/signUp/style.css";
import {
  BlackWithOpacity,
  EmailInput,
  ErrorMessage,
  EyeImage,
  Form,
  FormSubmitButton,
  InputAndErrorMessage,
  InputAndEyeIcon,
  RegisterForm,
  RegisterFormWrapper,
  RegisterTitle,
  SignUpWrapper
} from "containers/signUp/styled";

export const SignUp = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(selelectAuth);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(useSignUpSchema(language))
  });
  const onSubmit = (data) => {
    if (data.email && data.password) {
      dispatch(signUp(data));
    }
  };

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate(ROUTENAMES.cars);
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <SignUpWrapper>
      <BlackWithOpacity />
      <RegisterFormWrapper>
        <RegisterForm>
          <RegisterTitle>{t("register")}</RegisterTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputAndErrorMessage>
              <EmailInput placeholder={t("name")} {...register("name")} />
              {errors.name ? (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              ) : null}
            </InputAndErrorMessage>

            <InputAndErrorMessage>
              <EmailInput placeholder={t("email")} {...register("email")} />
              {errors.email ? (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              ) : null}
            </InputAndErrorMessage>

            <InputAndErrorMessage>
              <InputAndEyeIcon className="passwordInput">
                <EmailInput placeholder={t("password")} type={isOpen ? "text" : "password"} {...register("password")} />
                <EyeImage className="seePassword" alt='open eye' src={isOpen ? Images.openedEye : Images.closedEye}
                          onClick={() => setIsOpen(!isOpen)} />
              </InputAndEyeIcon>
              {errors.password ? (
                <ErrorMessage>{errors.password.message}</ErrorMessage>
              ) : null}
            </InputAndErrorMessage>

            <FormSubmitButton>{t("register")}</FormSubmitButton>
          </Form>
        </RegisterForm>
      </RegisterFormWrapper>
    </SignUpWrapper>
  );
};

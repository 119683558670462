import styled from "styled-components";
import { Images } from "assets";
import { COLORS } from "utils/colors";
import PhoneInput from "react-phone-input-2";

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 700px;
  background: url(${Images.electricCarCharging});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 768px) {
    height: 700px;
  }
`;

export const ProfileWrapperOpacity = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
`;

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  margin: 0 auto;
  width: 800px;
  height: 380px;
  border: 3px solid ${COLORS.main_color};
  background: url(${Images.whiteGrayWallpaper});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  padding-right: 10px;

  @media only screen and (max-width: 1280px) {
    width: 730px;
  }

  @media only screen and (max-width: 768px) {
    width: 400px;
    height: auto;
    padding-bottom: 10px;
    padding-right: unset;
  }

  @media only screen and (max-width: 428px) {
    width: 310px;
    height: auto;
  }
`;

export const ProfileBlocks = styled.form`
  display: flex;
  position: relative;
  z-index: 4;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px
  }
`;

export const ProfileLeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

export const UserImagePart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    width: 180px;
    margin-bottom: 20px;
  }
`;

export const UserInput = styled.input`
  position: absolute;
  z-index: 4;
  width: 120px;
  border-radius: 100%;
  height: 120px;
  cursor: pointer;
  opacity: 0;
`;

export const Image = styled.img`
  width: 100%;
  position: absolute;
  z-index: 3;
  border-radius: 100%;
`;

export const UserName = styled.span`
  font-size: 26px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: ${COLORS.main_color};
  font-weight: 500;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ProfileRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 550px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

export const ProfileTitle = styled.span`
  position: relative;
  z-index: 4;
  width: 100%;
  text-align: center;
  font-size: 2.4vw;
  color: ${COLORS.main_color};
  margin-top: 10px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 4.5vw;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 5.5vw;
    margin-bottom: 30px;
  }
`;

export const ProfileMainInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  height: 300px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: unset;
    justify-content: center;
  }
`;

export const ProfileMainInputs = styled.input`
  width: 45%;
  height: 30px;
  border: 2px solid ${COLORS.main_color};
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
  color: ${COLORS.main_color};
  background: transparent;

  &::placeholder {
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const GoToSeeSavedCars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 30px;
  border: 2px solid ${COLORS.main_color};
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
  color: ${COLORS.main_color};
  background: transparent;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${COLORS.main_color};
    color: ${COLORS.white};
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const PhoneNumberBlock = styled(PhoneInput)`
  width: ${({ isWithFull }) => isWithFull ? "100%" : "48%"} !important;
  height: 35px;

  .form-control {
    width: 100% !important;
    background: transparent;
    border: 2px solid ${COLORS.main_color} !important;
    color: ${COLORS.main_color};
  }

  .flag-dropdown {
    background: ${COLORS.main_color} !important;
  }

  @media only screen and (max-width: 768px) {
    width: ${({ isWithFull }) => isWithFull ? "100%" : "93%"} !important;
  }
`;

export const ProfileMainLogOutButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1280px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 70px;
    align-items: center;
  }
`;

export const ProfileMainSaveButton = styled.button`
  width: ${({ isLogOut }) => isLogOut ? "20%" : "70%"};
  height: 30px;
  border-radius: 4px;
  color: ${COLORS.white};
  background: ${COLORS.main_color};
  border: unset;
  outline: unset;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    background: ${COLORS.white};
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 768px) {
    width: 94%;
  }
`;

export const ProfileMainLogOutButton = styled.div`
  width: ${({ isLogOut }) => isLogOut ? "20%" : "70%"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  color: ${COLORS.white};
  background: ${COLORS.main_color};
  border: unset;
  outline: unset;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    background: ${COLORS.white};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 768px) {
    width: 94%;
  }
`;

export const SendCreditButton = styled.button`
  width: 100%;
  height: 40px;
  background: ${COLORS.main_color};
  border: 1px solid ${COLORS.main_color};
  outline: none;
  color: ${COLORS.white};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  font-size: 18px;

  &:hover {
    background: transparent;
    color: ${COLORS.main_color};
  }
`;

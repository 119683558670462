import { createAsyncThunk } from "@reduxjs/toolkit";
import { contactService } from "store/contact/service";

export const contactPost = createAsyncThunk(
  "contact",
  async(data, { rejectWithValue }) => {
    try {
      const response = await contactService.contact(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

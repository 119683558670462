import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const AvailableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    padding-bottom: unset;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 10px;
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  gap: 30px;
  width: 100%;
  
  @media only screen and (max-width: 886px) {
    padding: 10px;
  }

  @media only screen and (max-width: 525px) {
    padding: unset !important;
    padding-top: 10px;
  }
`;

export const ContentTitle = styled.span`
  width: 100%;
  font-size: 38px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  color: ${COLORS.main_color};
`;

export const CarsWrapperAvailable = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1280px) {
    justify-content: center;
    gap: 30px;
  }
  
  @media only screen and (max-width: 886px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  flex: 7;
  
  @media only screen and (max-width: 886px) {
    flex: unset !Important;
  }

  @media only screen and (max-width: 515px) {
    justify-content: unset;
  }

  @media only screen and (max-width: 375px) {
    justify-content: center;
  }
`;

export const CarInfo = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
  padding: 6px;
  box-sizing: border-box;

  a {
    text-decoration: none !important;
  }
  
  @media only screen and (max-width: 768px) {
    height: 160px;
  }
`;

export const CarEveryBlock = styled.div`
  width: 100%;
  height: 200px;
  background: url(${({ background }) => background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px 8px 0 0;
  
  @media only screen and (max-width: 375px){
    height: 120px;
  }
`;

export const LinkToCarButton = styled.button`
  width: 100%;
  height: 30px;
  background: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  ${DefaultFont};
  border: 2px solid ${COLORS.dark_blue};
  cursor: pointer;
  color: ${COLORS.dark_blue};
  border-radius: 30px;
  transition-timing-function: linear;
  transition: 1s;
  display: block;

  &:hover {
    color: ${COLORS.white};
    background: ${COLORS.main_color};
    border: 2px solid ${COLORS.main_color};
    opacity: 0.8;
  }
`;

export const CarWrappingContent = styled.div`
  width: 240px;
  height: 320px;
  border: 1px solid ${COLORS.main_color};
  border-radius: 8px;
  
  @media only screen and (max-width: 768px) {
    width: 30%;
    height: auto;
  }

  @media only screen and (max-width: 515px) {
    width: 48%;
  }
  
  @media only screen and (max-width: 375px) {
    width: 45%;
  }
`;

export const CarEveryTitle = styled.span`
  font-size: 16px;
  color: ${COLORS.dark_blue};
  font-weight: 700;
`;

export const CarEveryPlace = styled.span`
  font-size: 12px;
  height: 20px;
  color: ${COLORS.black};
`;

export const FilterWithPlace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
  min-height: 500px;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  flex: 3;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  @media only screen and (max-width: 886px) {
    flex-direction: row;
    height: auto;
    flex: unset !Important;
    width: 98%;
  }
`;

export const EverySalonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: auto;
`;

export const CheckboxForSalon = styled.input`
  width: 18px;
  height: 18px;
  border: 1px solid ${COLORS.main_color} !important;
  cursor: pointer;
  flex-shrink: 0;
`;

export const ChooseSalonText = styled.span`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: ${COLORS.black};
`;

export const SalonText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.dark_blue};
`;

import axios from "axios";
import { API } from "configs/api";

const token = localStorage.getItem("access_token");

const axiosInstance = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${token}`
    }
  }
});

export const profile = {
  profile_data: (body) => axiosInstance.get(`${API.profile}`, { ...body })
};

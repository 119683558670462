import { createAsyncThunk } from "@reduxjs/toolkit";
import { carTypeService } from "./service";

export const carTypes = createAsyncThunk(
  "carTypes/fetch",
  async(language, { rejectWithValue }) => {
    try {
      const response = await carTypeService.carType(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

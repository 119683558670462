import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { onlySalonData } from "store/onlySalon/actions";

const initialState = {
  onlySalonDataStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const CarOnlySalonData = createSlice({
  name: "salonCarInfo/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "onlySalonDataStatus":
          state.onlySalonDataStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [onlySalonData.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.onlySalonDataStatus = REDUX_STATES.PENDING;
    },
    [onlySalonData.fulfilled]: (state, action) => {
      state.data = action?.payload?.cars;
      state.is_loading = false;
      state.onlySalonDataStatus = REDUX_STATES.SUCCEEDED;
    },
    [onlySalonData.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.onlySalonDataStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarOnlySalonData.actions;

export default CarOnlySalonData.reducer;

import { CarTypeText, CarTypeWrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCarType } from "store/carTypes/selector";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { carTypes } from "store/carTypes/actions";
import { ROUTENAMES } from "routes/routeNames";

export const CarType = () => {
  const selectCarTypeData = useSelector(selectCarType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(localStorage.getItem("lang"));

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(carTypes({ language }));
  }, [dispatch, language]);

  return (
    <CarTypeWrapper>
      {selectCarTypeData.map((element) => {
        return (
            <CarTypeText key={uuid()} onClick={() => navigate(`${ROUTENAMES.car_with_type}/${element.id}`)}>{element.car_body_name}</CarTypeText>
        );
      })}
    </CarTypeWrapper>
  );
};
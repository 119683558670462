import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { Link } from "react-router-dom";

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: ${COLORS.white};
`;

export const LinkForBlock = styled(Link)`
  border-radius: 100%;

  &:hover h1 {
    background: rgba(79, 140, 51, 0.58) none repeat scroll 0 0;
  }
`;


export const ServicesTitleBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 80px;
  overflow: hidden;
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const ServiceAnimatedTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-align: center;
  color: ${COLORS.main_color};
  animation: ${slideIn} 3.5s ease-in-out infinite;

  @media only screen and (max-width: 768px) {
    font-size: 4.5vw;
  }

  @media only screen and (max-width: 428px) {
    font-size: 6.5vw;
  }
`;

export const ServicesMainBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 50px;
  height: auto;
  min-height: 350px;
  gap: 20px;
  background: ${COLORS.white};

  @media only screen and (max-width: 428px) {
    padding: 20px;
  }
`;

export const ServiceBlock = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  border: 4px solid ${COLORS.main_color};
  overflow: hidden;
  pointer-events: none;

  @media only screen and (max-width: 428px) {
    width: 260px;
    height: 260px;
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  span img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
`;

export const BlackWithOpacity = styled.h1`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
  border-radius: unset;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${COLORS.white};
  text-transform: capitalize;
  margin: 0;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: 3.5vw;
  }
`;

export const ServiceTitle = styled.span`
  width: 100%;
  height: 100%;
  position: relative;
  //top: 50%;
  //left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${COLORS.white};
  //z-index: 5;
  text-transform: capitalize;
  //display: none;
  //&:hover {
  //  z-index: 0;
  //}

`;

import React from 'react';
import { HeaderAdvertisementContainer } from "./styled";
import "components/welcome/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Images } from "assets/index";
import {
  SliderAdvertisementImage,
  SliderAdvertisementImageAmeria,
  SliderAdvertisementInecoImage
} from "components/welcome/styled.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/scrollbar';
import './style.css';

export const HeaderAdvertisement = () => {
  return (
    <HeaderAdvertisementContainer>
        <Swiper
          scrollbar={{
            hide: true,
          }}
          autoplay={true}
          modules={[Scrollbar, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <SliderAdvertisementImageAmeria backgroundImage={Images.advertisement} loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <SliderAdvertisementInecoImage backgroundImage={Images.ecoIneco} loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <SliderAdvertisementImage backgroundImage={Images.ecoEvoca} loading="lazy" />
          </SwiperSlide>
        </Swiper>
    </HeaderAdvertisementContainer>
  );
};

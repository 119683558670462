import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import first from "assets/360Images/bz4x/Screenshot 2023-12-21 025709.png";
import second from "assets/360Images/bz4x/Screenshot 2023-12-21 025718.png";
import third from "assets/360Images/bz4x/Screenshot 2023-12-21 025725.png";
import fourth from "assets/360Images/bz4x/Screenshot 2023-12-21 025731.png";
import fifth from "assets/360Images/bz4x/Screenshot 2023-12-21 025740.png";
import sixth from "assets/360Images/bz4x/Screenshot 2023-12-21 025746.png";
import seventh from "assets/360Images/bz4x/Screenshot 2023-12-21 025751.png";
import eight from "assets/360Images/bz4x/Screenshot 2023-12-21 025756.png";
import ninth from "assets/360Images/bz4x/Screenshot 2023-12-21 025802.png";
import tenth from "assets/360Images/bz4x/Screenshot 2023-12-21 025807.png";
import eleventh from "assets/360Images/bz4x/Screenshot 2023-12-21 025814.png";
import tvelth from "assets/360Images/bz4x/Screenshot 2023-12-21 025822.png";
import threeth from "assets/360Images/bz4x/Screenshot 2023-12-21 025827.png";
import fifourth from "assets/360Images/bz4x/Screenshot 2023-12-21 025833.png";
import last from "assets/360Images/bz4x/Screenshot 2023-12-21 025839.png";
import "containers/360cameraBZ4X/style.css";
import { MainImagesBlock, MainImagesWrapper } from "components/camera360/styled";

export const CarCamera360DegreeMercedesToyotaBZ4X = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageUrls = [
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eight,
    ninth,
    tenth,
    eleventh,
    tvelth,
    threeth,
    fifourth,
    last,
    first,
  ];

  useEffect(() => {
    const images = document.querySelectorAll(".card img");
    images[0].classList.add("active");

    if (windowWidth > 768) {
      const spans = document.querySelectorAll(".card span");
      const images = document.querySelectorAll(".card img");
      spans.forEach((span, index) => {
        span.addEventListener("mouseover", () => {
          images.forEach((image) => {
            image.classList.remove("active");
          });

          images[index].classList.add("active");
        });
      });
    } else {
      const card = document.querySelector(".card");
      let touchStartX = 0;
      let touchEndX = 0;
      let activeIndex = 0;

      card.addEventListener("touchstart", handleTouchStart, { passive: true });
      card.addEventListener("touchmove", handleTouchMove, { passive: true });
      card.addEventListener("touchend", handleTouchEnd, { passive: true });

      function handleTouchStart(event) {
        touchStartX = event.touches[0].clientX;
      }

      function handleTouchMove(event) {
        touchEndX = event.touches[0].clientX;
        handleGesture();
      }

      function handleTouchEnd() {
        touchStartX = 0;
        touchEndX = 0;
      }

      function handleGesture() {
        const gestureThreshold = 10;
        if (touchStartX - touchEndX > gestureThreshold) {
          showNextImage();
        } else if (touchEndX - touchStartX > gestureThreshold) {
          showPreviousImage();
        }
      }

      function showNextImage() {
        activeIndex = (activeIndex + 1) % images.length;
        setActiveImage();
      }

      function showPreviousImage() {
        activeIndex = (activeIndex - 1 + images.length) % images.length;
        setActiveImage();
      }

      function setActiveImage() {
        images.forEach((image) => {
          image.classList.remove("active");
        });
        images[activeIndex].classList.add("active");
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <MainImagesWrapper backgroundWhite>
      <MainImagesBlock>{t("hover_on_pictures")}</MainImagesBlock>
      <div className="parent-card">
        <div className="card">
          {imageUrls.map((element, index) => {
            return (
              <div>
                <span style={{ "--i": index }}></span>
                <img src={element} alt="image" />
              </div>
            );
          })}
        </div>
      </div>
    </MainImagesWrapper>
  );
};

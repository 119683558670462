import { createAsyncThunk } from "@reduxjs/toolkit";
import { favouriteCarService } from "store/favourite/service";

export const favouriteCars = createAsyncThunk(
  "favouriteCars/fetch",
  async(body, { rejectWithValue }) => {
    try {
      const response = await favouriteCarService.favourite_cars(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { PrivacyPolicyWrapper } from "containers/privacyPolicy/styled";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <PrivacyPolicyWrapper>
      {t('privacy_policy')}
    </PrivacyPolicyWrapper>
  )
}
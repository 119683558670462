import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { carModels } from "store/carModels/actions";

const initialState = {
  carModelStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const CarModelsData = createSlice({
  name: "carModels",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carModelStatus":
        state.carModelStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [carModels.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carModelStatus = REDUX_STATES.PENDING;
    },
    [carModels.fulfilled]: (state, action) => {
      state.data = action.payload?.carNames;
      state.is_loading = false;
      state.carModelStatus = REDUX_STATES.SUCCEEDED;
    },
    [carModels.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carModelStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarModelsData.actions;

export default CarModelsData.reducer;

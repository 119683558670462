import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";
import { AiOutlineStar } from "react-icons/ai";
import { Images } from "assets";

export const WriteReviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 200px;
  height: 600px;
  background: url(${(Images.ID4electro)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 900px) {
    padding-inline: 2.5vw;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
`;

export const WriteReviewMainBlock = styled.form`
  position: relative;
  z-index: 2;
  padding-inline: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 500px;
  border: 1px solid ${COLORS.main_color};
`;

export const WriteReviewMainTitle = styled.span`
  display: block;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 10px;
`;

export const WriteReviewMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;

  @media only screen and (max-width: 900px) {
    gap: 20px;
  }

  @media only screen and (max-width: 428px) {
    flex-direction: column;
    justify-content: unset;
    flex-wrap: unset;
  }
`;

export const MarkingBox = styled.span`
  font-size: 16px;
  color: ${COLORS.white};
`;

export const WriteReviewRangeInput = styled.div`
  display: flex;
  width: 100%;
`;

export const WriteMainInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const WriteReviewInput = styled.input`
  flex-shrink: 1;
  outline: none;
  width: 100%;
  height: ${({ isDescription }) => isDescription ? "180px" : "40px"};
  border-radius: 4px;
  ${DefaultFont};
  background: transparent;
  color: ${COLORS.white};
  border: 1px solid ${COLORS.main_color};
  @media only screen and (max-width: 428px) {
    width: 100%;
  }

  &::placeholder {
    color: ${COLORS.white};
  }
`;

export const StyledWriteReviewTextArea = styled.textarea`
  width: 100%;
  height: 118px;
  resize: none;
  outline: unset;
  border: unset;
  border-radius: 4px;
  ${DefaultFont};
  background: transparent;
  color: ${COLORS.white};
  border: 1px solid ${COLORS.main_color};

  &::placeholder {
    color: ${COLORS.white};
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.main_color};
    border-radius: 4px;
  }
`;


export const WriteReviewSendButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 50px;
`;

export const WriteSendButton = styled.button`
  width: 180px;
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.main_color};
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    color: ${COLORS.white};
    background: ${COLORS.main_color};
    border: 2px solid ${COLORS.white};
  }
`;

export const FrameStars = styled.div`
  display: flex;
  gap: 10px;
`;
export const SpanForStars = styled.span`
  display: inline;
  font-size: 25px;
  color: ${COLORS.main_color};
  cursor: pointer;
  height: 25px;
`;

export const StarIcon = styled(AiOutlineStar)`
  color: ${({ yellow }) => yellow ? "yellow" : null};
`;

export const ErrorMessage = styled.span`
  top: -14px;
  right: 0;
  position: absolute;
  font-size: 16px;
  line-height: 1px;
  font-weight: 500;
  color: ${COLORS.red};

  @media only screen and (max-width: 428px) {
    font-size: 12px;
    top: 6px;
  }
`;


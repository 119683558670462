import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestSparePartService } from "store/requestSparePart/service";

export const requestSparePartPost = createAsyncThunk(
  "requestSparePart",
  async(data, { rejectWithValue }) => {
    try {
      const response = await requestSparePartService.requestSparePart(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont.js";
import { Images } from "assets";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${COLORS.black};

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    z-index: 9999999999;
    height: auto;
  }
`;

export const HeaderAdvertisementContainer = styled.div`
  width: 100%;
  height: 56px;
  margin-top: 4px;
  margin-bottom: 4px;

  @media only screen and (max-width: 1280px) {
    height: 36px;
  }

  @media only screen and (max-width: 768px) {
    height: 30px;
  }

  .swiper {
    width: 100%;
    height: 56px;
    padding: unset;
    margin: unset;
  }

  .swiper-slide {
    height: auto;
  }

  @media only screen and (max-width: 1280px) {
    .swiper {
      height: 36px;
    }
  }

  @media only screen and (max-width: 768px) {
    .swiper {
      height: 30px;
    }
  }

  @media only screen and (max-width: 428px) {
    .swiper {
      height: 25px;
    }
  }

`;

export const FirstHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 48px;
  background: ${COLORS.black};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const FirstHeaderLeftSide = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 48px;
  background: ${COLORS.black};
`;

export const PhoneIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media only screen and (max-width: 1280px) {
    width: 16px;
    height: 16px;
    display: none;
  }
`;

export const LeftSideBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
`;

export const LeftSideText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.white};
  ${DefaultFont};
  color: ${({ isTextGreen }) => isTextGreen ? COLORS.main_color : COLORS.white};
  font-size: ${({ isTextGreen }) => isTextGreen ? "3.4vh" : "2vh"};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  @media only screen and (max-width: 1280px) {
    font-size: ${({ isTextBig, isTextGreen }) => isTextBig ? "1.2vw" : isTextGreen ? "1.2vw" : "0.8vw"};
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`;


export const LeftSideOrText = styled.span`
  gap: 8px;
  color: ${COLORS.white};
  ${DefaultFont};
  color: ${({ isTextGreen }) => isTextGreen ? COLORS.main_color : COLORS.white};
  font-size: 2.2vh;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    color: ${COLORS.main_color};
    cursor: pointer;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`;

export const RightArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 48px solid transparent;
  border-left: 86px solid ${COLORS.black};
`;

export const LeftArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 48px solid transparent;
  border-right: 86px solid ${COLORS.black};
`;

export const FirstHeaderRightSide = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 48px;
  background: ${COLORS.black};
`;

export const RightSideContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 48px;
`;

export const RightSideText = styled.span`
  color: ${COLORS.white};
  font-size: 1.125vw;
  ${DefaultFont};
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    color: ${COLORS.main_color};
    cursor: pointer;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`;

export const ContainerForHeaderTriangle = styled.div`
  width: 40%;
  display: flex;
`;

export const SecondHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 200px;
  height: 100px;
  background: ${COLORS.white};

  @media only screen and (max-width: 1280px) {
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 400px;
  cursor: pointer;
`;

export const AddressAndTime = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  cursor: ${({ isAddress }) => isAddress ? "pointer" : "unset"};

  @media only screen and (max-width: 1280px) {
    display: none;
  }
  
  .earth__icon {
    font-size: 84px !important;
    object-fit: contain;
    color: ${COLORS.main_color};
  }
  
  .big-size-icon {
    font-size: 84px !important;
    object-fit: contain;
    color: ${COLORS.main_color};
  }
`;
export const AddressAndTimeZone = styled.span`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

export const MainAddress = styled.span`
  font-weight: 600;
  font-size: 12px;
  ${DefaultFont};
`;

export const AddressWithStreet = styled.span`
  font-size: 16px;
  ${DefaultFont};
`;

export const ThirdHeaderWrapper = styled.div`
  position: relative;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${COLORS.white};

  @media only screen and (max-width: 768px) {
    background: ${COLORS.white};
    justify-content: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "space-between" : "center"};
    border-bottom: 1px solid ${COLORS.main_color};
    z-index: 22;
  }
`;

export const LogoSmallSize = styled.img`
  width: 120px;
  cursor: pointer;
  display: none;

  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    display: block;
  }
`;

export const HeaderListsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileHeaderListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: absolute;
  top: 52px;
  background: #000000;
  padding-top: 40px;
  width: 100%;
  height: 100vh;
`;

export const MobileHeaderList = styled.span`
  ${DefaultFont};
  font-size: 2vh;
  color: ${COLORS.white};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderList = styled.span`
  display: flex;
  align-items: center;
  font-size: 1vw;
  ${DefaultFont};
  width: max-content;
  height: 100%;
  cursor: pointer;
  color: ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  text-transform: capitalize;

  @media only screen and (max-width: 1280px) {
    font-size: 1.3vw;
  }
`;

export const StoreIcon = styled.img`
  width: 38px;
  height: 38px;
  cursor: pointer;
`;

export const HamburgerIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
  margin-right: 40px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const SearchMediaIcon = styled.img`
  display: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 80px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const LanguageDropDown = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`;

export const SelectedLanguage = styled.div`
  position: relative;
  width: 30px;
  height: 20px;
  background: url(${({selectedLanguage}) => selectedLanguage === "am" ? Images.armeniaFlag : 
          selectedLanguage === "en" ? Images.usaFlag : selectedLanguage === "ru" ? Images.russianFlag : selectedLanguage === "zh" ? Images.chinaFlag : "red"});
  background-size: 100% 100%;
  cursor: pointer;
`;

export const NotSelectedLanguage = styled.div`
  position: absolute;
  top: ${({selectedLanguage}) => selectedLanguage === "am" ? '30px' :
          selectedLanguage === "en" ? '60px' : selectedLanguage === "ru" ? "90px" : selectedLanguage === "zh" ? "120px" : "150px"};
  width: 30px;
  height: 20px;
  background: url(${({ Image }) => Image});
  background-size: 100% 100%;
  z-index: 2;
  cursor: pointer;
`;

export const LanguageSelected = styled.div`
  width: 30px;
  height: 20px;
  background: url(${({ Image }) => Image});
  background-size: 100% 100%;

  @media only screen and (max-width: 1280px) {
    width: 20px;
    height: 15px;
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 100%;
  padding-inline: 20px;
`;

export const SearchBox = styled.div`
  width: ${({ isHundred }) => isHundred ? "100%" : "180px"};
  position: ${({ isHundred }) => isHundred ? "relative" : "auto"};
  height: 28px;
`;

export const SearchIcon = styled.img`
  position: relative;
  left: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "98%" : "92%"};
  top: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "-28px" : "-26px"};
  background: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "rgba(61, 154, 52, 0.8) none repeat scroll 0 0" : "unset"};
  border-radius: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? " 0 5px 7px 0" : "unset"};
  height: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "28px" : "unset"};
  cursor: pointer;

  @media only screen and (max-width: 580px) {
    left: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "97%" : "92%"};
  }

  @media only screen and (max-width: 450px) {
    left: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? "96%" : "92%"};
  }
`;

export const SearchMobileIcon = styled.img`
  position: absolute;
  right: 26px;
  top: 0;
  background: rgba(61, 154, 52, 0.8) none repeat scroll 0 0;
  height: 28px;
  cursor: pointer;
`;

export const CloseIconParent = styled.div`
  position: absolute;
  top: 0;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: rgba(61, 154, 52, 0.8) none repeat scroll 0 0;
  cursor: pointer;
`;

export const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const HeaderSearchBar = styled.input`
  width: 100%;
  height: 25px;
  background: ${COLORS.main_color};
  border: 1px solid ${COLORS.main_color};
  border-radius: 4px;
  outline: unset;
  background: transparent;
  font-size: 15px;
  padding-left: 10px;
  color: ${COLORS.white};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${COLORS.main_color};
  }

  &:hover {
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }

  &:focus {
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }
`;

export const HeaderMobileSearchBar = styled.input`
  width: 100%;
  height: 25px;
  background: ${COLORS.main_color};
  border: 1px solid ${COLORS.main_color};
  border-radius: 4px;
  outline: unset;
  background: transparent;
  font-size: 15px;
  color: ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${COLORS.main_color};
  }

  &:hover {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  &:focus {
    border: ${({ isMobileSearchOpen }) => isMobileSearchOpen ? `1px solid ${COLORS.white}` : `1px solid ${COLORS.main_color}`};
    color: ${COLORS.main_color};
  }
`;

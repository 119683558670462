import styled from "styled-components";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { COLORS } from "utils/colors";

export const CallToCompanyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 40;
  bottom: 160px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: ${COLORS.main_color};
  border-radius: 100%;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    box-shadow: 10px 3px 1px 0px rgba(65, 194, 24, 0.3);
  }
`;

export const PhoneIcon = styled(BsFillTelephoneOutboundFill)`
  width: 20px !important;
  height: 20px !important;
  color: white;
`;

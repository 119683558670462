import { createAsyncThunk } from "@reduxjs/toolkit";
import { likedCarService } from "./service";

export const likedCarsData = createAsyncThunk(
  "likedCarsData/fetch",
  async(body, { rejectWithValue }) => {
    try {
      const response = await likedCarService.likedCars(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import axios from "axios";
import { API } from "configs/api";

export const blogSlugService = {
  blog_slug: (data) => axios.get(`${API.blog_slug}/${data.slug}?timestamp=${Date.now()}${data.language !== null ? `&lang=${data.language}` : `&lang=`}`, {
    headers: {
      "Cache-Control": "no-store"
    }
  })
};

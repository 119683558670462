import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";

export const WrapperOfScrollLines = styled.section`
  height: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
  gap: 20px;
  
  @media only screen and (max-width: 515px) {
    padding-inline: 10px;
  }
`;

export const MainTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

export const MainTitleText = styled.span`
  font-size: 38px;
  color: ${COLORS.main_color};
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const CarModels = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: 10px;
  display: flex;
  gap: 10px;
  width: 100%;
  height: auto;
  flex: 3;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  @media only screen and (max-width: 1080px) {
    flex: unset;
    min-height: 300px;
    height: auto;
  }
`;

export const CarMainModelsWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
`;

export const CardLineBlock = styled.div`
  width: 100%;
  flex: 7;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  padding-top: unset;
  box-sizing: border-box;
  gap: 10px;

  @media only screen and (max-width: 1080px) {
    flex: unset;
  }
  
  @media only screen and (max-width: 768px) {
    gap: 20px;
    justify-content: center;
  }

  @media only screen and (max-width: 515px) {
    padding: unset;
    gap: 10px;
  }
`;

export const EveryCardBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 250px;
  border-radius: 20px;
  cursor: pointer;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding: 4px;
  
  &:hover {
    background: ${COLORS.main_color};
  }
  
  @media only screen and (max-width: 1280px) {
    width: 30%;
  }

  @media only screen and (max-width: 768px) {
    width: 45%;
    height: 300px;
  }

  @media only screen and (max-width: 515px) {
    width: 45%;
    height: 250px;
  }
`;

export const CardBlockTitle = styled.span`
  font-size: 20px;
  color: ${COLORS.black};
  text-transform: capitalize;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: center;
  align-self: start;
  word-break: break-all;
  line-break: anywhere;
  width: 100%;

  @media only screen and (max-width: 1280px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 515px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const EverySparePartBlockImage = styled.img`
  width: 90%;
  height: 120px;
  
  @media only screen and (max-width: 515px) {
    height: unset !important;
  }
`;

import { useTranslation } from "react-i18next";
import { ROUTENAMES } from "routes/routeNames.js";

export const useHeaderLists = () => {
  const { t } = useTranslation();

  const headerLists = [
    // {
    //   text: t("Home"),
    //   path: ROUTENAMES.welcome
    // },
    {
      text: t("car_available"),
      path: ROUTENAMES.available_cars
    },
    {
      text: t("Cars"),
      path: ROUTENAMES.cars
    },
    {
      text: t('spare_parts'),
      path: ROUTENAMES.main_spare_parts,
    },
    {
      text: t('motorcycles'),
      path: ROUTENAMES.motorcycles,
    },
    {
      text: t('active-motorcycles'),
      path: ROUTENAMES.active_motorcycles,
    }
  ];

  return headerLists;
};

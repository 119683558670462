import styled from "styled-components";
import { COLORS } from "utils/colors";

export const SparePartModal = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 0;
  width: 250px;
  height: 200px;
  background: ${COLORS.dark_blue};
  padding: 30px;
  border-radius: 20px;
  z-index: 5;
  box-sizing: border-box;
  
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SparePartModalNameAndNumber = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 4px;
`;

export const SparePartModalButton = styled.button`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  outline: none;
  padding-left: 4px;
  background: unset;
  cursor: pointer;
  color: ${COLORS.white};
  background: ${COLORS.main_color};
  border: 1px solid ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  border-radius: 8px;
  
  &:hover {
    color: ${COLORS.white};
    background: unset;
  }
`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { onlySalonService } from "store/onlySalon/service";

export const onlySalonData = createAsyncThunk(
  "salonCarInfo/fetch",
  async(number, { rejectWithValue }) => {
    try {
      const response = await onlySalonService.salonCarInfo(number);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ECO_MOTORS_MAIL } from "utils/constants";
import { ROUTENAMES } from "routes/routeNames";
import {
  BlackWithOpacity,
  FooterHref,
  FooterMainContent,
  FooterMainWrapper,
  FooterSection,
  FooterSectionSocialLinks,
  FooterSectionText,
  FooterSectionTextA,
  FooterSectionTextBold
} from "components/footer/styled.js";
import { MainAddress } from "../header/styled";
import { HiOutlinePaperAirplane } from 'react-icons/hi2';
import { useState } from "react";
import axios from "axios";
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

export const Footer = ({ selectWebsiteData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState('');
  const isMobile = window.innerWidth <= 768;
  const test = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  };

  const handleClick = () => {
    window.location.href = `mailto:${ECO_MOTORS_MAIL}`;
  };

  const sendEmail = () => {
    if(state !== "") {
      axios.post('https://www.admin.ecomotors.am/api/mailing-send', {
        email: state
      })
    }
  }

  return (
    <FooterMainWrapper>
      <BlackWithOpacity />
      <FooterMainContent>
        <FooterSection>
          <FooterSectionTextBold
            onClick={() => {
              navigate(ROUTENAMES.welcome);
              test();
            }}
          >
            Eco Motors
          </FooterSectionTextBold>
          <FooterSectionText
            onClick={() => {
              navigate(ROUTENAMES.about);
              test();
            }}
          >
            {t("About")}
          </FooterSectionText>

          <FooterSectionText
            onClick={() => {
              navigate(ROUTENAMES.services);
              test();
            }}
          >
            {t("services")}
          </FooterSectionText>
          <FooterSectionText
            onClick={() => {
              navigate(ROUTENAMES.contact);
              test();
            }}
          >
            {t("Contact")}
          </FooterSectionText>
          <FooterSectionText
            onClick={() => {
              navigate(ROUTENAMES.blog);
              test();
            }}
          >
            {t("Blog")}
          </FooterSectionText>

          <FooterSectionText
            onClick={() => {
              navigate(ROUTENAMES.faq);
              test();
            }}
          >
            {t("faq")}
          </FooterSectionText>

        </FooterSection>

        <FooterSection>
          <FooterSectionTextBold>{t("headOffice")}</FooterSectionTextBold>

            <FooterSectionText
              onClick={() => navigate(ROUTENAMES.our_addresses)}
            >
              {t("our_addresses")}
            </FooterSectionText>

          <FooterHref
            href={`tel:${
              selectWebsiteData?.phone_one
                ? selectWebsiteData?.phone_one
                : "+374-96-94-94-66"
            }`}
          >
            <FooterSectionText isPhone>
              {t("phone")} 1:{" "}
              {selectWebsiteData?.phone_one
                ? selectWebsiteData?.phone_one
                : "+374-96-94-94-66"}
            </FooterSectionText>
          </FooterHref>

          <FooterHref
            href={`tel:${
              selectWebsiteData?.phone_two
                ? selectWebsiteData?.phone_two
                : "+374-96-94-94-66"
            }`}
          >
            <FooterSectionText isPhone>
              {t("phone")} 2:{" "}
              {selectWebsiteData?.phone_two
                ? selectWebsiteData?.phone_two
                : "+374-96-94-94-66"}
            </FooterSectionText>
          </FooterHref>
          {!isMobile ? (
            <FooterSectionText onClick={handleClick} isEmail>
              {t("email")}:{" "}
              {selectWebsiteData?.email
                ? selectWebsiteData?.email
                : "ecomotors.am23@gmail.com"}
            </FooterSectionText>
          ) : (
            <FooterSectionTextA href={`mailto:${ECO_MOTORS_MAIL}`} isEmail>
              {t("email")}:{" "}
              {selectWebsiteData?.email
                ? selectWebsiteData?.email
                : "ecomotors.am23@gmail.com"}
            </FooterSectionTextA>
          )}
          <FooterSectionText>
            {t("officeTime")}
            <br />
            <MainAddress>
              Երկուշաբթիից շաբաթ 10։00 - 20։00
            </MainAddress>
            <br />
            <MainAddress>
              Կիրակի 10։00 - 16։00
            </MainAddress>
            <br />
          </FooterSectionText>
        </FooterSection>

        <FooterSection>
          <FooterSectionTextBold>{t("socialMediaLinks")}</FooterSectionTextBold>
          <FooterSectionSocialLinks>
            <a
              href={selectWebsiteData?.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare className='social__icon__media'/>
            </a>

            <a
              href={selectWebsiteData?.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className='social__icon__media'/>
            </a>

            <a
              href={selectWebsiteData?.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutubeSquare className='social__icon__media'/>
            </a>

            <a
              href={selectWebsiteData?.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <RiInstagramFill className='social__icon__media'/>
            </a>

            <a
              href={selectWebsiteData?.tiktok}
              target="_blank"
              rel="noreferrer"
            >
              <FaTiktok className='social__icon__media'/>
            </a>
          </FooterSectionSocialLinks>
            <div className='container__send_email'>
              <HiOutlinePaperAirplane className='send__email_button' onClick={sendEmail}/>
              <input className='send__email_input' value={state} onChange={(e) => setState(e.target.value)} placeholder='Write your Email'/>
            </div>
        </FooterSection>
      </FooterMainContent>
    </FooterMainWrapper>
  );
};

import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";
import { Images } from "assets";

export const SignUpWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
  background: url(${Images.electricCarCharging});
  background-size: cover;

  @media only screen and (max-width: 768px) {
    background-position: center;
    height: 420px;
  }

  @media only screen and (max-width: 428px) {
    background-position: center;
    height: 360px;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
`;

export const RegisterFormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 850px;
  height: 400px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border: 4px solid ${COLORS.main_color};
  border-radius: 4px;
  z-index: 1;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(92, 245, 13, 0.22);
  }

  @media only screen and (max-width: 1920px) {
    width: 700px;
  }

  @media only screen and (max-width: 1280px) {
    width: 600px;
  }

  @media only screen and (max-width: 768px) {
    width: 410px;
  }

  @media only screen and (max-width: 420px) {
    width: 300px;
  }
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 400px;
`;

export const RegisterTitle = styled.span`
  display: flex;
  justify-content: center;
  ${DefaultFont};
  color: ${COLORS.main_color};
  width: 100%;
  font-size: 25px;
  font-weight: 700;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-inline: 140px;

  @media only screen and (max-width: 1280px) {
    padding-inline: 100px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 60px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 30px;
  }
`;

export const InputAndErrorMessage = styled.div`
  position: relative;
  width: 100%;
`;

export const InputAndEyeIcon = styled.div``;

export const EmailInput = styled.input`
  height: 36px;
  width: 100%;
  background: transparent;
  box-shadow: 0.2rem 0.2rem ${COLORS.main_color}, -0.2rem -0.2rem ${COLORS.main_color};
  border: none;
  outline: none;
  transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
  padding-left: 4px;
  ${DefaultFont};
  color: ${COLORS.main_color};

  &::placeholder {
    color: ${COLORS.main_color};
    font-size: 18px;
  }

  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0.2rem 0.2rem ${COLORS.main_color}, -0.2rem -0.2rem ${COLORS.main_color};
    -webkit-text-fill-color: ${COLORS.main_color} !important;
  }

  &:hover {
    box-shadow: 0.2rem 0.2rem ${COLORS.white}, -0.2rem -0.2rem ${COLORS.white};
    color: ${COLORS.white};

    &::placeholder {
      color: ${COLORS.white};
    }
  }

  &:focus {
    box-shadow: 0.2rem 0.2rem ${COLORS.white}, -0.2rem -0.2rem ${COLORS.white};
    color: white;

    &::placeholder {
      color: ${COLORS.white};
    }
  }
`;

export const EyeImage = styled.img``;

export const ForgetPasswordLine = styled.div`
  height: 36px;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;
`;

export const ForgetPasswordText = styled.span`
  cursor: pointer;
  ${DefaultFont};
  color: ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    color: ${COLORS.white};
  }
`;

export const RegisterWithSocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RegisterWithSocialMediaImagesWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 10px;

  a {
    color: ${COLORS.main_color};
    cursor: pointer;
    transition: 0.2s;
    transition-timing-function: linear;
    transition-duration: 0.3s;

    &:hover {
      color: ${COLORS.white};
    }
  }
`;

export const RegisterWithSocialMediaText = styled.span`
  ${DefaultFont};
  color: ${COLORS.main_color};
  cursor: unset;
  font-size: 20px;

  &:hover {
    color: ${COLORS.white};
  }
`;

export const FormSubmitButton = styled.button`
  width: 100%;
  height: 36px;
  background: ${COLORS.main_color};
  outline: none;
  font-size: 18px;
  ${DefaultFont};
  color: ${COLORS.white};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  border: 4px solid ${COLORS.main_color};

  &:hover {
    background: transparent;
    border: 3px solid ${COLORS.main_color};
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  top: 50px;
  width: 100%;
  font-size: 15px;
  line-height: 8px;
  font-weight: 500;
  color: ${COLORS.red};
`;

import styled from "styled-components";
import { Images } from "assets";
import { COLORS } from "../../utils/colors";
import { DefaultFont } from "../../utils/defaultFont";

export const MainMopedsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  background:  url(${Images.MopedsBackground});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 768px) {
    height: 400px;
    background-position: top;
  }
`;

export const OpacityBlock = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
`;

export const MopedsTitle = styled.span`
  position: absolute;
  font-size: 92px;
  color: ${COLORS.main_color};
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  left: 30px;
  z-index: 3;
  
  @media only screen and (max-width: 914px) {
    font-size: 60px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 30px;    
  }
`;

export const RedColorText = styled.span`
  color: ${COLORS.white};
`;

export const MainMotorCyclesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-inline: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  
  @media only screen and (max-width: 1280px) {
    padding-inline: 50px;
  }


  @media only screen and (max-width: 768px) {
    padding-inline: 10px;
    justify-content: center;
  }
`;

export const EveryMotorCycleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 4px;
  box-sizing: border-box;
  width: 320px;
  height: 360px;
  transition: 0.4s linear;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  border-radius: 8px;
  background: ${COLORS.white};

  @media only screen and (max-width: 768px) {
    width: 160px;
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(31, 231, 0, 0.19);
  }
`;

export const ImageOfMoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;


  @media only screen and (max-width: 768px) {
    height: 160px;
  }
`;

export const MotoName = styled.span`
  font-size: 20px;
  color: ${COLORS.main_color};
  font-weight: 600;
  ${DefaultFont};


  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const MotoPrice = styled.span`
  font-size: 18px;
  color: ${COLORS.dark_blue};
  font-weight: 500;
  text-transform: capitalize;
  ${DefaultFont};

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const MotorcyclePrice = styled.span`
  font-size: 12px;
  color: ${COLORS.dark_blue};
  font-weight: 500;
  text-transform: capitalize;
  ${DefaultFont};
  text-align: center;
  padding-inline: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;


import { useEffect, useRef } from "react";
import { SearchCarsBlock } from "components/welcome/SearchCarsBlock.js";
import { TopAnnouncments } from "components/welcome/topAnnouncments.js";
import { CarsSlider } from "components/welcome/carsSlider";
import { IntroBlock } from "components/welcome/introBlock";

export const Welcome = () => {
  const myRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <>
      {window.innerWidth > 768 ? <SearchCarsBlock /> : null}
      <TopAnnouncments myRef={myRef} />
      <CarsSlider />
      <IntroBlock />
      {/*<SeeCar360 />*/}
      {/*<Devices />*/}
    </>
  );
};

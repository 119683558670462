import { useEffect, useRef, useState } from "react";

export const useCloseOnClickOutside = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const secondRef = useRef(null);
  const [secondIsOpen, setSecondIsOpen] = useState(false);
  const thirdRef = useRef(null);
  const [thirdIsOpen, setThirdIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }

    if (secondRef.current && !secondRef.current.contains(event.target)) {
      setSecondIsOpen(false);
    }

    if (thirdRef.current && !thirdRef.current.contains(event.target)) {
      setThirdIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { ref, isOpen, setIsOpen, secondRef, secondIsOpen, setSecondIsOpen, thirdRef, thirdIsOpen, setThirdIsOpen };
};

import React, { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import { writeReviewPost } from "store/writeReview/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectWriteReview } from "store/writeReview/selector";
import { useReviewValidation } from "validations/reviewValidation";
import {
  BlackWithOpacity,
  ErrorMessage,
  FrameStars,
  MarkingBox,
  SpanForStars,
  StarIcon,
  StyledWriteReviewTextArea,
  WriteMainInputWrapper,
  WriteReviewContainer,
  WriteReviewInput,
  WriteReviewMainBlock,
  WriteReviewMainTitle,
  WriteReviewMainWrapper,
  WriteReviewRangeInput,
  WriteReviewSendButton,
  WriteSendButton
} from "containers/writeReview/styled";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "routes/routeNames";
import { REDUX_STATES } from "utils/constants";

export const WriteReview = () => {
  const [hoverIndex, setHoverINdex] = useState(0);
  const [rating, setRating] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(useReviewValidation())
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectWriteReviewStatus = useSelector(selectWriteReview);
  const starIndexses = [1, 2, 3, 4, 5];

  useEffect(() => {
    if(selectWriteReviewStatus === REDUX_STATES.SUCCEEDED) {
      navigate(ROUTENAMES.welcome);
    }
  }, [selectWriteReviewStatus])

  const handleClick = (data) => {
    dispatch(writeReviewPost({
      name_person: data.name,
      description: data.description,
      rating: rating
    }));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <WriteReviewContainer>
      <BlackWithOpacity />
      <WriteReviewMainBlock onSubmit={handleSubmit(handleClick)}>
        <WriteReviewMainTitle>
          {t("write_review")}
        </WriteReviewMainTitle>
        <WriteReviewMainWrapper>
          <WriteMainInputWrapper>
            <WriteReviewInput placeholder={t("name")} {...register("name")} />
            {errors.name ? (
              <ErrorMessage>{errors.name.message}</ErrorMessage>
            ) : null}
          </WriteMainInputWrapper>
          <WriteMainInputWrapper>
            <StyledWriteReviewTextArea placeholder={t("description")} {...register("description")} />
            {errors.description ? (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            ) : null}
          </WriteMainInputWrapper>
          <WriteReviewRangeInput>
            <MarkingBox>{t("scale")} - {rating}</MarkingBox>
          </WriteReviewRangeInput>
          <FrameStars>
            {starIndexses.map((e) => {
              return (
                <SpanForStars key={uuid()}
                  onMouseEnter={() => setHoverINdex(e)}
                  onMouseLeave={() => setHoverINdex(0)}
                  onClick={() => setRating(e)}>
                  <StarIcon yellow={e <= hoverIndex || e <= rating} />
                </SpanForStars>
              );
            })}
          </FrameStars>
        </WriteReviewMainWrapper>
        <WriteReviewSendButton>
          <WriteSendButton>{t("send_message")}</WriteSendButton>
        </WriteReviewSendButton>
      </WriteReviewMainBlock>
    </WriteReviewContainer>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditWithBank } from "store/creditWithBank/actions";
import { selectCarWithCredit } from "store/creditWithBank/selector";
import { NumberWithDots, NumberWithoutDots } from "HOC/numberWithDots";
import { PhoneNumberBlock, SendCreditButton } from "containers/profile/styled";
import { Images } from "assets";
import { creditWithCardPost } from "store/creditWithCardPost/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreditValidation } from "validations/creditValidation";
import { useTranslation } from "react-i18next";
import {
  CloseIcon,
  CreditMainInput,
  CreditMainModal,
  CreditMainModalLeftSide,
  CreditMainModalRightSide,
  CreditMainText,
  CreditModalWrapper,
  EcoMotorsLogo,
  ErrorMessage,
  FormInput,
  PaymentPerMonth,
  PerMonthText,
  PriceText,
  RangeInput,
  RangeInputValue,
  RangeInputWrapper
} from "modals/styled";
import { RangeInputBlock, RangeInputBlockWrapper } from "containers/CreditField/styled";

export const CreditModal = ({ firstRef, secondIsOpen, setSecondIsOpen }) => {
  const dispatch = useDispatch();
  const selectCreditWithBankValue = useSelector(selectCarWithCredit);
  const { t } = useTranslation();

  const [creditValue, setCreditValue] = useState(selectCreditWithBankValue.credit_price_min);
  const [timeValue, setTimeValue] = useState(selectCreditWithBankValue.credit_time_min);
  const [phoneValue, setPhoneValue] = useState("");
  const pricePercent = selectCreditWithBankValue.credit_percent;
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(useCreditValidation())
  });

  function handleChange(event, text) {
    if (text === "credit_value") {
      setCreditValue(event.target.value);
    } else {
      setTimeValue(event.target.value);
    }
  }

  const onSubmit = (data) => {
    if (creditValue > selectCreditWithBankValue?.credit_price_max) {
      setCreditValue(selectCreditWithBankValue?.credit_price_max);
    } else {
      dispatch(creditWithCardPost({
        credit_first_name: data.name_person,
        credit_last_name: data.surName_person,
        credit_phone: data.phone_number,
        credit_price: creditValue,
        credit_time: timeValue,
        credit_price_mount: pricePerMonth
      }));
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
  };

  const changeCreditValue = (e) => {
    setCreditValue(NumberWithoutDots(e.target.value));
  };


  useEffect(() => {
    dispatch(creditWithBank());
  }, [dispatch]);

  useEffect(() => {
    setCreditValue(selectCreditWithBankValue.credit_price_min);
    setTimeValue(selectCreditWithBankValue.credit_time_min);
  }, [selectCreditWithBankValue]);

  useEffect(() => {
    setPricePerMonth(Math.round((+creditValue + (+creditValue * parseFloat(pricePercent) / 100)) / +timeValue));
  }, [timeValue, creditValue]);

  useEffect(() => {
    if (secondIsOpen) {
      document.body.style.overflow = "hidden";
    }
  }, [secondIsOpen]);

  return (
    <CreditModalWrapper onSubmit={handleSubmit(onSubmit)}>
      <CreditMainModal ref={firstRef}>
        <CloseIcon src={Images.closeIconBlack} alt='close icon' onClick={() => setSecondIsOpen(false)} />
        <CreditMainModalLeftSide>
          <EcoMotorsLogo />
          <RangeInputWrapper>
            <RangeInputBlockWrapper>
              <RangeInputBlock
                type="text"
                value={NumberWithDots(creditValue)}
                onChange={changeCreditValue}
                pattern="[0-9,]*"
                inputMode="numeric"
                minLength={selectCreditWithBankValue?.credit_price_min?.length || 0}
                maxLength={selectCreditWithBankValue?.credit_price_max?.length + 2 || Infinity}
              />
              <RangeInputValue>դրամ</RangeInputValue>
            </RangeInputBlockWrapper>
            <RangeInput type="range" value={creditValue} min={selectCreditWithBankValue.credit_price_min}
                        max={selectCreditWithBankValue.credit_price_max}
                        onChange={(e) => handleChange(e, "credit_value")} />
          </RangeInputWrapper>
          <RangeInputWrapper>
            <RangeInputValue>{timeValue} ամիս</RangeInputValue>
            <RangeInput type="range" value={timeValue} min={selectCreditWithBankValue.credit_time_min}
                        max={selectCreditWithBankValue.credit_time_max}
                        onChange={(e) => handleChange(e, "time_value")} />
          </RangeInputWrapper>
          <PaymentPerMonth>
            <PerMonthText>Ամսական Վճար</PerMonthText>
            <PriceText>{NumberWithDots(pricePerMonth) + " ֏"} </PriceText>
          </PaymentPerMonth>
        </CreditMainModalLeftSide>
        <CreditMainModalRightSide>
          <CreditMainText>
            *Հետվճարը հաշվարկված է առավելագույն արժեքով, մնացած ամիսների համար գումարը նվազում է:
            <br />
            <br />
            <br />
            *Հետվճարում հաշվարկված է ամսական սպասարկման վճարի առավելագույն արժեքը։
          </CreditMainText>
          <FormInput>
            <CreditMainInput placeholder={t("name_text")} {...register("name_person")} />
            <ErrorMessage>{errors?.name_person?.message}</ErrorMessage>
          </FormInput>
          <FormInput>
            <CreditMainInput placeholder={t("surname_text")} {...register("surName_person")} />
            <ErrorMessage>{errors?.surName_person?.message}</ErrorMessage>
          </FormInput>
          <PhoneNumberBlock
            isWithFull
            country="am"
            value={phoneValue}
            onChange={handlePhoneChange}
          />
          <SendCreditButton>Դիմել Հիմա</SendCreditButton>
        </CreditMainModalRightSide>
      </CreditMainModal>
    </CreditModalWrapper>
  );
};
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CarSpecialPageSlider } from "components/cars/carSpecialPageSlider.js";
import { useDispatch, useSelector } from "react-redux";
import { selectCarSlug, selectCarStatus } from "store/carSinglePage/selector";
import { Images } from "assets";
import { Loading } from "components/loading";
import { selectWebsiteMainData } from "store/websiteMainData/selector";
import { websiteMainData } from "store/websiteMainData/actions";
import { useTranslation } from "react-i18next";
import { useCloseOnClickOutside } from "hooks/useCloseOnClickOutside";
import { currencyExchange } from "store/currencyExchange/action";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { CreditModal } from "modals/creditModal";
import { resetStatus } from "store/favourite/slice";
import {
  AboutCarBlock,
  AboutCarKey,
  AboutCarValue,
  CallNumber,
  CallNumberHref,
  CallSellerAndInfoAboutSeller,
  CallToSeller,
  CarCharacteristics,
  CarPageContent,
  CarPageLeftSide,
  CarPageRightSide,
  CarPageWrapper, CarRightSideTitle, CarRightSideTitleH1,
  CarsWithOrderText, CarTitleAndAddFavorite,
  PhoneImage
} from "containers/carPage/styled.js";
import { mopedSlug } from "../../store/mopedSingleInfo/actions";
import { selectMopedData } from "../../store/mopedSingleInfo/selector";
import { NumberWithDots } from "../../HOC/numberWithDots";
import { About } from "../about";

export const MopedsSinglePage = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
  const carSelectedData = useSelector(selectMopedData);
  const carSelectedStatus = useSelector(selectCarStatus);
  const selectWebsiteData = useSelector(selectWebsiteMainData);
  const { secondRef, secondIsOpen, setSecondIsOpen } = useCloseOnClickOutside();
  const { thirdRef, thirdIsOpen, setThirdIsOpen } = useCloseOnClickOutside();

  const handleCreditModal = () => {
    setSecondIsOpen(!secondIsOpen);
  };

  console.log(carSelectedData, 'sdsadasd moped');

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    if (!secondIsOpen) {
      document.body.style.overflow = "unset";
    }
  });

  useEffect(() => {
    dispatch(websiteMainData(language));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(mopedSlug(slug));
  }, [dispatch, language, location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    dispatch(resetStatus());
  }, [slug]);

  useEffect(() => {
    const title = carSelectedData?.product_name?.car_name + " " + carSelectedData?.product_model?.model_name;
    const imageUrl = `${IMAGE_STARTING_URL}/${carSelectedData?.product_images?.[0]?.image}`;

    const setMetaTags = () => {
      const metaTags = [
        { property: "og:title", content: title },
        { property: "og:image", content: imageUrl }
      ];

      metaTags.forEach((tag) => {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("property", tag.property);
        metaTag.setAttribute("content", tag.content);
        document.head.appendChild(metaTag);
      });
    };

    setMetaTags();

    return () => {
      const metaTags = document.querySelectorAll("meta[property^=\"og:\"]");
      metaTags.forEach((tag) => {
        document.head.removeChild(tag);
      });
    };
  }, [carSelectedData]);

  return (
    <>
      <CarPageWrapper>
        {carSelectedData?.length === 0 ||
        carSelectedStatus === REDUX_STATES.PENDING ? (
          <Loading />
        ) : (
          <CarPageContent>
            {secondIsOpen ? (
              <CreditModal
                firstRef={secondRef}
                secondIsOpen={secondIsOpen}
                setSecondIsOpen={setSecondIsOpen}
              />
            ) : null}
            <CarPageLeftSide>
              <CarSpecialPageSlider
                setThirdIsOpen={setThirdIsOpen}
                thirdIsOpen={thirdIsOpen}
                carData={carSelectedData}
                saveCarAsFavourite={() => {}}
              />
              <CallSellerAndInfoAboutSeller>
                <CallToSeller>
                  <CallNumberHref
                    href={`tel:${
                      selectWebsiteData?.phone_one
                        ? selectWebsiteData?.phone_one
                        : "+374-96-94-94-66"
                    }`}
                  >
                    <CallNumber>
                      <PhoneImage src={Images.phoneIcon} alt='phone icon'/>
                      {selectWebsiteData?.phone_one
                        ? selectWebsiteData?.phone_one
                        : "+374-96-94-94-66"}{" "}
                    </CallNumber>
                  </CallNumberHref>

                  <CallNumberHref
                    href={`tel:${
                      selectWebsiteData?.phone_two
                        ? selectWebsiteData?.phone_two
                        : "+374-96-94-94-66"
                    }`}
                  >
                    <CallNumber>
                      <PhoneImage src={Images.phoneIcon} alt='phone icon'/>
                      {selectWebsiteData?.phone_two
                        ? selectWebsiteData?.phone_two
                        : "+374-96-94-94-66"}{" "}
                    </CallNumber>
                  </CallNumberHref>
                </CallToSeller>
                <CallToSeller>
                  <CarsWithOrderText isWithCredit onClick={handleCreditModal}>
                    {t("buy_with_credit")}
                  </CarsWithOrderText>
                </CallToSeller>
              </CallSellerAndInfoAboutSeller>
            </CarPageLeftSide>

            <CarPageRightSide>
              <CarCharacteristics>

                <AboutCarBlock>
                  <AboutCarKey is_car_additional>{`${t("car_model")}/${t("car_name")}`}</AboutCarKey>
                  <AboutCarValue is_car_additional isTextBig>{carSelectedData?.product_name?.motorcycle_name}{" "}
                    {carSelectedData?.product_model?.motorcycle_model}</AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_price")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_price} $
                  </AboutCarValue>
                </AboutCarBlock>


                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_power")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_power}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_battery")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_battery}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_wheel_front")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_wheel_front}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_wheel_rear")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_wheel_rear}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_max_weight")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_max_weight}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_weight")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_weight}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_max_speed")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_max_speed}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_front_and_rear_disc_brakes")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_front_and_rear_disc_brakes}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_controller")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_controller}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_light")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_light}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_absorbing_front_rear")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_absorbing_front_rear}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_charging_time")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_charging_time}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_total_size")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_total_size}
                  </AboutCarValue>
                </AboutCarBlock>

                <AboutCarBlock>
                  <AboutCarKey>
                    {t("motorcycle_cruising_range")}
                  </AboutCarKey>
                  <AboutCarValue>
                    {carSelectedData?.motorcycle_cruising_range}
                  </AboutCarValue>
                </AboutCarBlock>
              </CarCharacteristics>
            </CarPageRightSide>
          </CarPageContent>
        )}
      </CarPageWrapper>
    </>
  );
};

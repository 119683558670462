import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { urgentSale } from "store/urgentSale/actions";

const initialState = {
  urgentSaleStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const urgentSaleData = createSlice({
  name: "urgentSale",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "urgentSaleStatus":
        state.urgentSaleStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [urgentSale.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.urgentSaleStatus = REDUX_STATES.PENDING;
    },
    [urgentSale.fulfilled]: (state, action) => {
      state.data = action?.payload?.cars;
      state.is_loading = false;
      state.urgentSaleStatus = REDUX_STATES.SUCCEEDED;
    },
    [urgentSale.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.urgentSaleStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = urgentSaleData.actions;

export default urgentSaleData.reducer;

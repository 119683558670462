import { createAsyncThunk } from "@reduxjs/toolkit";
import { spareInfoService } from "store/sparePartsInfo/service";

export const spareParts = createAsyncThunk(
  "sparePartsInfo/fetch",
  async(language, { rejectWithValue }) => {
    try {
      const response = await spareInfoService.spareParts(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

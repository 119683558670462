import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sparePartsWithModelID } from "store/sparePartsProductWithModelId/actions";
import { SparePartModal } from "spareParts/components/sparePartModal";
import { Images } from "assets";
import { Subheading } from "containers/notFound/styled";
import { REDUX_STATES } from "utils/constants";
import { IoIosArrowBack } from "react-icons/io";
import {
  selectSparePartStatus,
  selectSparePartWithModelId
} from "store/sparePartsProductWithModelId/selector";
import { resetStatus } from "../../../store/sparePartsProductWithModelId/slice";
import {
  MainItemTitle,
  MainItemTitleWrapper,
  SparePartsMainPageContainer,
  SparePartsMainPageWrapper,
  MainItemAvailableAndNotBlock,
  MainItemAvailable, NoAvailableSparePart, AvailableSparePartImage, Backicon
} from "spareParts/containers/sparePartSinglePage/styled";
import { useTranslation } from "react-i18next";

export const SparePartSinglePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectSparePartId = useSelector(selectSparePartWithModelId);
  const selectSparePartDataStatus = useSelector(selectSparePartStatus);
  const pathSegments = location.pathname.split("/");
  const idSegment = pathSegments.find(segment => segment.startsWith("id="));
  const modelSegment = pathSegments.find(segment => segment.startsWith("model="));
  const id = idSegment ? idSegment.split("=")[1] : null;
  const model = modelSegment ? modelSegment.split("=")[1] : null;

  useEffect(() => {
    dispatch(sparePartsWithModelID({
      model,
      carModelId: id
    }));
  }, [dispatch, id, model]);

  useEffect(() => {
    dispatch(resetStatus());
  }, [dispatch]);

  return (
    <SparePartsMainPageWrapper>
      <Backicon onClick={() => navigate(-1)}>
        <IoIosArrowBack />
      </Backicon>
      {selectSparePartId?.length ? <SparePartsMainPageContainer>
        <MainItemTitleWrapper>
          <MainItemTitle>{selectSparePartId[0]?.car_spare_part_category_id?.[0]?.name_spare_part_category}</MainItemTitle>
        </MainItemTitleWrapper>
        <MainItemAvailableAndNotBlock>
          <MainItemAvailable>
            {selectSparePartId?.map((sparePart, id) => {
                return (
                  <SparePartModal sparePart={sparePart} id={id} model={model} />
                );
              }
            )}
          </MainItemAvailable>
        </MainItemAvailableAndNotBlock>
      </SparePartsMainPageContainer> : selectSparePartId?.length === 0 && selectSparePartDataStatus === REDUX_STATES.SUCCEEDED ? <NoAvailableSparePart>
        <AvailableSparePartImage src={Images.spareParts} alt='Spare Part Image'/>
        <Subheading>{t('not_found_spare_part')}</Subheading>
      </NoAvailableSparePart> : null}
    </SparePartsMainPageWrapper>
  );
};

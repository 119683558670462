import { createSlice } from "@reduxjs/toolkit";
import { cars } from "store/cars/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  carStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const CarData = createSlice({
  name: "cars/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carStatus":
        state.carStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [cars.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.carStatus = REDUX_STATES.PENDING;
    },
    [cars.fulfilled]: (state, action) => {
      state.data = action?.payload?.cars;
      state.carStatus = REDUX_STATES.SUCCEEDED;
    },
    [cars.rejected]: (state, { payload }) => {
      state.carStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarData.actions;

export default CarData.reducer;

import React from "react";
import { Navigate } from "react-router-dom";
import { CREDENTIALS } from "utils/constants";
import { ROUTENAMES } from "./routeNames";

export const PrivateRoute = ({ Component }) => {
  const isAuthenticated = localStorage.getItem(CREDENTIALS.ACCESS_TOKEN);

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={ROUTENAMES.sign_in} replace />
  );
};

import axios from "axios";
import { API } from "configs/api";

const token = localStorage.getItem("access_token");

const axiosInstance = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${token}`
    }
  }
});

export const likedCarService = {
  likedCars: () => axiosInstance.get(`${API.favourite_cars}`)
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { carWithTypeSlugService } from "./service";

export const carWithTypeSlug = createAsyncThunk(
  "carWithTypeSlug/fetch",
  async(data, { rejectWithValue }) => {
    try {
      const response = await carWithTypeSlugService.carWithTypeSlug(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";

export const OurAddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 10px;
  padding-bottom: 30px;
  box-sizing: border-box;
  width: 100%;
`;

export const OurAddressesTitle = styled.span`
  width: 100%;
  height: 70px;
  font-size: 40px;
  font-weight: bold;
  ${DefaultFont};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.main_color};

  @media only screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const AddressesBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: auto;
  padding-inline: 120px;
  box-sizing: border-box;
  
  @media only screen and (max-width: 1280px) {
    padding-inline: 60px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
  }
`;

export const EveryAddressContainer = styled.div`
  width: 40%;
  height: 400px;
  border: 2px solid ${COLORS.main_color};
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const EveryAddressTitle = styled.span`
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  color: ${COLORS.black};
  ${DefaultFont};
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 350px;
  border: 0;
`;

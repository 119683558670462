import styled from "styled-components";
import { Images } from "assets";
import { DefaultFont } from "utils/defaultFont.js";
import { COLORS } from "utils/colors.js";

export const FooterMainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-inline: 200px;
  min-height: 400px;
  background: url(${Images.ID6Wallpaper});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
    height: auto;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
`;

export const FooterMainContent = styled.div`
  position: static;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
    gap: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 260px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }


  .container__send_email {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    border: 2px solid ${COLORS.main_color};
    border-radius: 30px;
    transition: 0.6s linear;
  }

  .send__email_input {
    width: 100%;
    padding-left: 10px;
    margin-left: 4px;
    margin-right: 4px;
    height: 24px;
    outline: none;
    border: none;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    
    &::placeholder {
      font-size: 13px;
    }
  }

  .send__email_button {
    position: absolute;
    right: 8px;
    z-index: 8;
    top: 4px;
    font-size: 24px;
    border-radius: 10px;
    transition: 0.6s linear;

    &:hover {
      transform: rotate(-360deg);
      cursor: pointer;
    }
  }
`;

export const FooterInputEmail = styled.input`
  width: 100%;
  height: 20px;
`;

export const FooterHref = styled.a`
  text-decoration: none;

  &:last-child {
    float: right;
  }
`;

export const PhoneHrefParent = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const FooterSectionText = styled.span`
  color: ${COLORS.white};
  ${DefaultFont};
  font-size: ${({ isPhone }) => (isPhone ? "16px" : "18px")};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;
  text-decoration: none;
  text-transform: ${({isEmail}) => isEmail ? 'unset' : 'capitalize'};

  &:hover {
    color: ${COLORS.main_color};
  }
`;

export const FooterSectionTextA = styled.a`
  color: ${COLORS.white};
  ${DefaultFont};
  font-size: ${({ isPhone }) => (isPhone ? "16px" : "18px")};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${COLORS.main_color};
  }
`;

export const FooterSectionTextBold = styled.span`
  color: ${COLORS.white};
  ${DefaultFont};
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 4px solid ${COLORS.main_color};
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    color: ${COLORS.main_color};
  }
`;

export const FooterSectionSocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.white};
    cursor: pointer;
    transition: 0.2s;
    transition-timing-function: linear;
    transition-duration: 0.3s;
  }
  
  .social__icon__media {
    font-size: 20px;
    transition: 0.4s linear;


    &:hover {
      color: ${COLORS.main_color};
    }
  }
`;

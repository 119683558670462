import { createSlice } from "@reduxjs/toolkit";
import { serviceSlug } from "store/serviceSinglePage/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  serviceSlugStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};

export const ServiceSlugData = createSlice({
  name: "service/serviceSlug",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "serviceSlugStatus":
          state.serviceSlugStatus = null;
          break;
        default:
          break;
      }
    },
  },

  extraReducers: {
    [serviceSlug.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.serviceSlugStatus = REDUX_STATES.PENDING;
    },
    [serviceSlug.fulfilled]: (state, action) => {
      state.data = action.payload?.services;
      state.is_loading = false;
      state.serviceSlugStatus = REDUX_STATES.SUCCEEDED;
    },
    [serviceSlug.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.serviceSlugStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = ServiceSlugData.actions;

export default ServiceSlugData.reducer;

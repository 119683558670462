import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectCars } from "store/cars/selector";
import { Images } from "assets/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { BlackWithOpacity, CarListWrapper, CarsAmount, CarsTitle } from "components/cars/styled.js";
import { InvisibleH1 } from "../welcome/styled";
import React from "react";

export const Carsheader = ({ titleText }) => {
  const { t } = useTranslation();
  const SelectCarData = useSelector(selectCars);
  return (
    <CarListWrapper>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
      <LazyLoadImage
        effect="opacity"
        alt='lazy charger image'
        src={`${Images.carCharger}`} />
      <BlackWithOpacity />
      <CarsTitle>
        {titleText}
        <CarsAmount>
          {SelectCarData.length ? `(${t("all_cars")} ${SelectCarData.length})` : null}
        </CarsAmount>
      </CarsTitle>
    </CarListWrapper>
  );
};

export const API = {
  signIn: "https://www.admin.ecomotors.am/api/login",
  signUp: "https://www.admin.ecomotors.am/api/register",
  contact: "https://www.admin.ecomotors.am/api/feedback",
  blog: "https://www.admin.ecomotors.am/api/blog",
  blog_slug: "https://www.admin.ecomotors.am/api/blog-details",
  cars: "https://www.admin.ecomotors.am/api/cars",
  car_slug: "https://www.admin.ecomotors.am/api/car-details",
  moped_slug: "https://www.admin.ecomotors.am/api/motorcycle-details",
  car_models: "https://www.admin.ecomotors.am/api/car-names",
  car_names: "https://www.admin.ecomotors.am/api/car-models",
  website_main_data: "https://www.admin.ecomotors.am/api/contact-us",
  about_us: "https://www.admin.ecomotors.am/api/about-us",
  top_cars: "https://www.admin.ecomotors.am/api/car-top",
  favourite_cars: "https://www.admin.ecomotors.am/api/user/favourites",
  profile: "https://www.admin.ecomotors.am/api/user/profile",
  currency_exchange: "https://www.admin.ecomotors.am/api/currency-exchange-value",
  banners: "https://www.admin.ecomotors.am/api/banners",
  country: "https://www.admin.ecomotors.am/api/country",
  car_filter: "https://www.admin.ecomotors.am/api/country-filter",
  car_articulate: "https://www.admin.ecomotors.am/api/search-articulate",
  urgent_sale: "https://www.admin.ecomotors.am/api/car-urgently",
  services: "https://www.admin.ecomotors.am/api/car-service",
  service_slug: "https://www.admin.ecomotors.am/api/car-service-details",
  car_type: "https://www.admin.ecomotors.am/api/car-body-type",
  car_type_slug: "https://www.admin.ecomotors.am/api/car-body-type-filter",
  credit_with_card: "https://www.admin.ecomotors.am/api/credit-info",
  credit_with_card_post: "https://www.admin.ecomotors.am/api/credit-request",
  advertisement: "https://www.admin.ecomotors.am/api/advertisement-request",
  write_review: "https://www.admin.ecomotors.am/api/review-request",
  see_reviews: "https://www.admin.ecomotors.am/api/review-all",
  spare_parts_info: "https://www.admin.ecomotors.am/api/spare-part-info",
  spare_parts_category: "https://www.admin.ecomotors.am/api/spare-parts-category",
  spare_parts_model: "https://www.admin.ecomotors.am/api/spare-parts",
  spare_part_request: "https://www.admin.ecomotors.am/api/spare-part-request",
  our_team: "https://www.admin.ecomotors.am/api/team-info",
  available_cars: "https://www.admin.ecomotors.am/api/cars-only-active",
  spare_parts_active_car_models: "https://www.admin.ecomotors.am/api/spare-parts-car-names",
  salon_localization: "https://www.admin.ecomotors.am/api/salon-localization",
  car_based_salon: "https://www.admin.ecomotors.am/api/cars-only-location",
  motorcycles_based_salon: "https://admin.ecomotors.am/api/motorcycle-only-location",
  motorcycles: "https://www.admin.ecomotors.am/api/motorcycles",
  motorcycles_active: "https://www.admin.ecomotors.am/api/motorcycle-only-active"
};

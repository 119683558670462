import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const MainImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 50px;
  user-select: none;
`;

export const MainImagesBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  text-transform: capitalize;
  color: ${COLORS.main_color};
  font-size: 30px;
  font-weight: 800;
  ${DefaultFont};

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 3.5vw;
  }
`;

import { useDispatch, useSelector } from "react-redux";
import { selectSeeReview } from "store/seeReview/selector";
import { useEffect } from "react";
import { seeReview } from "store/seeReview/actions";
import { Images } from "assets";
import { useTranslation } from "react-i18next";
import {
  ReviewEveryBlockDescription,
  ReviewEveryBlockName,
  ReviewEveryBlockNameText,
  ReviewEveryBlockStarImage,
  ReviewEveryBlockStarImageWrapper,
  ReviewsEveryBlock,
  ReviewsMainTitle,
  ReviewsMainWrapper, SeeReviewsContentWrapper,
  SeeReviewsWrapper
} from "containers/seeReviews/styled";

export const SeeReviews = () => {
  const selectSeeReviewData = useSelector(selectSeeReview);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(seeReview());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <SeeReviewsWrapper>
        <ReviewsMainTitle>{t("reviews")}</ReviewsMainTitle>
        {selectSeeReviewData.map((element) => {
          return (
              <ReviewsEveryBlock>
                <ReviewEveryBlockName>
                  <ReviewEveryBlockNameText>{element?.name_person}</ReviewEveryBlockNameText>
                  <ReviewEveryBlockStarImageWrapper>
                    {Array.from({ length: element?.rating }, (_, i) => (
                      <ReviewEveryBlockStarImage key={i} alt='star icon' src={Images.starWallpaper} />
                    ))}
                  </ReviewEveryBlockStarImageWrapper>
                </ReviewEveryBlockName>
                <ReviewEveryBlockDescription>{element?.description}</ReviewEveryBlockDescription>
              </ReviewsEveryBlock>
          );
        })}
    </SeeReviewsWrapper>
  );
};
import { createSlice } from "@reduxjs/toolkit";
import { carSlug } from "store/carSinglePage/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  carSlugStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const CarSlugData = createSlice({
  name: "car_slug",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "carStatus":
        state.carSlugStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [carSlug.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.carSlugStatus = REDUX_STATES.PENDING;
    },
    [carSlug.fulfilled]: (state, action) => {
      const cars = action.payload.cars || [];
      state.data = cars.length > 0 ? cars[0] : null;
      state.is_loading = false;
      state.carSlugStatus = REDUX_STATES.SUCCEEDED;
    },

    [carSlug.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.carSlugStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = CarSlugData.actions;

export default CarSlugData.reducer;

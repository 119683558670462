import {
  EveryMotorCycleBlock, ImageOfMoto,
  MainMopedsWrapper,
  MainMotorCyclesWrapper,
  MopedsTitle, MotoName, MotoPrice,
  OpacityBlock,
  RedColorText
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MotorcyclesAction } from "../../store/motorcycles/actions";
import { selectMotorCycleData } from "../../store/motorcycles/selector";
import { IMAGE_STARTING_URL } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";

export const Motorcycles = () => {
  const dispatch = useDispatch();
  const selectMotoInfo = useSelector(selectMotorCycleData);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(MotorcyclesAction())
  }, [dispatch]);

  return (
    <>
      <MainMopedsWrapper>
        <OpacityBlock />
        <MopedsTitle>
          <RedColorText>Նորույթ</RedColorText> <br /> Էկո Մոթորսում <br />
          <RedColorText>Մոտոցիկլերի </RedColorText> Լայն <br /> տեսականի</MopedsTitle>
      </MainMopedsWrapper>
      <MainMotorCyclesWrapper>
        {selectMotoInfo?.map((moto) => (
          <EveryMotorCycleBlock onClick={() => navigate(`${ROUTENAMES.motorcycles_single_page}/${moto.slug}`)} key={moto.id}>
            {moto.product_images.map((image) => (
              image.main_image === 1 && (
                <ImageOfMoto
                  key={image.id}
                  alt='image'
                  src={`${IMAGE_STARTING_URL}/${image.image}`}
                />
              )
            ))}
            <MotoName>{moto?.product_name?.motorcycle_name} {moto?.product_model?.motorcycle_model}</MotoName>
            <MotoPrice>{t('price')} {moto?.motorcycle_price} $</MotoPrice>
            <MotoPrice>{t('car_product_year')} {moto?.product_year?.car_year}</MotoPrice>
          </EveryMotorCycleBlock>
        ))}
      </MainMotorCyclesWrapper>
    </>

  );
};
import {
  FaqWrapper,
  FaqTitle,
  FaqContentWrapper,
  EveryFaq,
  EveryFaqTitle,
  EveryFaqAnswer
} from "containers/FAQ/styled";
import { useTranslation } from "react-i18next";

export const FAQ = () => {
  const { t } = useTranslation();

  return (
    <FaqWrapper>
      <FaqTitle>{t("faq")}</FaqTitle>
      <FaqContentWrapper>
        <EveryFaq>
          <EveryFaqTitle>  {t('do_they_have_question_1')}</EveryFaqTitle>
          <EveryFaqAnswer>{t('do_they_have_answer_1')}</EveryFaqAnswer>
        </EveryFaq>
        <EveryFaq>
          <EveryFaqTitle>  {t('do_they_have_question_2')}</EveryFaqTitle>
          <EveryFaqAnswer>{t('do_they_have_answer_2')}</EveryFaqAnswer>
        </EveryFaq>
        <EveryFaq>
          <EveryFaqTitle>  {t('do_they_have_question_3')}</EveryFaqTitle>
          <EveryFaqAnswer>{t('do_they_have_answer_3')}</EveryFaqAnswer>
        </EveryFaq>
        <EveryFaq>
          <EveryFaqTitle>  {t('do_you_have_question_4')}</EveryFaqTitle>
          <EveryFaqAnswer>{t('do_you_have_answer_4')}</EveryFaqAnswer>
        </EveryFaq>
      </FaqContentWrapper>
    </FaqWrapper>
  );
};

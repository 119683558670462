import { createAsyncThunk } from "@reduxjs/toolkit";
import { carSlugService } from "store/carSinglePageCompare/service";

export const carCompareSlug = createAsyncThunk(
  "carCompare/carCompareSlug",
  async(data, { rejectWithValue }) => {
    try {
      const response = await carSlugService.car_slug(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

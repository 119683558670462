import { createSlice } from "@reduxjs/toolkit";
import { servicesEcoMotors } from "store/services/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  servicesEcoMotorsStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const EcoMotorsServicesData = createSlice({
  name: "servicesEcoMotors",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "servicesEcoMotorsStatus":
        state.servicesEcoMotorsStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [servicesEcoMotors.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.servicesEcoMotorsStatus = REDUX_STATES.PENDING;
    },
    [servicesEcoMotors.fulfilled]: (state, action) => {
      state.data = action?.payload?.services;
      state.is_loading = false;
      state.servicesEcoMotorsStatus = REDUX_STATES.SUCCEEDED;
    },
    [servicesEcoMotors.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.servicesEcoMotorsStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = EcoMotorsServicesData.actions;

export default EcoMotorsServicesData.reducer;

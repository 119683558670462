import { createAsyncThunk } from "@reduxjs/toolkit";
import { MopedSingleService } from "store/mopedSingleInfo/service";

export const mopedSlug = createAsyncThunk(
  "moped/mopedSlug",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await MopedSingleService.moped_slug(slug);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

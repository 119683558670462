import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "routes/routeNames";
import { useTranslation } from "react-i18next";
import { Images } from "assets";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileData } from "store/profile/selector";
import { profileData } from "store/profile/actions";
import { selectChangedProfileData } from "store/changeProfileInfo/selector";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Controller, useForm } from "react-hook-form";
import { changeProfileData } from "store/changeProfileInfo/actions";
import {
  GoToSeeSavedCars,
  Image,
  PhoneNumberBlock,
  ProfileBlock,
  ProfileBlocks,
  ProfileLeftBlock,
  ProfileMainInfo,
  ProfileMainInputs,
  ProfileMainLogOutButton,
  ProfileMainLogOutButtonBlock,
  ProfileMainSaveButton,
  ProfileRightBlock,
  ProfileTitle,
  ProfileWrapper,
  ProfileWrapperOpacity,
  UserImagePart,
  UserInput,
  UserName
} from "containers/profile/styled";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userProfileData = useSelector(selectProfileData);
  const changeUserProfileData = useSelector(selectChangedProfileData);
  const { register, control, setValue, handleSubmit } = useForm();

  const handleLogOut = () => {
    navigate(ROUTENAMES.welcome);
    localStorage.removeItem("access_token");
    localStorage.removeItem("session_data");
  };

  const onSubmit = (data) => {
    dispatch(changeProfileData(data));
  };

  useEffect(() => {
    setValue("name", userProfileData?.name);
    setValue("email", userProfileData?.email);
    setValue("phoneNumber", userProfileData?.phoneNumber);
  }, [setValue, userProfileData]);

  useEffect(() => {
    dispatch(profileData());
  }, [dispatch, changeUserProfileData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <ProfileWrapper>
      <ProfileWrapperOpacity />
      <ProfileBlock>
        <ProfileWrapperOpacity />
        <ProfileTitle>{t("profile")}</ProfileTitle>
        <ProfileBlocks onSubmit={handleSubmit(onSubmit)}>
          <ProfileLeftBlock>
            <UserImagePart>
              <Image src={Images.userProfilePhoto} alt='user profile'/>
              <UserInput type="file" />
            </UserImagePart>
            <UserName> {userProfileData?.name} </UserName>
          </ProfileLeftBlock>
          <ProfileRightBlock>
            <ProfileMainInfo>
              <ProfileMainInputs placeholder={t("name")} {...register("name")} />
              <ProfileMainInputs placeholder={t("email")} {...register("email")} />
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <PhoneNumberBlock
                    country="am"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <GoToSeeSavedCars onClick={() => navigate(ROUTENAMES.saved_cars)}>{t("save_cars")}</GoToSeeSavedCars>
              <ProfileMainLogOutButtonBlock>
                <ProfileMainSaveButton> {t("save")} </ProfileMainSaveButton>
                <ProfileMainLogOutButton isLogOut onClick={handleLogOut}>{t("log_out")}</ProfileMainLogOutButton>
              </ProfileMainLogOutButtonBlock>
            </ProfileMainInfo>
          </ProfileRightBlock>
        </ProfileBlocks>
      </ProfileBlock>
    </ProfileWrapper>
  );
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CarDataModelsWrapper,
  CarImage,
  CarImagesBlock, CarModel,
  CarTitle,
  FullLayout
} from "spareParts/containers/sparePartsMainPage/styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sparePartsCategory } from "store/sparePartsCategory/actions";
import { selectSparePartsCategory } from "store/sparePartsCategory/selector";
import { IMAGE_STARTING_URL } from "utils/constants";
import { ROUTENAMES } from "routes/routeNames";
import { selectAvailableSpareCars } from "store/availableCarSparePart/selector";
import { selectCarNames } from "store/carNames/selector";
import { availableCarsSpareParts } from "store/availableCarSparePart/actions";
import { resetStatus } from "store/sparePartsProductWithModelId/slice";
import { carNames } from "store/carNames/actions";
import {
  CardBlockTitle,
  CardLineBlock, CarMainModelsWrapper, CarModels, CategoriesWrapper,
  EveryCardBlock,
  EverySparePartBlockImage,
  MainTitle,
  MainTitleText,
  WrapperOfScrollLines
} from "spareParts/containers/carSpareParts/styled";
import { toast } from "react-toastify";

export const SparePartsCategory = () => {
  const [language, setLanguage] = useState("am");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, modelId } = useParams();
  const selectSpareCategories = useSelector(selectSparePartsCategory);
  const [carModel, setCarModel] = useState(modelId || null);
  const [carNameId, setCarNameId] = useState(id || null)
  const selectCarModelsData = useSelector(selectAvailableSpareCars);
  const CarDataNames = useSelector(selectCarNames);

  const openSelectTab = (element) => {
    setCarModel(element.id);
  };

  const selectCarModel = (id) => {
    setCarNameId(id);
    navigate(`${ROUTENAMES.main_spare_parts}/${id}/${carModel}`)
  }

  const handleNavigation = (elID) => {
    if(carNameId === null) {
      toast.error(`Ընտրեք մակնիշ և մոդել`)
    } else {
      navigate(`${ROUTENAMES.car_spare_part_single_page}/id=${elID}/model=${carNameId}`)
    }
  }

  useEffect(() => {
    dispatch(sparePartsCategory({ language }));
  }, [dispatch, language]);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(availableCarsSpareParts());
    if (carModel) {
      dispatch(carNames({ car_name_id: carModel }));
    }
  }, [dispatch, carModel]);

  return (
    <FullLayout>
      <WrapperOfScrollLines>
        <MainTitle>
          <MainTitleText>{t("categories")}</MainTitleText>
        </MainTitle>
        <CategoriesWrapper>
          <CarModels>
            <CarMainModelsWrapper>
              {selectCarModelsData.map((element) => (
                <CarImagesBlock selected={modelId == element.id} key={element.created_at} onClick={() => openSelectTab(element)}>
                  <CarImage src={`${IMAGE_STARTING_URL}/${element.image}`} alt='car main'/>
                  <CarTitle>{element.car_name}</CarTitle>
                </CarImagesBlock>
              ))}
            </CarMainModelsWrapper>

            {CarDataNames.length ? <CarDataModelsWrapper>
                {CarDataNames.map((element) => (
                  <CarModel selected={id == element.id} key={element.created_at}  onClick={() => selectCarModel(element.id)}>
                    {element.model_name}
                  </CarModel>
                ))}
            </CarDataModelsWrapper> : null}
          </CarModels>
          <CardLineBlock>
            {selectSpareCategories?.map((element) => (
              <EveryCardBlock onClick={() => handleNavigation(element.id)}>
                <CardBlockTitle>{element.name_spare_part_category}</CardBlockTitle>
                <EverySparePartBlockImage
                  src={`${IMAGE_STARTING_URL}/${element?.image}`}
                  alt='car'
                />
              </EveryCardBlock>
            ))}
          </CardLineBlock>
        </CategoriesWrapper>
      </WrapperOfScrollLines>
    </FullLayout>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { carService } from "store/carNames/service";

export const carNames = createAsyncThunk(
  "carNames/fetch",
  async(body, { rejectWithValue }) => {
    try {
      const response = await carService.carNames(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

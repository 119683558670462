import { createSlice } from "@reduxjs/toolkit";
import { creditWithBank } from "store/creditWithBank/actions";
import { REDUX_STATES } from "utils/constants";

const initialState = {
  creditWithBankStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const creditWithBankData = createSlice({
  name: "creditWithBank",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "creditWithBankStatus":
        state.creditWithBankStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [creditWithBank.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.creditWithBankStatus = REDUX_STATES.PENDING;
    },
    [creditWithBank.fulfilled]: (state, action) => {
      state.data = action?.payload?.creditInfo;
      state.is_loading = false;
      state.creditWithBankStatus = REDUX_STATES.SUCCEEDED;
    },
    [creditWithBank.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.creditWithBankStatus = REDUX_STATES.FAILED;

      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = creditWithBankData.actions;

export default creditWithBankData.reducer;

import "components/welcome/style.css";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuid } from "uuid";
import { SwiperSliderImage, WrapperSlider } from "components/welcome/styled.js";
import { IMAGE_STARTING_URL } from "utils/constants";

export const WelcomeSlider = ({ AboutUsData }) => {
  return (
    <WrapperSlider>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false
        }}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {AboutUsData?.images?.map((element) => (
          <SwiperSlide key={uuid()}>
            <SwiperSliderImage loading="lazy" backgroundImage={`${IMAGE_STARTING_URL}/${element?.image}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </WrapperSlider>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { REDUX_STATES } from "utils/constants";
import { likedCarsData } from "store/likedCars/actions";

const initialState = {
  likedCarsStatus: null,
  data: [],
  error: null,
  errors: "",
  errorMessage: "",
  is_loading: false,
};
export const UserLikedCarsData = createSlice({
  name: "likedCarsData/fetch",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      case "likedCarsStatus":
        state.likedCarsStatus = null;
        break;
      default:
        break;
      }
    },
  },

  extraReducers: {
    [likedCarsData.pending]: (state) => {
      state.errors = null;
      state.errorMessage = "";
      state.is_loading = false;
      state.likedCarsStatus = REDUX_STATES.PENDING;
    },
    [likedCarsData.fulfilled]: (state, action) => {
      state.data = action?.payload?.favourites;
      state.is_loading = false;
      state.likedCarsStatus = REDUX_STATES.SUCCEEDED;
    },
    [likedCarsData.rejected]: (state, { payload }) => {
      state.is_loading = false;
      state.likedCarsStatus = REDUX_STATES.FAILED;
      if (payload.status === 422) {
        state.errors = payload.data?.errors;
      } else {
        state.errorMessage = "error";
      }
    },
  },
});

export const { resetStatus } = UserLikedCarsData.actions;

export default UserLikedCarsData.reducer;

import styled, { keyframes } from "styled-components";
import { DefaultFont } from "utils/defaultFont";
import { COLORS } from "utils/colors";
import { Images } from "assets";
import PhoneInput from "react-phone-input-2";

export const CreditFieldWrapper = styled.form`
  padding-inline: 220px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: column;

  @media only screen and (max-width: 1454px) {
    padding-inline: 140px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 100px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 40px;
    padding-bottom: unset;
  }
`;

export const slideIn = keyframes`
  0% {
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
  }

  50% {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  }

  75% {
    background: rgba(0, 0, 0, 0.78) none repeat scroll 0 0;
  }

  100% {
    background: rgba(0, 0, 0, 0.90) none repeat scroll 0 0;
  }
`;

export const CreditFieldSuccessWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 20px;
  z-index: 12;
  animation: ${slideIn} 1s ease-in-out;
`;


export const CreditFieldSuccessText = styled.span`
  font-size: 28px;
  color: ${COLORS.white};
`;

export const CreditMainModalTitle = styled.h1`
  width: 100%;
  height: 40px;
  padding: 20px;
  margin: 0 !important;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: ${COLORS.main_color};
  font-weight: bold;
  text-transform: capitalize;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    height: unset;
  }

  @media only screen and (max-width: 428px) {
    font-size: 24px;
  }
`;

export const CreditMainModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: ${COLORS.white};
  padding: 20px;
  background-color: ${COLORS.white};
  gap: 30px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
`;

export const CreditMainModalLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  height: 300px;
  position: relative;
  left: 3%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }
`;

export const RangeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 60px;
`;

export const RangeInput = styled.input.attrs({
  type: "range"
})`
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  -webkit-appearance: none;
  width: 80%;
  height: 20px;
  outline: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: ${COLORS.main_color};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${COLORS.white};
    cursor: pointer;
  }
`;

export const RangeInputValue = styled.span`
  display: flex;
  align-items: center;
  width: auto;
  height: 30px;
  ${DefaultFont};
  font-size: 16px;
  font-weight: 800;
  margin-left: 10px;
  color: ${COLORS.main_color};
  text-transform: capitalize;
`;

export const RangeInputBlockWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RangeInputBlock = styled.input`
  width: 200px;
  border: 1px solid ${COLORS.main_color};
  outline: unset;
  margin-left: 4px;
  height: 23px;
  ${DefaultFont};
  font-weight: 800;
  color: ${COLORS.main_color};

  @media only screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const CreditMainModalRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 100%;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 26px;
  }

  @media only screen and (max-width: 428px) {
    width: 100%;
    height: auto;
    gap: 2px;
  }
`;

export const CreditMainText = styled.span`
  font-size: 18px;
  ${DefaultFont};
`;

export const CreditMainInput = styled.input`
  width: 100%;
  border: unset;
  outline: none;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid ${COLORS.main_color};
  color: ${COLORS.main_color};

  &::placeholder {
    color: ${COLORS.main_color};
    text-transform: capitalize;
  }
`;

export const EcoMotorsLogo = styled.div`
  width: 100%;
  height: 40px;
  background: url(${Images.logo});
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    background-position: center;
  }
`;

export const PaymentPerMonth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 40px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PerMonthText = styled.span`
  color: ${COLORS.main_color};
  font-size: 16px;
  font-weight: 700;
`;

export const PriceText = styled.span`
  padding: 4px;
  color: ${COLORS.white};
  font-size: 20px;
  background-color: ${COLORS.main_color};
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PhoneNumberBlock = styled(PhoneInput)`
  width: 100%;
  height: 35px;

  .form-control {
    width: 100% !important;
    background: transparent;
    border: 2px solid ${COLORS.main_color} !important;
    color: ${COLORS.main_color};
  }

  .flag-dropdown {
    background: ${COLORS.main_color} !important;
  }

  @media only screen and (max-width: 768px) {
    width: ${({ isWithFull }) => (isWithFull ? "100%" : "93%")} !important;
  }
`;

export const ErrorMessage = styled.span`
  position: relative;
  width: 100%;
  line-height: 8px;
  font-weight: 500;
  color: ${COLORS.red};
`;

export const FormInput = styled.div`
  height: 40px;
`;

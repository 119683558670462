import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "store/auth/actions";
import { selelectAuth } from "store/auth/selector";
import { Images } from "assets";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInSchema } from "validations/signInValidation";
import { ROUTENAMES } from "routes/routeNames";
import {
  BlackWithOpacity,
  EmailInput,
  ErrorMessage,
  Form,
  FormSubmitButton,
  InputAndErrorMessage,
  LoginForm,
  LoginFormWrapper,
  LoginTitle,
  PasswordEye,
  PasswordInput,
  SignInWrapper
} from "containers/signIn/styled";


export const SignIn = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selelectAuth);
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(SignInSchema(language))
  });

  const onSubmit = (data) => {
    if (data.email && data.password) {
      dispatch(signIn(data));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate(ROUTENAMES.welcome);
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <SignInWrapper>
      <BlackWithOpacity />
      <LoginFormWrapper>
        <LoginForm>
          <LoginTitle>{t("login")}</LoginTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputAndErrorMessage>
              <EmailInput placeholder={t("email")} {...register("email")} />
              {errors.email ? (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              ) : null}
            </InputAndErrorMessage>

            <InputAndErrorMessage>
              <PasswordInput>
                <EmailInput placeholder={t("password")}
                  type={isOpen ? "text" : "password"} {...register("password")} />
                <PasswordEye
                  onClick={() => setIsOpen(!isOpen)}
                  src={isOpen ? Images.openedEye : Images.closedEye} alt="eye" />
              </PasswordInput>
              {errors.password ? (
                <ErrorMessage>{errors.password.message}</ErrorMessage>
              ) : null}
            </InputAndErrorMessage>
            <FormSubmitButton>{t("login")}</FormSubmitButton>
          </Form>
        </LoginForm>
      </LoginFormWrapper>
    </SignInWrapper>
  );
};

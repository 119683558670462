import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useContactValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    feedback_name: yup.string().required(t("this_field_is_required")),
    feedback_email: yup.string().email(t("this_field_is_required")).required(t("error_required_email")),
    feedback_subject: yup.string().required(t("this_field_is_required")),
    feedback_phone: yup.string().required(t("this_field_is_required")),
    feedback_message: yup.string().required(t("this_field_is_required"))
  });
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AboutUsService } from "store/aboutUs/service";

export const aboutUsData = createAsyncThunk(
  "fetch/aboutUsData",
  async(language, { rejectWithValue }) => {
    try {
      const response = await AboutUsService.about_us(language);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    cache: "no-store"
  }
);

import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "utils/defaultFont";

export const CarModelsSliderWrapper = styled.div`
  width: 100%;
  height: 220px;
  margin-top: 20px;
`;

export const CarModelName = styled.span`
  position: absolute;
  top: 0;
  left: -100px;
  color: ${COLORS.dark_blue};
  font-size: 20px;
  font-weight: bold;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.3s;
  ${DefaultFont};
`;

export const CarModelImage = styled.img`
  width: 200px;
  object-fit: cover;
  transition: 0.6s;
  animation-timing-function: ease-in-out;
`;

export const CarModelsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
  border: 2px solid rgba(143, 231, 115, 0.58);

  &:hover {
    background: rgba(137, 255, 30, 0.3) none repeat scroll 0 0;

    ${CarModelName} {
      opacity: 1;
      top: 20px;
      left: 8px;
    }
  ;

    ${CarModelImage} {
      transform: scale(1.1, 1.1);
    }
  }
`;

import { CompareCarsWrapper, EveryCarBlock } from "containers/compareCars/styled";
import { LeftSide } from "components/carCompare";
import { RightSide } from "components/carCompare/rightSide";
import React from "react";

export const CompareCars = () => (
  <CompareCarsWrapper>
    <EveryCarBlock>
      <LeftSide />
    </EveryCarBlock>
    <EveryCarBlock borderLeft>
      <RightSide />
    </EveryCarBlock>
  </CompareCarsWrapper>
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "./service";

export const signIn = createAsyncThunk(
  "auth/signIn",
  async(data, { rejectWithValue }) => {
    try {
      const response = await authService.logIn(data);
      const responseDataAccessToken = response.data.data.access_token;
      localStorage.setItem("access_token", responseDataAccessToken);
      const sessionData = {
        timestamp: Date.now(),
      };
      localStorage.setItem("session_data", JSON.stringify(sessionData));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async(data, { rejectWithValue }) => {
    try {
      const response = await authService.signUp(data);
      const responseDataAccessToken = response.data.data.access_token;
      localStorage.setItem("access_token", responseDataAccessToken);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
